import { tns } from "tiny-slider"
import { getDynamicMenuResponse } from '../opus2/util/getDynamicMenu';
import { getCampaignName } from "../opus2/util/share";
import { ScreenType } from "../opus2/location-search-map/models/ScreenType";
import { reportScreenSize } from "../opus2/location-search-map/reportScreenSize";
export class StickyHeader {
    private firstHead: HTMLElement | null;
    private headerH: number;
    public  dynamicMenuType: string;
    constructor() {
        this.firstHead = document.querySelector(".first-head");
        const secondHead: any = document.querySelector(".second-head");
        this.headerH = secondHead?.offsetHeight || 0;
        this.dynamicMenuType = '';
        window.addEventListener("scroll", () => {
            const sticky = secondHead?.offsetTop || 0;
            let main = document.getElementById("main")
            if (window.pageYOffset > sticky) {
                this.firstHead?.classList.add("hidden");
                secondHead?.classList.add("fixed", "top-0");
                if (main)
                    main.style.marginTop = this.headerH + "px";
            } else {
                this.firstHead?.classList.remove("hidden");
                secondHead?.classList.remove("fixed", "top-0");
                if (main)
                    main.style.marginTop = "0";
            }
        });

        //FOR SITEMAP TARGET LINK
        const allElements = document.querySelectorAll('.link-target');
        allElements?.forEach((element: any) => {
            element.style.scrollMarginTop = this.headerH - 30 + "px";
        });

        setTimeout(()=>{
            this.megaMenu.call(this);
           }, 1000);
           this.readDynamicMenu();

           getCampaignName();
    }
 

    async readDynamicMenu () {
        let response = await getDynamicMenuResponse();
        this.dynamicMenuType = response.dynamicMenuType;
        this.dynamicMenuV2();
    }
    megaMenu() {
        // MEGA MENU JS STARTS HERE
        const firstMenu = document.querySelector<HTMLElement>(".mega-menu");
        const secondMenu = document.querySelector<HTMLElement>(".sub-menu");
        const theMenuButton:any = document.querySelector<HTMLElement>(".mobile-sidebar-drawer");
        const showMenu = document.querySelectorAll<HTMLElement>(".expand-menu-js");

        const mainMenu = document.querySelectorAll<HTMLElement>('.nav-link');

        if (window.matchMedia("(max-width: 1023px)").matches) {
            theMenuButton.setAttribute("data-drawer-target", "sidebar");
            theMenuButton.setAttribute("data-drawer-toggle", "sidebar");
        }

        mainMenu?.forEach(main => {
            const screenType = reportScreenSize();
            if(screenType !== ScreenType.Desktop){
            main.addEventListener('click', function handleClick(event) {
                    let id:any = this.getAttribute('data-nested-value');
                    let idWrap = document.getElementById(id);
                    idWrap?.classList.add('show-menu');
                    idWrap?.classList.remove('hide-menu');
                });
            }
        });

        const backBtn = document.querySelectorAll<HTMLElement>('.back-btn');
        backBtn?.forEach(back => {
            back.addEventListener('click', function handleClick(event) {
                let nameBack:any = this.getAttribute('data-back');
                let idWrap = document.getElementById(nameBack);
                idWrap?.classList.remove('show-menu');
                idWrap?.classList.add('hide-Submenu');
            });
        });

        const backTabBtn = document.querySelectorAll<HTMLElement>('.back-tab-btn');
        backTabBtn?.forEach(back => {
            back.addEventListener('click', function handleClick(event) {
                back.closest(".mega-menu")?.querySelector(".tab-sibling")?.classList.remove('hide-menu');
                let nameBack:any = this.getAttribute('data-back');
                let idWrap = document.getElementById(nameBack);
                idWrap?.classList.remove('show-menu');
                idWrap?.classList.add('hidden');
            });
        });

        const closeNav = document.querySelector<HTMLElement>(".sidenav-close");
        closeNav?.addEventListener('click', closeAllMenu);
        let allFirstMenu = document.querySelectorAll<HTMLElement>(".mega-menu");
        let allSecondMenu = document.querySelectorAll<HTMLElement>(".sub-menu");
        function closeAllMenu() {
            allFirstMenu.forEach((element) => {
                element?.classList.remove('show-menu');
            });
            allSecondMenu.forEach((element) => {
                element?.classList.add('hide-Submenu');
            });
            document.querySelector<HTMLElement>(".tab-sibling")?.classList.remove('hide-menu');
        }

        const slideBlock = document.querySelector<HTMLElement>(".slide-block");
        const brandHeader = document.querySelector<HTMLElement>(".main-nav-warpper");
        const slideBlockH:any = slideBlock?.offsetHeight;
        const brandHeaderH = brandHeader?.offsetHeight;
        const firstMenuTop = document.querySelectorAll<HTMLElement>(".mega-menu");
        const firstSubMenuTop = document.querySelectorAll<HTMLElement>(".sub-menu");

        const mql = window.matchMedia("(max-width: 1023px)");

        if (mql.matches) {
            firstMenuTop.forEach((element:any) => {
                element.style.top = slideBlockH + 16 + "px";
            });
            firstSubMenuTop.forEach((element:any) => {
                element.style.top = "0";
            });
        }

        // RESET SUBMEGAMENU ON MOUSE LEAVE
        const subMegaMenu:any = document.getElementsByClassName('submega-inner-warp');
        if (window.matchMedia("(min-width:1024px)").matches) {
            for (let i = 0; i < subMegaMenu.length; i++) {
                subMegaMenu[i].onmouseleave = function () {
                    document.getElementsByClassName("mega-inner-warp tab-sibling")[i].classList.remove('hide-menu');
                    document.querySelectorAll(".submega-inner-warp")[i].classList.remove('show-menu');
                    document.querySelectorAll(".submega-inner-warp")[i].classList.add('hide-menu');
                    const hideSubMegaMenu = document.querySelectorAll('.submega-tab-content div[role="tabpanel"]');
                    hideSubMegaMenu.forEach(item => {
                        item.classList.add('hidden');
                    })
                  let  resetMenuBtn:any = document.querySelectorAll('.submega-tab li[role="presentation"] button');
                    resetMenuBtn?.forEach((item:any) => {
                        item.setAttribute("aria-selected", "false");
                    })
                }
            }
        }

    }

    public dynamicMenuV2() {
       
        const mainSubMenu = document.querySelectorAll<HTMLElement>('.expand-menu-js');
        this.dynamicMenuType = this.dynamicMenuType?.replace(/\s/g, "")?.toLowerCase();
        const dynamicStr = 'Dynamic Menu V2'.replace(/\s/g, "").toLowerCase();
        if(this.dynamicMenuType == dynamicStr) {
            mainSubMenu?.forEach(main => {
                main.addEventListener('click', function handleClick(event) {
                try {
                    if (main.closest(".tab-sibling")) {
                        main.closest(".tab-sibling")?.classList.add('hide-menu');
                    }
                    let id:any = this.getAttribute('data-nested-value');
                    let tab:any = this.getAttribute('data-active-tab');
                    let idWrap = document.getElementById(id);
                    idWrap?.classList.add('show-menu');
                    idWrap?.classList.remove('hide-menu');
    
                    let idTab = document.getElementById(tab);
                    idTab?.closest(".submega-tab")?.querySelector(".submega-tab-button")?.setAttribute("aria-selected", "false");
                    idTab?.setAttribute("aria-selected", "true");
    
                    let openTab = idTab?.getAttribute("data-tabs-target");
                    let tabIdsArray:any = openTab?.split("#");

                    if(tabIdsArray && tabIdsArray.length > 0){

                        let idTabCont = document.getElementById(tabIdsArray[1]);
                        idTabCont?.closest(".submega-inner-warp")?.querySelector(".submega-tab-content div[role='tabpanel']")?.classList.add('hidden');
                        idTabCont?.classList.remove('hidden');

                    }
                }  catch (error) {
                    console.error("An error occurred in the click event:", error);
                }
                });
            });
    
        }
    }






}

class AsideDiv {
    private aside: HTMLElement | null;
    private secondHead: HTMLElement | null;
    private headerH: number;

    constructor() {
        this.secondHead = document.querySelector(".second-head");
        this.aside = document.querySelector(".blog-aside");
        this.headerH = this.secondHead?.offsetHeight || 0;
        if (this.aside) {
            this.aside.style.top = this.headerH - 64 + "px";;
        }

        //FOR SITEMAP TARGET LINK
        const allElements = document.querySelectorAll('.link-target');
        allElements?.forEach((element: any) => {
            element.style.scrollMarginTop = this.headerH - 30 + "px";
        });
    }
}

export class TinySlider {
    private offerSlider: HTMLElement | null;
    private offerWrapper: HTMLElement | null;

    constructor() {
        this.offerSlider = document.querySelector(".offers-slider");
        this.offerWrapper = document.querySelector(".offers-block-wrapper");
        // .offers-block-section .offers-block-list .offers-block-item

        if (this.offerSlider) {
            const offersCount = this.offerSlider.getElementsByTagName("li").length;
            let itemsToShow = 4;
            if (offersCount < itemsToShow) {
                itemsToShow = offersCount;
            }
            const slider = tns({
                container: this.offerSlider,
                items: 1,
                loop: false,
                gutter: 20,
                controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
                responsive: {
                    1024: { items: 2 },
                    1200: { items: 3 },
                    1300: { items: itemsToShow }
                }
            });

            //const offerSliderlist = this.offerSlider.getElementsByTagName("li").length;

            //const adjustOfferSlider = () => {
            //    const w = window.innerWidth;
            //    if (offerSliderlist <= 4 && w > 1300 && slider?.destroy) {
            //        slider?.destroy();
            //        if (this.offerWrapper) {
            //            this.offerWrapper.style.maxWidth = "100%";
            //        }
            //    }
            //};


            //window.addEventListener('load', adjustOfferSlider);
            //window.addEventListener('resize', adjustOfferSlider);

            let firstMenuTop = document.querySelectorAll(".mega-menu");
            let slideBlock: any = document.querySelector(".slide-block");
            let slideBlockH = slideBlock?.offsetHeight;
            let mql = window.matchMedia("(max-width: 768px)")


            // if (mql.matches) {
            //     firstMenuTop?.forEach((element:any) => {
            //         element.style.top = slideBlockH - 34 + "px";
            //     });
            // }
        }
    }
}

class CustomerReviewSlider {
    private reviewSlider: HTMLElement | null;
    private reviewSlider1: HTMLElement | null;
    private reviewWrapper: HTMLElement | null;

    constructor() {
        this.reviewSlider = document.querySelector(".review-slider");
        this.reviewSlider1 = document.querySelector(".review-slider1");
        this.reviewWrapper = document.querySelector(".reviews-list-wrapper");

        const setupReviewSlider = (sliderContainer: HTMLElement) => {
            const slider = tns({
                container: sliderContainer,
                items: 1,
                loop: false,
                controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
                responsive: {
                    1024: { items: 2 },
                    1200: { items: 3 }
                }
            });

            //const reviewSliderlist = sliderContainer.getElementsByTagName("li").length;

            //const adjustReviewSlider = () => {
            //    const w = window.innerWidth;
            //    if (reviewSliderlist <= 3 && w > 1300 && slider) {
            //        slider?.destroy();
            //        if (this.reviewWrapper) {
            //            this.reviewWrapper.style.maxWidth = "100%";
            //        }
            //    }
            //};

            //window.addEventListener('load', adjustReviewSlider);
            //window.addEventListener('resize', adjustReviewSlider);
        };

        if (this.reviewSlider) {
            setupReviewSlider(this.reviewSlider);

            let firstMenuTop = document.querySelectorAll(".mega-menu");
            let slideBlock: any = document.querySelector(".slide-block");
            let slideBlockH = slideBlock?.offsetHeight;
            let mql = window.matchMedia("(max-width: 768px)")


            // if (mql.matches) {
            //     firstMenuTop?.forEach((element:any) => {
            //         element.style.top = slideBlockH - 34 + "px";
            //     });
            // }
        }

        if (this.reviewSlider1) {
            setupReviewSlider(this.reviewSlider1);
            let firstMenuTop = document.querySelectorAll(".mega-menu");
            let slideBlock: any = document.querySelector(".slide-block");
            let slideBlockH = slideBlock?.offsetHeight;
            let mql = window.matchMedia("(max-width: 768px)")


            // if (mql.matches) {
            //     firstMenuTop?.forEach((element:any) => {
            //         element.style.top = slideBlockH - 34 + "px";
            //     });
            // }
        }
    }
}

class PhotoGallerySlider {
    private gallerySlider: HTMLElement | null;
    private galleryWrapper: HTMLElement | null;
    private slideIndex = 1;

    constructor() {
        this.gallerySlider = document.querySelector(".gallery-slider");
        this.galleryWrapper = document.querySelector(".gallery-wrapper");

        const setupGallerySlider = (sliderContainer: HTMLElement) => {
            const slider = tns({
                container: sliderContainer,
                items: 1,
                loop: false,
                slideBy: 'page',
                nav: true,
                navPosition: "bottom",
                // controlsPosition: "bottom",
                controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
                responsive: {
                    1024: {
                        items: 2,
                    },
                    1200: {
                        items: 3,
                    }
                }
            });

            //let gallerySliderlist = sliderContainer.getElementsByTagName("li").length;

            //const adjustGallerySlider = () => {
            //    let w = window.innerWidth;
            //    if (gallerySliderlist <= 3 && w > 1300) {
            //        slider?.destroy();
            //        if (this.galleryWrapper) {
            //            this.galleryWrapper.style.maxWidth = "100%";
            //        }
            //    }
            //};

            //window.addEventListener('load', adjustGallerySlider);
            //window.addEventListener('resize', adjustGallerySlider);
        };

        if (this.gallerySlider) {
            setupGallerySlider(this.gallerySlider);
            let firstMenuTop = document.querySelectorAll(".mega-menu");
            let slideBlock: any = document.querySelector(".slide-block");
            let slideBlockH = slideBlock?.offsetHeight;
            let mql = window.matchMedia("(max-width: 768px)")


            // if (mql.matches) {
            //     firstMenuTop?.forEach((element:any) => {
            //         element.style.top = slideBlockH - 34 + "px";
            //     });
            // }
        }

        const modal = document.getElementById("myModal");

        const openModal = () => {
            if (modal) {
                modal.style.display = "flex";
            }
        };

        const closeModal = () => {
            if (modal) {
                modal.style.display = "none";
            }
        };

        const prevArrowBtn = document.querySelector(".gallery-slider > .arrow-slidder > .prev") as HTMLButtonElement;
        const nextArrowBtn = document.querySelector(".gallery-slider > .arrow-slidder > .next") as HTMLButtonElement;
        prevArrowBtn?.addEventListener('click', () => {
            this.plusSlides(-1);
        });
        nextArrowBtn?.addEventListener('click', () => {
            this.plusSlides(1);
        })
        const closeModalButton = document.querySelector(".gallery-modal .close");

        const imgElement = document.querySelector(".photo-gallery-item img");

        // if (imgElement) {
        //     imgElement.addEventListener("click", openModal);
        // }

        // if (closeModalButton) {
        //     closeModalButton.addEventListener("click", closeModal);
        // }
    }
    plusSlides(n: number) {
        this.showSlides(this.slideIndex += n);
    };

    currentSlide(n: number) {
        this.showSlides(this.slideIndex = n);
    };

    showSlides(n: number) {
        let i;
        const slides = document.getElementsByClassName("pop-up-slider");
        if(slides) {

            if (n > slides.length) {
                this.slideIndex = 1;
            }
            if (n < 1) {
                this.slideIndex = slides.length;
            }
            for (i = 0; i < slides.length; i++) {
                (slides[i] as HTMLElement).style.display = "none";
            }
        }
        (slides[this.slideIndex - 1] as HTMLElement).style.display = "flex";
    };

}


class ChargerServiceSlider {
    private stepsSlider: HTMLElement | null;
    private stepsWrapper: HTMLElement | null;

    constructor() {
        this.stepsSlider = document.querySelector(".steps-slider");
        this.stepsWrapper = document.querySelector(".steps-img-wrapper");

        const setupSeviceSlider = (sliderContainer: HTMLElement) => {
            const slider = tns({
                container: sliderContainer,
                items: 1,
                loop: false,
                slideBy: 'page',
                nav: true,
                navPosition: "bottom",
                // controlsPosition: "bottom",
                controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
                responsive: {
                    1024: {
                        items: 1,
                    }
                }
            });

            const stepsSliderlist = sliderContainer.getElementsByTagName("li").length;

            //const adjustReviewSlider = () => {
            //    const w = window.innerWidth;
            //    if (stepsSliderlist <= 1 && w > 1300) {
            //        slider?.destroy();
            //        if (this.stepsWrapper) {
            //            this.stepsWrapper.style.maxWidth = "100%";
            //        }
            //    }
            //};

            //window.addEventListener('load', adjustReviewSlider);
            //window.addEventListener('resize', adjustReviewSlider);

            const progress = document.querySelectorAll(".card-step");
            const cardSelector = document.querySelector('.card-steps-wrapper');
            const prevStep = document.querySelector('button[data-controls="prev"]');
            const nextStep = document.querySelector('button[data-controls="next"]');
            let steps = 0;
            prevStep?.addEventListener('click', () => {
                if (steps > 0) {
                    steps--;
                    progress.forEach((progressStep, idx) => {
                        if (idx === steps) {
                            progressStep.classList.add("active-step");
                        } else {
                            progressStep.classList.remove("active-step");
                        }
                    });
                }
                cardSelector?.scrollBy(-305, 0);
            });
            nextStep?.addEventListener('click', () => {
                if (steps < stepsSliderlist - 1) {
                    steps++;
                    progress.forEach((progressStep, idx) => {
                        if (idx === steps) {
                            progressStep.classList.add("active-step");
                        } else {
                            progressStep.classList.remove("active-step");
                        }
                    });
                }
                cardSelector?.scrollBy(305, 0);
            });
        };

        if (this.stepsSlider) {
            setupSeviceSlider(this.stepsSlider);
            let firstMenuTop = document.querySelectorAll(".mega-menu");
            let slideBlock: any = document.querySelector(".slide-block");
            let slideBlockH = slideBlock?.offsetHeight;
            let mql = window.matchMedia("(max-width: 768px)")


            // if (mql.matches) {
            //     firstMenuTop?.forEach((element:any) => {
            //         element.style.top = slideBlockH - 34 + "px";
            //     });
            // }
        }

       
    }

 
}

class WindowSlider {
    constructor() {
      this.initWindowSlider();
      if (window.screen && window.screen.width < 1024) {
        this.initWindowWrapSlider();
      }
    }
  
    // This function for Desktop
    private initWindowSlider() {
      const windowSliderElements = document.querySelectorAll(".window-slider");
      this.initSlider(windowSliderElements);
    }
  
    // This function for Mobile devices
    private initWindowWrapSlider() {
      const windowWrapElements = document.querySelectorAll(".window-wrap");
      this.initSlider(windowWrapElements);
    }
  
    private initSlider(nodeList: NodeListOf<Element>) {
      let countSliders = document.getElementsByClassName('window-list-wrapper');
      for (let i = 0; i < countSliders.length; i++) {
        if (nodeList[i]) {
          const slider = tns({
            container: nodeList[i] as HTMLElement,
            items: 1,
            slideBy:"page",
            loop: false,
            navPosition: "bottom",
            controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
            responsive: {
              640: {
                items: 2,
              },
              1024: {
                items: 4
              }
            },
          });
        }
      }
    }
  }
  
class LogoImgSlider {
    constructor() {
        this.logoImgSliderFunction();
    }

    private logoImgSliderFunction() {
        const logoImgSlider = document.querySelector(".logo-img-slider") as HTMLElement;
        const logoImgSliderWrap = document.querySelector(".logo-img-carousel-wrap") as HTMLElement;
        if (logoImgSlider) {
            let itemsCount = logoImgSlider.querySelectorAll(".logo-img-carousel-item").length;
            if (itemsCount < 5 && window.innerWidth >= 1024) {
                logoImgSlider.classList.remove("logo-img-slider");
                logoImgSliderWrap.style.paddingBottom = "0";
            } else {
                // Initialize the slider
                tns({
                    container: logoImgSlider,
                    items: 1,
                    loop: false,
                    controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
                    responsive: {
                        1024: {
                            items: 2,
                        },
                        1200: {
                            items: 3,
                        },
                        1300: {
                            items: 5,
                        }
                    }
                });
            }
        }

    }


} 

// INTERACTIVE IMAGE POPOVER JS STRATS HERE
export class PopoverBts {
    constructor() {
        this.handlePopoverButton();
    }
    private handlePopoverButton() {
        if (window.innerWidth > 768) {
            const openPopoverBtns = document.querySelectorAll('.open-modal-btn');
            const closePopoverBtns = document.querySelectorAll('.close-popover');
            openPopoverBtns.forEach(function (btn) {
                btn.addEventListener('click', function () {
                    const popoverId: any = btn.getAttribute('data-popover-target');
                    const popover: any = document.getElementById(popoverId) as HTMLElement;
                    if (popover) {
                        popover.style.display = "flex";
                        popover.style.zIndex = "11";
                    }
                });
            });
            closePopoverBtns.forEach(function (btn) {
                btn.addEventListener('click', function () {
                    const popover: any = btn.closest('[data-popover]');
                    if (popover) {
                        popover.style.display = "none";
                        popover.style.zIndex = "5";
                    }
                });
            });
        }
        if (window.innerWidth <= 768) {
            const openModalBtns = document.querySelectorAll('.open-modal-btn');
            const popoverMobile: HTMLElement | null = document.querySelector('.popover-mobile');
            if(popoverMobile != null) {
                popoverMobile.style.display = "none";
                openModalBtns.forEach(function (btn) {
                    btn.addEventListener('click', function () {
                        const popoverModal: any = btn.nextElementSibling;
                        const popoverContent = popoverModal.innerHTML;
                        // Copy popover content to popover-mobile
                        popoverMobile.innerHTML = popoverContent;

                        // Show popover-mobile
                        popoverMobile.style.display = "flex";

                        // Add event listener to the popoverMobile button
                        const popoverMobileBtn = popoverMobile.querySelector('.close-popover');
                        popoverMobileBtn?.addEventListener('click', function () {
                            // Hide popover-mobile when popoverMobileBtn is clicked
                            popoverMobile.style.display = "none";
                        });
                        // Add event listener to the document for clicks
                        document.addEventListener('click', function (event: any) {
                            const isClickedInsidePopoverMobile = popoverMobile.contains(event.target);
                            const isClickedInsideOpenModalBtn = Array.from(openModalBtns).some(function (openModalBtn) {
                                return openModalBtn.contains(event.target);
                            });
                            // Hide popover-mobile if click is outside
                            if (!isClickedInsidePopoverMobile && !isClickedInsideOpenModalBtn) {
                                popoverMobile.style.display = "none";
                            }
                        });
                    });
                });
            }
        }
    }
}
// INTERACTIVE IMAGE POPOVER JS ENDS HERE

//MultiImage Component
export class MultiImageModal{

    private multiImgSlideIndex = 1;
    private multiImgSlides = document.getElementsByClassName("pop-up-img-slider");

    constructor(){

        const multiImage = document.querySelectorAll('.multi-img-item');
        const closeBtn:any = document.querySelector('#multiImageModal .close');
        const prevBtn:any = document.querySelector('#multiImageModal .prev');
        const nextBtn:any = document.querySelector('#multiImageModal .next');

        
        closeBtn?.addEventListener('click',()=>{
            (document.getElementById("multiImageModal")as HTMLElement).style.display = "none";
            document.body.style.overflow = '';
        })
        if(prevBtn){
            prevBtn.addEventListener("click", ()=>this.plusMultiSlides(-1));
        }
        if(nextBtn){
            nextBtn.addEventListener("click", ()=>this.plusMultiSlides(1));
        }  


        multiImage?.forEach((item) => {
            item.addEventListener('click', () => {
                const imageIdAttr = item.children[0]?.getAttribute('data-image-id');
                const imageId = imageIdAttr !== null ? Number(imageIdAttr) : 0;
                this.openModalMultiImg(imageId as any); 
            });
        });
       }
  
       public openModalMultiImg(imageId : string | null) {
        let modal = document.getElementById("multiImageModal");
        if (modal) {
            modal.style.display = "flex";
            document.body.style.overflow = 'hidden';
        }
        
        this.currentMultiSlide(imageId as any);
      }
   
      public plusMultiSlides(n:number) {

            const prevBtn:any=document.querySelector('#multiImageModal .prev');
            const nextBtn:any=document.querySelector('#multiImageModal .next');

            if(this.multiImgSlideIndex>=this.multiImgSlides.length-1 && n>0){
                this.multiImgSlideIndex = this.multiImgSlideIndex + n;
                this.showMultiSlides(this.multiImgSlideIndex);
                this.disableButton(nextBtn, true);
                this.disableButton(prevBtn, false);
            }
            else if(this.multiImgSlideIndex<=2 && n<0){
                this.multiImgSlideIndex = this.multiImgSlideIndex + n;
                this.showMultiSlides(this.multiImgSlideIndex);
                this.disableButton(nextBtn, false);
                this.disableButton(prevBtn, true);
            }
            else{
               this.multiImgSlideIndex = this.multiImgSlideIndex + n;
               this.showMultiSlides(this.multiImgSlideIndex);
               this.disableButton(nextBtn, false);
               this.disableButton(prevBtn, false);
            }
        }

     public disableButton(btn: HTMLButtonElement | null, isDisabled: boolean) {
            if (btn) {
                btn.disabled = isDisabled;
            }
        }
    
     public currentMultiSlide(n: number) {

            this.multiImgSlideIndex = n;
            this.showMultiSlides(this.multiImgSlideIndex);

            const prevBtn:any=document.querySelector('#multiImageModal .prev');
            const nextBtn:any=document.querySelector('#multiImageModal .next');
            const multiImgSlides = document.getElementsByClassName("pop-up-img-slider");

            if(this.multiImgSlideIndex===multiImgSlides.length){
                this.disableButton(nextBtn, true);
            }
            else if(this.multiImgSlideIndex===1){
                this.disableButton(prevBtn, true);
                this.disableButton(nextBtn, false);
            }
            else{
                this.disableButton(nextBtn, false);
                this.disableButton(prevBtn, false);
            }

        };

    public showMultiSlides(n: number) {
        let i;
        const multiImgSlides = document.getElementsByClassName("pop-up-img-slider");
        if (n > multiImgSlides.length) {
            this.multiImgSlideIndex = 1;
        }
        if (n < 1) {
            this.multiImgSlideIndex =  multiImgSlides.length;
        }
        for (i = 0; i <  multiImgSlides.length; i++) {
            ( multiImgSlides[i] as HTMLElement).style.display = "none";
        }
        ( multiImgSlides[this.multiImgSlideIndex - 1] as HTMLElement).style.display = "flex";
        };
    }

    export class InspirationGallerySlider{
        private imageSlideIndex = 1;
        private imageSlides = document.getElementsByClassName("pop-up-slider");
        constructor(){
            const galleryImage = document.querySelectorAll('.photo-card-item');
            const closeBtn:any = document.querySelector('#galleryModal .close');
            const prevBtn:any = document.querySelector('#galleryModal .prev');
            const nextBtn:any = document.querySelector('#galleryModal .next');

            closeBtn?.addEventListener('click',()=>{
                (document.getElementById("galleryModal")as HTMLElement).style.display = "none";
                document.body.style.overflow = '';
            })
            if(prevBtn){
                prevBtn.addEventListener("click", ()=>this.plusSlides(-1));
            }
            if(nextBtn){
                nextBtn.addEventListener("click", ()=>this.plusSlides(1));
            }  

            galleryImage?.forEach((item) => {
                if(item.querySelector(".video-area") === null){
                    item.addEventListener('click', () => {
                        const imageIdAttr = item.children[0]?.getAttribute('data-photo-id');
                        const imageId = imageIdAttr !== null ? Number(imageIdAttr) : 0;
                        this.openModalImg(imageId as any); 
                    });
                }
            });

            this.copySocialLink();
        }
        public openModalImg(imageId : string | null) {
            let modal = document.getElementById("galleryModal");
            if (modal) {
                modal.style.display = "flex";
                document.body.style.overflow = 'hidden';
            }
            this.currentSlide(imageId as any);
          }
          public plusSlides(n:number) {

            const prevBtn:any=document.querySelector('#galleryModal .prev');
            const nextBtn:any=document.querySelector('#galleryModal .next');

            if(this.imageSlideIndex>=this.imageSlides.length-1 && n>0){
                this.imageSlideIndex = this.imageSlideIndex + n;
                this.showSlides(this.imageSlideIndex);
                this.disableGalleryButton(nextBtn, true);
                this.disableGalleryButton(prevBtn, false);
            }
            else if(this.imageSlideIndex<=2 && n<0){
                this.imageSlideIndex = this.imageSlideIndex + n;
                this.showSlides(this.imageSlideIndex);
                this.disableGalleryButton(nextBtn, false);
                this.disableGalleryButton(prevBtn, true);
            }
            else{
               this.imageSlideIndex = this.imageSlideIndex + n;
               this.showSlides(this.imageSlideIndex);
               this.disableGalleryButton(nextBtn, false);
               this.disableGalleryButton(prevBtn, false);
            }
        }

     public disableGalleryButton(btn: HTMLButtonElement | null, isDisabled: boolean) {
            if (btn) {
                btn.disabled = isDisabled;
            }
        }
    
     public currentSlide(n: number) {

            this.imageSlideIndex = n;
            this.showSlides(this.imageSlideIndex);

            const prevBtn:any=document.querySelector('#galleryModal .prev');
            const nextBtn:any=document.querySelector('#galleryModal .next');
            const imageSlides = document.getElementsByClassName("pop-up-slider");

            if(this.imageSlideIndex===imageSlides.length){
                this.disableGalleryButton(nextBtn, true);
            }
            else if(this.imageSlideIndex===1){
                this.disableGalleryButton(prevBtn, true);
                this.disableGalleryButton(nextBtn, false);
            }
            else{
                this.disableGalleryButton(nextBtn, false);
                this.disableGalleryButton(prevBtn, false);
            }

        };

    public showSlides(n: number) {
        let i;
        const imageSlides = document.getElementsByClassName("pop-up-slider");
        if (n > imageSlides.length) {
            this.imageSlideIndex = 1;
        }
        if (n < 1) {
            this.imageSlideIndex =  imageSlides.length;
        }
        for (i = 0; i < imageSlides.length; i++) {
            ( imageSlides[i] as HTMLElement).style.display = "none";
        }
        ( imageSlides[this.imageSlideIndex - 1] as HTMLElement).style.display = "flex";
        };
    
    public copySocialLink(){

        const copyBlogLink: NodeListOf<Element> = document.querySelectorAll(".copy_link_share");

        // Loop through each element to add event listeners
        copyBlogLink.forEach((element: Element) => {
            element.addEventListener("click", (event: Event) => {
                event.preventDefault();
                let urlImg: string = decodeURIComponent(window.location.href);
                navigator.clipboard.writeText(urlImg)
                    .then(() => {
                        console.log('URL copied to clipboard'); 
                        alert(`${urlImg}\n Copied to Clipboard`);
                    })
                    .catch((err: any) => {
                        console.error('Failed to copy URL: ', err);
                    });
            });
        });

    }

    }

    export class TransformationSlider{
        constructor(){
        this.transFormationSlider();
        }
     
        private  transFormationSlider() {
            let transfSlider = document.querySelector(".transformation-slider")as HTMLElement;
            if (transfSlider) {
                tns({
                    container: transfSlider,
                    items: 1,
                    loop: false,
                    gutter: 10,
                    controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
                });
            }
        }
    }

export class SolutionMagazineSlider {
    constructor(){
        this.solutionMagazineSlider();
        const solMagazineSlider = document.querySelector(".solution-magazine-slider");
        // Add event listener for slider change
        solMagazineSlider?.addEventListener('transitionend', this.handleSolutionSliderChange);
        // Initial setup with a slight delay to ensure everything is loaded
        setTimeout(this.handleSolutionSliderChange, 100);
    }

    private solutionMagazineSlider(){

        //SOLUTION MAGAZINE SECTION SLIDER JS STARTS HERE
        let solMagazineSlider = document.querySelector(".solution-magazine-slider");
        if (solMagazineSlider) {
            tns({
                container: solMagazineSlider,
                items: 1,
                loop: false,
                gutter: 10,
                controlsText: ['<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-white"></use></svg>', '<svg class="slider-control"><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>'],
                navContainer: "#magazine-thumbnails",
                navAsThumbnails: true,
                swipeAngle: false,
                onInit: this.handleSolutionSliderChange
            });
        }
    }

    private handleSolutionSliderChange() {
        
        const sliderElement = document.querySelector('.solution-magazine-slider') as HTMLLIElement;
        if(sliderElement){

            const activeIndex = Array.from(sliderElement.children).findIndex(child => child.classList.contains('tns-slide-active'));
            
            const thumbnails = document.querySelectorAll('.thumbnails li') as any;
            const totalThumbnails = thumbnails.length;

            thumbnails.forEach(function(thumbnail:any) {
                thumbnail.style.display = 'none';
            });

            // Calculate the range of thumbnails to show
            const startIndex = Math.max(0, activeIndex - 2);
            const endIndex = Math.min(totalThumbnails, startIndex + 6);
            const numVisibleThumbnails = endIndex - startIndex;

            // Show the thumbnails within the range
            for (let i = startIndex; i < endIndex; i++) {
                thumbnails[i].style.display = 'inline-block';
            }

            // Adjust thumbnails if less than 6 are visible
            const additionalThumbnails = 6 - numVisibleThumbnails;
            if (additionalThumbnails > 0) {
                const startIndexToAdjust = Math.max(0, startIndex - additionalThumbnails);
                for (let j = startIndexToAdjust; j < startIndex; j++) {
                    thumbnails[j].style.display = 'inline-block';
                }
            }
        }
    }

}

document.addEventListener("DOMContentLoaded", () => {
    const stickyHeader = new StickyHeader();
    const asideDiv = new AsideDiv();
    const offersliderElement = new TinySlider();
    const customerReviewSlider = new CustomerReviewSlider();
    const photoGallerySlider = new PhotoGallerySlider();
    const chargerServiceSlider = new ChargerServiceSlider();
    const windowSlider = new WindowSlider();
    const textElement = document.querySelector(".residential-text") as HTMLElement;
    const readMoreButton = document.getElementById("readMoreButton") as HTMLElement;
    new LogoImgSlider();
    new PopoverBts();
    new MultiImageModal();
    new InspirationGallerySlider();
    new TransformationSlider();
    new SolutionMagazineSlider();
    if (textElement) {
        const fullText = textElement.innerHTML;
        const maxLength = 375;

        if (fullText.length > maxLength) {
            const visibleText = fullText.substring(0, maxLength);
            const hiddenText = fullText.substring(maxLength);

            textElement.innerHTML = `${visibleText}<span class="hidden-text" style="display:none;">${hiddenText}</span>`;
            readMoreButton.classList.remove("hidden");
        }

        readMoreButton.addEventListener("click", function() {
            const hiddenTextElement = document.querySelector(".hidden-text")as HTMLElement;

            if (hiddenTextElement.style.display === "none") {
                hiddenTextElement.style.display = "inline";
                readMoreButton.textContent = "read less";
            } else {
                hiddenTextElement.style.display = "none";
                readMoreButton.textContent = "... read more";
            }
        });
    }
});



