import axios from "axios";

export class EventService {
    constructor() {
        const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value;
        if (pageType && pageType.toLocaleLowerCase() == 'opus 2 event details page') {
            this.init();
        }
    }
    async init() {
        try {
            const dbaName = localStorage.getItem('dbaName');
            let url = `${window.location.origin}/events/event-landing.json`;
            if (dbaName) {
                url = `${window.location.origin}/${dbaName}/event-landing.json`
            }
            const eventDetails = await axios(url);
            const data = eventDetails?.data ?? [];
            const pathname = window.location.pathname.replace(/\/$/, '');
            const index = data.findIndex((x: any) => x.href.replace(/\/$/, '') === pathname);
            this.updatePaginationButtons(index, data);

        } catch (error) {
            this.disablesPagination();
            console.error("Error fetching event details:", error);
        }
    }


    disablesPagination() {
        const elements = document.querySelectorAll('.events-section .pagination-link');
        elements.forEach((element: Element, index: number, nodeList: NodeListOf<Element>) => {
            (element as HTMLElement).style.opacity = '0.3';
            (element as HTMLElement).style.pointerEvents = 'none';
        });
    }
    

    updatePaginationButtons(index: number, data: any[]) {

        const btns: any = document.querySelectorAll('.events-section .pagination-link');
        const prevBtn = btns[0];
        const nextBtn = btns[1];

        if (index !== -1) {
            if (index > 0) {
                prevBtn.href = data[index - 1].href;
            } else {
                prevBtn.disabled = true;
                prevBtn.style.opacity = '0.3';
            }

            if (index < data.length - 1) {
                nextBtn.href = data[index + 1].href;
            } else {
                nextBtn.disabled = true;
                nextBtn.style.opacity = '0.3';
            }
        }
    }
}

const eventService = new EventService();
