import apiRequest from "../api/apiRequest";
import { getCountryCodefromAssetpath } from "./share";


export async function getDynamicMenuResponse() {
    let dynamicMenuURLResponse: any = {};
    dynamicMenuURLResponse = localStorage.getItem('dynamicMenuURLResponse');
    dynamicMenuURLResponse = JSON.parse(dynamicMenuURLResponse);
    // if dynamicMenuURLResponse is existing in Session Storage return the value
    if(dynamicMenuURLResponse || dynamicMenuURLResponse !== null ) {
        
        if(!sessionStorage.getItem('filterService')){
            setFilterService(dynamicMenuURLResponse);
        }

        return dynamicMenuURLResponse;
    }

    const brandName  = document.getElementById('brandName') as HTMLInputElement
    // const countryCode = document.getElementById('countryCode') as HTMLInputElement;
    // const country_code = countryCode?.value.toLocaleLowerCase();
    const country_code = getCountryCodefromAssetpath();
    const url = window.location.origin;   
    const dynamic_url = `${url}/${country_code}/en-${country_code}/${brandName?.value}/_assets/js/data/dynamic_menu.json`
    const request = {
          method: 'GET',
          url: dynamic_url,
    };
    
    try {
        const response = await apiRequest(request);
        dynamicMenuURLResponse = response;
        localStorage.setItem('dynamicMenuURLResponse', JSON.stringify(dynamicMenuURLResponse));
        setFilterService(response);
        return dynamicMenuURLResponse;
    } catch (error) {
        console.log("Error while calling dynamic menu json:", error);
        throw error; // Re-throw the error for the calling function to handle
    }
}

function setFilterService(response:any){

    const data = response.serviceMenu;
    const options = data["country"][0].brand[0].option;
    const brandMenu = options;
    sessionStorage.setItem('filterService',JSON.stringify(brandMenu));

}
