import { parse } from "path/win32";
import { REGEX } from "../constants/regex";
import errorMessages from "../util/errorMessages";
import { currentActiveStep } from "./step2";
import { HMSStep4 } from "./step4";
import { hmsProgressBarUpdate } from "../util/share";
 
export class Step3 {
 
    readonly PHONE_NUMBER_LENGTH: number = 14;
    public selectInspector!: HTMLInputElement;
    public selectedValue:any = '';
    public ownerNumberInput!: HTMLInputElement;
    public homeOwnerInspectionOver18: any = HTMLInputElement;
    public ownerNameInput: any = HTMLInputElement;
    public ownerLastNameInput: any = HTMLInputElement;
    public ownerEmailInput!: HTMLInputElement;
    public form: any;
    public selectedScheduling:string = "";
    public inspectionPresent: any =  HTMLInputElement;
 
    public agentPropertyAccess: any =  HTMLInputElement;
    public agentInspectionPresent: any = HTMLInputElement;
    public agentNameInput: any = HTMLInputElement;
    public agentLastNameInput: any = HTMLInputElement;
    public agentNumberInput!: HTMLInputElement;
    public agentEmailInput!: HTMLInputElement;
    public agentAgencyNameInput!: HTMLInputElement;
    public agentClientNameInput: any = HTMLInputElement;
    public agentClientLastNameInput: any = HTMLInputElement;
    public agentClientNumberInput!: HTMLInputElement;
    public agentClientEmailInput!: HTMLInputElement;
    public agentClientRecieveUpdate: any = HTMLInputElement;
    public agentClientAgencyNameInput:any = HTMLInputElement;
    public agentsAgentNameInput: any = HTMLInputElement;
    public agentsAgentLastNameInput: any = HTMLInputElement;
    public agentsAgentNumberInput!: HTMLInputElement;
    public agentsAgentEmailInput!: HTMLInputElement;
    public agentsRecieveUpdate: any = HTMLInputElement;
    public agentsAgencyNameInput: any =  HTMLInputElement;
 
    public buyerPropertyAccess: any =  HTMLInputElement;
    public buyerInspectionPresent: any = HTMLInputElement;
    public buyerNameInput: any = HTMLInputElement;
    public buyerLastNameInput: any = HTMLInputElement;
    public buyerNumberInput!: HTMLInputElement;
    public buyerEmailInput!: HTMLInputElement;
    public buyerAgentNameInput: any = HTMLInputElement;
    public buyerAgentLastNameInput: any = HTMLInputElement;
    public buyerAgentNumberInput!: HTMLInputElement;
    public buyerAgentEmailInput!: HTMLInputElement;
    public buyerAgentRecieveUpdate: any = HTMLInputElement;
    public buyerAgentAgencyNameInput: any =  HTMLInputElement;
    public buyerSellerAgentNameInput: any = HTMLInputElement;
    public buyerSellerAgentLastNameInput: any = HTMLInputElement;
    public buyerSellerAgentNumberInput!: HTMLInputElement;
    public buyerSellerAgentEmailInput!: HTMLInputElement;
    public buyerSellerRecieveUpdate: any = HTMLInputElement;
    public buyerSellerAgencyNameInput: any =  HTMLInputElement;
 
    public sellerPropertyAccess: any =  HTMLInputElement;
    public sellerInspectionPresent: any = HTMLInputElement;
    public sellerNameInput: any = HTMLInputElement;
    public sellerLastNameInput: any = HTMLInputElement;
    public sellerNumberInput!: HTMLInputElement;
    public sellerEmailInput!: HTMLInputElement;
    public sellerAgentNameInput: any = HTMLInputElement;
    public sellerAgentLastNameInput: any = HTMLInputElement;
    public sellerAgentNumberInput!: HTMLInputElement;
    public sellerAgentEmailInput!: HTMLInputElement;
    public sellerAgentRecieveUpdate: any = HTMLInputElement;
    public sellerAgentAgencyNameInput: any =  HTMLInputElement;
    public buyersAgentNameInput: any = HTMLInputElement;
    public buyersAgentLastNameInput: any = HTMLInputElement;
    public buyersAgentNumberInput!: HTMLInputElement;
    public buyersAgentEmailInput!: HTMLInputElement;
    public buyersAgentRecieveUpdate: any = HTMLInputElement;
    public buyersAgentAgencyNameInput: any =  HTMLInputElement;
   
    public agentInformationYes: any = HTMLInputElement;
    public agentInformationNo: any = HTMLInputElement;
    public buyersAgentDetail: any = HTMLInputElement;
    public buyerAgentYes: any = HTMLInputElement;
    public buyerAgentNo: any = HTMLInputElement;
    public buyerAgentDetail: any = HTMLInputElement;
    public buyerInformationYes: any = HTMLInputElement;
    public buyerInformationNo: any = HTMLInputElement;
    public buyerSellerAgentDetail: any = HTMLInputElement;
    public sellerAgentYes: any = HTMLInputElement;
    public sellerAgentNo: any = HTMLInputElement;
    public sellerAgentDetail: any = HTMLInputElement;
    public sellerInformationYes: any = HTMLInputElement;
    public sellerInformationNo: any = HTMLInputElement;
    public sellerBuyersAgentDetail: any = HTMLInputElement;
    public hideDropdown: any = null;
    public agentBuyerYes: any = HTMLInputElement;
    public agentSellerYes: any = HTMLInputElement;
   
   
    constructor() {
        this.initInputs();
        this.initForm();
        this.initCustomSelect();
        this.initSelectInspector();
        this.radioToggle();
        // hmsProgressBarUpdate('25%', 1);
        // this.editForm('homeowner');
        // this.editForm('buyer');
        // this.editForm('seller');
        // this.editForm('agent');
        this.handleDOMContentLoaded();       
        this.addToggleEventListeners(this.agentInformationYes, this.agentInformationNo, this.buyersAgentDetail);
        this.addToggleEventListeners(this.buyerInformationYes, this.buyerInformationNo, this.buyerSellerAgentDetail);
        this.addToggleEventListeners(this.buyerAgentYes, this.buyerAgentNo, this.buyerAgentDetail);
        this.addToggleEventListeners(this.sellerAgentYes, this.sellerAgentNo, this.sellerAgentDetail);
        this.addToggleEventListeners(this.sellerInformationYes, this.sellerInformationNo, this.sellerBuyersAgentDetail);
 
        window.scrollTo(0,0);
        this.editSchedule();
        this.handleSelectInspectorClick = this.handleSelectInspectorClick.bind(this);
    }


    editSchedule(){
        if(this.selectInspector?.value){
            this.hideAllDivs();
            this.showDivById(this.selectInspector?.value);
        }
    }

    hideErrorMessage(errorMsgId: string) {
        const errorMsg = document.getElementById(errorMsgId) as HTMLElement;
        if (errorMsg) {
            errorMsg.classList.add('hidden');
        }
    }

    public radioToggle() {
        const buyerOrSellerRadios = document.querySelectorAll('input[name="agent-buyer-seller-radio"]');
        buyerOrSellerRadios.forEach((radio) => {
            radio.addEventListener("change", () => {
                this.updateText();
            });
        });
    }
    public updateText(){
        const buyerOrSeller: any = document.querySelectorAll('input[name="agent-buyer-seller-radio"]') as unknown as  HTMLInputElement;
        const buyerOrSellerElements: any = document.querySelectorAll(".buyer-or-seller") as unknown as HTMLElement;
        if (buyerOrSeller[0].checked) { 
            buyerOrSellerElements.forEach(function(element:any) {
                if (element.innerText.includes("Buyer")) {
                    element.innerText = element.innerText.replace("Buyer’s", "Seller’s");
                } else if (element.innerText.includes("Seller")) {
                    element.innerText = element.innerText.replace("Seller’s", "Buyer’s");
                }
            });
        } else if (buyerOrSeller[1].checked) { 
            buyerOrSellerElements.forEach(function(element:any) {
                if (element.innerText.includes("Seller")) {
                    element.innerText = element.innerText.replace("Seller’s", "Buyer’s");
                } else if (element.innerText.includes("Buyer")) {
                    element.innerText = element.innerText.replace("Buyer’s", "Seller’s");
                }
            });
        }
    }

    public addToggleEventListeners(yesButton: HTMLInputElement | null, noButton: HTMLInputElement | null, detailElement: HTMLElement | null) {
        if (yesButton && noButton && detailElement) {
            if (noButton.checked) {
                detailElement.style.display = 'none';
            }    
            yesButton.addEventListener('click', () => {
                detailElement.style.display = '';
            });
 
            noButton.addEventListener('click', () => {
                detailElement.style.display = 'none';
            });
        }
    }
    
    public initInputs(): void {
        this.agentBuyerYes = document.getElementById('agent-buyer-yes') as HTMLInputElement;
        this.agentSellerYes = document.getElementById('agent-seller-yes') as HTMLInputElement;
      
        this.agentInformationYes = document.getElementById('agent-information-yes') as HTMLInputElement;
        this.agentInformationNo = document.getElementById('agent-information-no') as HTMLInputElement;
        this.buyersAgentDetail = document.getElementById('agentBuyersAgentDetail') as HTMLInputElement;
 
        this.buyerInformationYes = document.getElementById('buyer-information-yes') as HTMLInputElement;
        this.buyerInformationNo = document.getElementById('buyer-information-no') as HTMLInputElement;
        this.buyerSellerAgentDetail = document.getElementById('buyerSellerAgentDetail') as HTMLInputElement;
 
        this.buyerAgentYes = document.getElementById('buyer-agent-yes') as HTMLInputElement;
        this.buyerAgentNo = document.getElementById('buyer-agent-no') as HTMLInputElement;
        this.buyerAgentDetail = document.getElementById('buyerAgentDetail') as HTMLInputElement;
 
        this.sellerAgentYes = document.getElementById('seller-agent-yes') as HTMLInputElement;
        this.sellerAgentNo = document.getElementById('seller-agent-no') as HTMLInputElement;
        this.sellerAgentDetail = document.getElementById('sellerAgentDetail') as HTMLInputElement;
 
        this.sellerInformationYes = document.getElementById('seller-information-yes') as HTMLInputElement;
        this.sellerInformationNo = document.getElementById('seller-information-no') as HTMLInputElement;
        this.sellerBuyersAgentDetail = document.getElementById('sellerBuyersAgentDetail') as HTMLInputElement;

        this.inspectionPresent = document.getElementsByClassName('inspection-present') as unknown as HTMLElement;
        this.selectInspector = document.getElementById('select-inspector') as HTMLInputElement;
        this.ownerNumberInput = document.getElementById('owner-number') as HTMLInputElement;
        this.homeOwnerInspectionOver18 = document.getElementById('yes-18') as HTMLInputElement;
        this.ownerNameInput = document.getElementById('owner-name') as HTMLInputElement;
        this.ownerLastNameInput = document.getElementById('owner-lname') as HTMLInputElement;
        this.ownerEmailInput = document.getElementById('owner-email') as HTMLInputElement;
       
        this.agentPropertyAccess = document.getElementById('agent-property-access') as HTMLInputElement;
        this.agentInspectionPresent = document.getElementById('agent-buyer-agent') as HTMLInputElement;
        this.agentNameInput = document.getElementById('agent-name') as HTMLInputElement;
        this.agentLastNameInput = document.getElementById('agent-lname') as HTMLInputElement;
        this.agentNumberInput = document.getElementById('agent-number') as HTMLInputElement;
        this.agentEmailInput = document.getElementById('agent-email') as HTMLInputElement;
        this.agentAgencyNameInput = document.getElementById('agent-agency-name') as HTMLInputElement;
        this.agentClientNameInput = document.getElementById('agent-client-name') as HTMLInputElement;
        this.agentClientLastNameInput = document.getElementById('agent-client-lname') as HTMLInputElement;
        this.agentClientNumberInput = document.getElementById('agent-client-number') as HTMLInputElement;
        this.agentClientEmailInput = document.getElementById('agent-client-email') as HTMLInputElement;
        this.agentClientRecieveUpdate = document.getElementById('agent-client-receive-update') as HTMLInputElement;
        this.agentClientAgencyNameInput = document.getElementById('agent-client-agency-name') as HTMLInputElement;
        this.agentsAgentNameInput = document.getElementById('agents-agent-name') as HTMLInputElement;
        this.agentsAgentLastNameInput = document.getElementById('agents-agent-lname') as HTMLInputElement;
        this.agentsAgentNumberInput = document.getElementById('agents-agent-number') as HTMLInputElement;
        this.agentsAgentEmailInput = document.getElementById('agents-agent-email') as HTMLInputElement;
        this.agentsRecieveUpdate = document.getElementById('agents-receive-update') as HTMLInputElement;
        this.agentsAgencyNameInput = document.getElementById('agents-agency-name') as HTMLInputElement;
 
        this.buyerPropertyAccess = document.getElementById('buyer-property-access') as HTMLInputElement;
        this.buyerInspectionPresent = document.getElementById('buyer-the-buyer') as HTMLInputElement;
        this.buyerNameInput = document.getElementById('buyer-name') as HTMLInputElement;
        this.buyerLastNameInput = document.getElementById('buyer-lname') as HTMLInputElement;
        this.buyerNumberInput = document.getElementById('buyer-number') as HTMLInputElement;
        this.buyerEmailInput = document.getElementById('buyer-email') as HTMLInputElement;
        this.buyerAgentNameInput = document.getElementById('buyer-agent-name') as HTMLInputElement;
        this.buyerAgentLastNameInput = document.getElementById('buyer-agent-lname') as HTMLInputElement;
        this.buyerAgentNumberInput = document.getElementById('buyer-agent-number') as HTMLInputElement;
        this.buyerAgentEmailInput = document.getElementById('buyer-agent-email') as HTMLInputElement;
        this.buyerAgentRecieveUpdate = document.getElementById('buyer-agent-receive-update') as HTMLInputElement;
        this.buyerAgentAgencyNameInput = document.getElementById('buyer-agent-agency-name') as HTMLInputElement;
        this.buyerSellerAgentNameInput = document.getElementById('buyer-seller-agent-name') as HTMLInputElement;
        this.buyerSellerAgentLastNameInput = document.getElementById('buyer-seller-agent-lname') as HTMLInputElement;
        this.buyerSellerAgentNumberInput = document.getElementById('buyer-seller-agent-number') as HTMLInputElement;
        this.buyerSellerAgentEmailInput = document.getElementById('buyer-seller-agent-email') as HTMLInputElement;
        this.buyerSellerRecieveUpdate = document.getElementById('buyer-seller-receive-update') as HTMLInputElement;
        this.buyerSellerAgencyNameInput = document.getElementById('buyer-seller-agency-name') as HTMLInputElement;
 
        this.sellerPropertyAccess = document.getElementById('seller-property-access') as HTMLInputElement;
        this.sellerInspectionPresent = document.getElementById('seller-buyer-agent') as HTMLInputElement;
        this.sellerNameInput = document.getElementById('seller-name') as HTMLInputElement;
        this.sellerLastNameInput = document.getElementById('seller-lname') as HTMLInputElement;
        this.sellerNumberInput = document.getElementById('seller-number') as HTMLInputElement;
        this.sellerEmailInput = document.getElementById('seller-email') as HTMLInputElement;
        this.sellerAgentNameInput = document.getElementById('seller-agent-name') as HTMLInputElement;
        this.sellerAgentLastNameInput = document.getElementById('seller-agent-lname') as HTMLInputElement;
        this.sellerAgentNumberInput = document.getElementById('seller-agent-number') as HTMLInputElement;
        this.sellerAgentEmailInput = document.getElementById('seller-agent-email') as HTMLInputElement;
        this.sellerAgentRecieveUpdate = document.getElementById('seller-agent-receive-update') as HTMLInputElement;
        this.sellerAgentAgencyNameInput = document.getElementById('seller-agent-agency-name') as HTMLInputElement;
        this.buyersAgentNameInput = document.getElementById('buyers-agent-name') as HTMLInputElement;
        this.buyersAgentLastNameInput = document.getElementById('buyers-agent-lname') as HTMLInputElement;
        this.buyersAgentNumberInput = document.getElementById('buyers-agent-number') as HTMLInputElement;
        this.buyersAgentEmailInput = document.getElementById('buyers-agent-email') as HTMLInputElement;
        this.buyersAgentRecieveUpdate = document.getElementById('buyers-receive-update') as HTMLInputElement;
        this.buyersAgentAgencyNameInput = document.getElementById('buyers-agency-name') as HTMLInputElement;

      
        if (this.homeOwnerInspectionOver18) {
            this.homeOwnerInspectionOver18.addEventListener('input', () => this.handleError(this.homeOwnerInspectionOver18, REGEX.sendName, this.homeOwnerInspectionOver18?.id, errorMessages.invalidName));
        }

        if (this.ownerNumberInput) {
            this.ownerNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.ownerNumberInput));
        }
 
        if (this.ownerNameInput) {
            this.ownerNameInput.addEventListener('input', () => this.handleError(this.ownerNameInput, REGEX.sendName, this.ownerNameInput?.id, errorMessages.invalidName));
        }

        if (this.ownerLastNameInput) {
            this.ownerLastNameInput.addEventListener('input', () => this.handleError(this.ownerLastNameInput, REGEX.sendName, this.ownerLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.ownerEmailInput) {
            this.ownerEmailInput.addEventListener('input', () => this.handleError(this.ownerEmailInput, REGEX.sendEmail, this.ownerEmailInput?.id, errorMessages.invalidEmail));
        }
       
        if (this.agentPropertyAccess) {
            this.agentPropertyAccess.addEventListener('input', () => this.handleError(this.agentPropertyAccess, REGEX.sendName, this.agentPropertyAccess?.id, errorMessages.invalidName));
        }
 
        if (this.agentNumberInput) {
            this.agentNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.agentNumberInput));
        }
 
        if (this.agentNameInput) {
            this.agentNameInput.addEventListener('input', () => this.handleError(this.agentNameInput, REGEX.sendName, this.agentNameInput?.id, errorMessages.invalidName));
        }

        if (this.agentLastNameInput) {
            this.agentLastNameInput.addEventListener('input', () => this.handleError(this.agentLastNameInput, REGEX.sendName, this.agentLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.agentEmailInput) {
            this.agentEmailInput.addEventListener('input', () => this.handleError(this.agentEmailInput, REGEX.sendEmail, this.agentEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.agentClientNumberInput) {
            this.agentClientNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.agentClientNumberInput));
        }
 
        if (this.agentClientNameInput) {
            this.agentClientNameInput.addEventListener('input', () => this.handleError(this.agentClientNameInput, REGEX.sendName, this.agentClientNameInput?.id, errorMessages.invalidName));
        }

        if (this.agentClientLastNameInput) {
            this.agentClientLastNameInput.addEventListener('input', () => this.handleError(this.agentClientLastNameInput, REGEX.sendName, this.agentClientLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.agentClientEmailInput) {
            this.agentClientEmailInput.addEventListener('input', () => this.handleError(this.agentClientEmailInput, REGEX.sendEmail, this.agentClientEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.agentsAgentNumberInput) {
            this.agentsAgentNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.agentsAgentNumberInput));
        }
 
        if (this.agentsAgentNameInput) {
            this.agentsAgentNameInput.addEventListener('input', () => this.handleError(this.agentsAgentNameInput, REGEX.sendName, this.agentsAgentNameInput?.id, errorMessages.invalidName));
        }

        if (this.agentsAgentLastNameInput) {
            this.agentsAgentLastNameInput.addEventListener('input', () => this.handleError(this.agentsAgentLastNameInput, REGEX.sendName, this.agentsAgentLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.agentsAgentEmailInput) {
            this.agentsAgentEmailInput.addEventListener('input', () => this.handleError(this.agentsAgentEmailInput, REGEX.sendEmail, this.agentsAgentEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.buyerPropertyAccess) {
            this.buyerPropertyAccess.addEventListener('input', () => this.handleError(this.buyerPropertyAccess, REGEX.sendName, this.buyerPropertyAccess?.id, errorMessages.invalidName));
        }
 
        if (this.buyerNumberInput) {
            this.buyerNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.buyerNumberInput));
        }
 
        if (this.buyerNameInput) {
            this.buyerNameInput.addEventListener('input', () => this.handleError(this.buyerNameInput, REGEX.sendName, this.buyerNameInput?.id, errorMessages.invalidName));
        }

        if (this.buyerLastNameInput) {
            this.buyerLastNameInput.addEventListener('input', () => this.handleError(this.buyerLastNameInput, REGEX.sendName, this.buyerLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.buyerEmailInput) {
            this.buyerEmailInput.addEventListener('input', () => this.handleError(this.buyerEmailInput, REGEX.sendEmail, this.buyerEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.buyerAgentNumberInput) {
            this.buyerAgentNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.buyerAgentNumberInput));
        }
 
        if (this.buyerAgentNameInput) {
            this.buyerAgentNameInput.addEventListener('input', () => this.handleError(this.buyerAgentNameInput, REGEX.sendName, this.buyerAgentNameInput?.id, errorMessages.invalidName));
        }

        if (this.buyerAgentLastNameInput) {
            this.buyerAgentLastNameInput.addEventListener('input', () => this.handleError(this.buyerAgentLastNameInput, REGEX.sendName, this.buyerAgentLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.buyerAgentEmailInput) {
            this.buyerAgentEmailInput.addEventListener('input', () => this.handleError(this.buyerAgentEmailInput, REGEX.sendEmail, this.buyerAgentEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.buyerSellerAgentNumberInput) {
            this.buyerSellerAgentNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.buyerSellerAgentNumberInput));
        }
 
        if (this.buyerSellerAgentNameInput) {
            this.buyerSellerAgentNameInput.addEventListener('input', () => this.handleError(this.buyerSellerAgentNameInput, REGEX.sendName, this.buyerSellerAgentNameInput?.id, errorMessages.invalidName));
        }

        if (this.buyerSellerAgentLastNameInput) {
            this.buyerSellerAgentLastNameInput.addEventListener('input', () => this.handleError(this.buyerSellerAgentLastNameInput, REGEX.sendName, this.buyerSellerAgentLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.buyerSellerAgentEmailInput) {
            this.buyerSellerAgentEmailInput.addEventListener('input', () => this.handleError(this.buyerSellerAgentEmailInput, REGEX.sendEmail, this.buyerSellerAgentEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.sellerPropertyAccess) {
            this.sellerPropertyAccess.addEventListener('input', () => this.handleError(this.sellerPropertyAccess, REGEX.sendName, this.sellerPropertyAccess?.id, errorMessages.invalidName));
        }
 
        if (this.sellerNumberInput) {
            this.sellerNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.sellerNumberInput));
        }
 
        if (this.sellerNameInput) {
            this.sellerNameInput.addEventListener('input', () => this.handleError(this.sellerNameInput, REGEX.sendName, this.sellerNameInput?.id, errorMessages.invalidName));
        }

        if (this.sellerLastNameInput) {
            this.sellerLastNameInput.addEventListener('input', () => this.handleError(this.sellerLastNameInput, REGEX.sendName, this.sellerLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.sellerEmailInput) {
            this.sellerEmailInput.addEventListener('input', () => this.handleError(this.sellerEmailInput, REGEX.sendEmail, this.sellerEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.sellerAgentNumberInput) {
            this.sellerAgentNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.sellerAgentNumberInput));
        }
 
        if (this.sellerAgentNameInput) {
            this.sellerAgentNameInput.addEventListener('input', () => this.handleError(this.sellerAgentNameInput, REGEX.sendName, this.sellerAgentNameInput?.id, errorMessages.invalidName));
        }

        if (this.sellerAgentLastNameInput) {
            this.sellerAgentLastNameInput.addEventListener('input', () => this.handleError(this.sellerAgentLastNameInput, REGEX.sendName, this.sellerAgentLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.sellerAgentEmailInput) {
            this.sellerAgentEmailInput.addEventListener('input', () => this.handleError(this.sellerAgentEmailInput, REGEX.sendEmail, this.sellerAgentEmailInput?.id, errorMessages.invalidEmail));
        }
 
        if (this.buyersAgentNumberInput) {
            this.buyersAgentNumberInput.addEventListener('input', (e)=>this.handleMobileInput(e,this.buyersAgentNumberInput));
        }
 
        if (this.buyersAgentNameInput) {
            this.buyersAgentNameInput.addEventListener('input', () => this.handleError(this.buyersAgentNameInput, REGEX.sendName, this.buyersAgentNameInput?.id, errorMessages.invalidName));
        }

        if (this.buyersAgentLastNameInput) {
            this.buyersAgentLastNameInput.addEventListener('input', () => this.handleError(this.buyersAgentLastNameInput, REGEX.sendName, this.buyersAgentLastNameInput?.id, errorMessages.invalidName));
        }
 
        if (this.buyersAgentEmailInput) {
            this.buyersAgentEmailInput.addEventListener('input', () => this.handleError(this.buyersAgentEmailInput, REGEX.sendEmail, this.buyersAgentEmailInput?.id, errorMessages.invalidEmail));
        }
 
 
    }
 
    private initForm(): void {
        this.form = document.querySelectorAll('#inspection-step .btn-next');
 
        if (this.form && this.form[0]) {
            this.form[0].addEventListener('click', this.handleSubmit.bind(this));
        }

        const cancelBtn = document.querySelectorAll('#inspection-step .btn-cancel');

        if (cancelBtn && cancelBtn[0]) {
            cancelBtn[0].addEventListener('click', this.handleGoBack.bind(this.selectedValue));
        }
    }
 
    private initCustomSelect(): void {
        const customSelectOption = document.querySelectorAll<HTMLElement>('.custom-select .select-selected');
        const customSelect = document.querySelectorAll<HTMLElement>('.custom-select .select-items');
 
        if (customSelectOption.length >= 2) {
            (customSelectOption[1] as HTMLButtonElement).disabled = true;
        }
 
        customSelect.forEach((selectItem, i) => {
            selectItem.addEventListener('click', function (this: HTMLElement) {
                const option = customSelectOption[i];
                if (option) {
                    option.style.backgroundColor = "var(--primary-500)";
                    option.style.color = "var(--white)";
                }
            });
        });
 
        if (customSelectOption[0]) {
            customSelect[0].addEventListener('click', function () {
                customSelectOption[0].style.backgroundColor = "var(--primary-500)";
                customSelectOption[0].style.color = "var(--white)";
                (customSelectOption[1] as HTMLElement)?.removeAttribute('disabled');
            });
        }
    }
 
    private initSelectInspector(): void {
        const self = this;
              const selectInspectorButton = document.querySelector('.custom-select #select-inspector + button.select-selected') as HTMLButtonElement;
 
            if (selectInspectorButton) {
                selectInspectorButton.addEventListener('click', self.handleSelectInspectorClick.bind(self));
            }
        
 
        this.hideAllDivs();
    }
 
    handleSelectInspectorClick(event: MouseEvent): void {
        const errorMessage = document.getElementById("select-inspector-error-msg");
        if (errorMessage) {
            errorMessage.classList.add("hidden");
        }
        this.selectedValue = (event.target as HTMLElement)?.textContent;
 
        this.hideAllDivs();
        this.showDivById(this.selectedValue);
 
        event.stopPropagation();
        
        const selectInspectorButton = document.querySelector('.custom-select #select-inspector + button.select-selected') as HTMLButtonElement;
        this.closeAllSelect(selectInspectorButton);
 
        const selectOptionsContainer = selectInspectorButton?.nextElementSibling as HTMLElement;
 
        if (selectOptionsContainer) {
            selectOptionsContainer.classList?.toggle("select-hide");
            selectInspectorButton.classList?.toggle("select-arrow-active");
        }
        const closeSelectOptionsOnEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                selectOptionsContainer.classList.add("select-hide");
                selectInspectorButton.classList.remove("select-arrow-active");
                document.removeEventListener('keydown', closeSelectOptionsOnEscape);
            }
            
        };
 
        document.addEventListener('keydown', closeSelectOptionsOnEscape);
    }
 
    closeAllSelect(elmnt: HTMLElement): void {
        const x = document.getElementsByClassName("select-items slim-scroll");
        const y = document.getElementsByClassName("select-selected");
        const xl = x.length;
        const yl = y.length;
        const arrNo: number[] = [];
        for (let i = 0; i < yl; i++) {
            if (elmnt === y[i]) {
                arrNo.push(i);
            } else {
                y[i].classList.remove("select-arrow-active");
            }
        }
        for (let i = 0; i < xl; i++) {
            if (!arrNo.includes(i)) {
                x[i].classList.add("select-hide");
            }
        }
    }
 
    hideAllDivs(): void {
        const divs: NodeListOf<HTMLDivElement> = document.querySelectorAll('.selected-person');
        divs.forEach((div: HTMLDivElement) => {
            div.style.display = 'none';
        });
    }
 
    showDivById(id: any): void {
        const div = document.getElementById(id);
        if (div) {
            div.style.display = 'flex';
        }
    }
 
    public getCheckedInspectionPresent = () => {
        let checkedCheckboxes: any[] = [];
        checkedCheckboxes = Array.from(document.querySelectorAll('.inspection-present') as NodeListOf<HTMLInputElement>)
            .filter((checkbox: HTMLInputElement) => checkbox.checked)
            .map((checkbox: HTMLInputElement) => checkbox.value);
        return checkedCheckboxes; 
    };
 
    public getAgentSellerValue(): string {
        const yesRadioButton = document.getElementById('agent-buyer-yes') as HTMLInputElement;
        const noRadioButton = document.getElementById('agent-seller-yes') as HTMLInputElement;
    
        const isYesChecked = yesRadioButton?.checked;
        const isNoChecked = noRadioButton?.checked;
    
        return isYesChecked ? yesRadioButton.value : isNoChecked ? noRadioButton.value : 'Not Specified';
    }
   
    nextStep4(){
        const propertyStepElement = document.getElementById('property-step') as HTMLElement;
        currentActiveStep(propertyStepElement);
        new HMSStep4();
        hmsProgressBarUpdate('50%', 2);
    }
   
    public handleSubmit(): void {
       
        if (this.validateForm()) {
            const propertyStepElement = document.getElementById('property-step') as HTMLElement;
            const inspectionStepElement = document.getElementById('inspection-step') as HTMLElement;
   
            let bookingData: any = JSON.parse(sessionStorage.getItem('hms_booking') as string) ?? {};
            const HmsRoleInfo: any = this.generateHmsRoleInfo(this.selectedValue);
            bookingData.HmsRoleInfo = {HmsRoleDetail:HmsRoleInfo.HmsRoleDetail,
                                        ...bookingData?.HmsRoleInfo
                                        };
           
            // bookingData.HmsRoleInfo = HmsRoleInfo;
            bookingData = this.updateBookingDataPayload(bookingData)

            sessionStorage.setItem('hms_booking', JSON.stringify(bookingData));
   
            console.log(HmsRoleInfo, "HmsRoleInformation........");
            this.nextStep4();
        }
    }

    updateBookingDataPayload(obj:any){
        if ( this.selectedValue?.toLowerCase() === 'homeowner') {
            obj.HmsRoleInfo.HmsRoleKey="CONTACT_SUBTYPE_HOME_OWNER";
            obj.HmsRoleInfo.HmsRoleType="CUSTOMER";
            obj.FirstName = this.ownerNameInput?.value;
            obj.LastName = this.ownerLastNameInput?.value;
            obj.Email = this.ownerEmailInput?.value;
            obj.Phone = this.ownerNumberInput?.value;
        }
 
        if (this.selectedValue?.toLowerCase() === 'agent') {
            if(this.agentBuyerYes?.checked){
                obj.HmsRoleInfo.HmsRoleKey="CONTACT_SUBTYPE_BUYER_AGENT";
            }
            if(this.agentSellerYes?.checked){
                obj.HmsRoleInfo.HmsRoleKey="CONTACT_SUBTYPE_SELLER_AGENT";  
            }
            obj.HmsRoleInfo.HmsRoleType="CUSTOMER";
            obj.FirstName = this.agentNameInput?.value;
            obj.LastName = this.agentLastNameInput?.value;
            obj.Email = this.agentEmailInput?.value;
            obj.Phone = this.agentNumberInput?.value;
        }
 
        if (this.selectedValue?.toLowerCase() === 'buyer') {
            obj.HmsRoleInfo.HmsRoleKey="CONTACT_SUBTYPE_BUYER";
            obj.HmsRoleInfo.HmsRoleType="CUSTOMER";
            obj.FirstName = this.buyerNameInput?.value;
            obj.LastName = this.buyerLastNameInput?.value;
            obj.Email = this.buyerEmailInput?.value;
            obj.Phone = this.buyerNumberInput?.value;
        }
       
        if ( this.selectedValue?.toLowerCase() === 'seller') {
            obj.HmsRoleInfo.HmsRoleKey="CONTACT_SUBTYPE_SELLER";
            obj.HmsRoleInfo.HmsRoleType="CUSTOMER";
            obj.FirstName = this.sellerNameInput?.value;
            obj.LastName = this.sellerLastNameInput?.value;
            obj.Email = this.sellerEmailInput?.value;
            obj.Phone = this.sellerNumberInput?.value;
        }

        return obj;
    }
   
    public generateHmsRoleInfo(selectedValue: any){
        const HmsRoleInfo: any = {
            "HmsRoleDetail": {}
        };
       
        if ( this.selectedValue?.toLowerCase() === 'homeowner') {
            HmsRoleInfo.HmsRoleDetail = {
                "willOwnerOrAdultAttend": this.homeOwnerInspectionOver18?.checked,
                "OwnerFirstName": this.ownerNameInput?.value,
                "OwnerLastName": this.ownerLastNameInput?.value,
                "OwnerPhone": this.ownerNumberInput?.value,
                "OwnerEmail": this.ownerEmailInput?.value,
                "PresentForInspection": [],
                "BuyerAgentFirstName": null,
                "BuyerAgentLastName": null,
                "BuyerAgentPhone": null,
                "BuyerAgentEmail": null,
                "BuyerNameOfAgency": null,
                "BuyerAgentReceiveTextMessages": false,
                "SellerAgentFirstName": null,
                "SellerAgentLastName": null,
                "SellerAgentPhone": null,
                "SellerAgentEmail": null,
                "SellerAgentReceiveTextMessages": false,
                "SellerNameOfAgency": null,
                "BuyerOrSellerAgent": null,
                "ClientFirstName": null,
                "ClientLastName": null,
                "ClientPhone": null,
                "ClientEmail": null,
                "ClientReceiveTextMessages": false
            
            };
        }
 
        if (this.selectedValue?.toLowerCase() === 'agent') {
            HmsRoleInfo.HmsRoleDetail = {
                "PresentForInspection": this.getCheckedInspectionPresent(),
                "AgentPropertyAcsess": this.agentPropertyAccess?.value,
                "AgentBuyerOrSeller": this.getAgentSellerValue(),
                "ClientFirstName": this.agentClientNameInput?.value,
                "ClientLastName": this.agentClientLastNameInput?.value,
                "ClientPhone": this.agentClientNumberInput?.value,
                "ClientEmail": this.agentClientEmailInput?.value,
                "AgentNameOfAgency": this.agentClientAgencyNameInput?.value,
                "ClientReceiveTextMessages": this.agentClientRecieveUpdate?.checked,
            };
            if(this.agentBuyerYes?.checked){
                const agentBuyerInfo= {
                "BuyerAgentFirstName": this.agentNameInput?.value,
                "BuyerAgentLastName": this.agentLastNameInput?.value,
                "BuyerAgentPhone": this.agentNumberInput?.value,
                "BuyerAgentEmail": this.agentEmailInput?.value,
                "BuyerNameOfAgency": this.agentAgencyNameInput?.value,  
                }
                HmsRoleInfo.HmsRoleDetail = {...agentBuyerInfo, ...HmsRoleInfo.HmsRoleDetail}
                if(this.agentInformationYes?.checked){
                    const agentInfo = {
                        "SellerAgentFirstName": this.agentsAgentNameInput?.value,
                        "SellerAgentLastName": this.agentsAgentLastNameInput?.value,
                        "SellerAgentPhone": this.agentsAgentNumberInput?.value,
                        "SellerAgentEmail": this.agentsAgentEmailInput?.value,
                        "SellerNameOfAgency": this.agentsAgencyNameInput?.value,
                        "SellerAgentReceiveTextMessages": this.agentsRecieveUpdate?.checked,
                    }
                    HmsRoleInfo.HmsRoleDetail = {...agentInfo, ...HmsRoleInfo.HmsRoleDetail} 
                }
            }

            if(this.agentSellerYes?.checked){
                const agentSellerInfo= {
                    "SellerAgentFirstName": this.agentNameInput?.value,
                    "SellerAgentLastName": this.agentLastNameInput?.value,
                    "SellerAgentPhone": this.agentNumberInput?.value,
                    "SellerAgentEmail": this.agentEmailInput?.value,
                    "SellerNameOfAgency": this.agentAgencyNameInput?.value,  
                    }
                    HmsRoleInfo.HmsRoleDetail = {...agentSellerInfo, ...HmsRoleInfo.HmsRoleDetail}  
            }
            if(this.agentInformationYes?.checked){
                const agentInfo = {
                    "BuyerAgentFirstName": this.agentsAgentNameInput?.value,
                    "BuyerAgentLastName": this.agentsAgentLastNameInput?.value,
                    "BuyerAgentPhone": this.agentsAgentNumberInput?.value,
                    "BuyerAgentEmail": this.agentsAgentEmailInput?.value,
                    "BuyerNameOfAgency": this.agentsAgencyNameInput?.value,
                    "BuyerAgentReceiveTextMessages": this.agentsRecieveUpdate?.checked,
                }
                HmsRoleInfo.HmsRoleDetail = {...agentInfo, ...HmsRoleInfo.HmsRoleDetail} 
            }
         
        }
 
        if (this.selectedValue?.toLowerCase() === 'buyer') {
            HmsRoleInfo.HmsRoleDetail = {
                "PresentForInspection": this.getCheckedInspectionPresent(),
                "BuyerPropertyAcess": this.buyerPropertyAccess?.value,
                "BuyerFirstName": this.buyerNameInput?.value,
                "BuyerLastName": this.buyerLastNameInput?.value,
                "BuyerPhone": this.buyerNumberInput?.value,
                "BuyerEmail": this.buyerEmailInput?.value,
            };
            if(this.buyerAgentYes?.checked ){
                const buyerArray = {
                    "BuyerAgentFirstName": this.buyerAgentNameInput?.value,
                    "BuyerAgentLastName": this.buyerAgentLastNameInput.value,
                    "BuyerAgentPhone": this.buyerAgentNumberInput?.value,
                    "BuyerAgentEmail": this.buyerAgentEmailInput?.value,
                    "BuyerAgentReceiveTextMessages": this.buyerAgentRecieveUpdate?.checked,
                    "BuyerNameOfAgency": this.buyerAgentAgencyNameInput?.value,
                }
                HmsRoleInfo.HmsRoleDetail = {...buyerArray, ...HmsRoleInfo.HmsRoleDetail} 
            }
            if( this.buyerInformationYes?.checked){
                const buyerInfo = {
                "SellerAgentFirstName": this.buyerSellerAgentNameInput?.value,
                "SellerAgentLastName": this.buyerSellerAgentLastNameInput?.value,
                "SellerAgentPhone": this.buyerSellerAgentNumberInput?.value,
                "SellerAgentEmail": this.buyerSellerAgentEmailInput.value,
                "SellerAgentReceiveTextMessages": this.buyerSellerRecieveUpdate?.checked,
                "SellerNameOfAgency": this.buyerSellerAgencyNameInput?.value,
                } 
                HmsRoleInfo.HmsRoleDetail = {...buyerInfo, ...HmsRoleInfo.HmsRoleDetail} 
            }

        }
       
        if ( this.selectedValue?.toLowerCase() === 'seller') {
            HmsRoleInfo.HmsRoleDetail = {
                "PresentForInspection": this.getCheckedInspectionPresent(),
                "SellerPropertyAcess": this.sellerPropertyAccess?.value,
                "SellerFirstName": this.sellerNameInput?.value,
                "SellerLastName": this.sellerLastNameInput?.value,
                "SellerPhone": this.sellerNumberInput?.value,
                "SellerEmail": this.sellerEmailInput?.value,
            };
            if(this.sellerAgentYes?.checked){
                const sellerArray = { 
                    "SellerAgentFirstName": this.sellerAgentNameInput?.value,
                    "SellerAgentLastName": this.sellerAgentLastNameInput?.value,
                    "SellerAgentPhone": this.sellerAgentNumberInput?.value,
                    "SellerAgentEmail": this.sellerAgentEmailInput?.value,
                    "SellerAgentReceiveTextMessages": this.sellerAgentRecieveUpdate?.checked,
                    "SellerNameOfAgency": this.sellerAgentAgencyNameInput?.value,
    
                }
                HmsRoleInfo.HmsRoleDetail = {...sellerArray, ...HmsRoleInfo.HmsRoleDetail} 
            }
             if( this.sellerInformationYes?.checked){
                const sellerInfo = { 
                    "BuyerAgentFirstName": this.buyersAgentNameInput?.value,
                    "BuyerAgentLastName": this.buyersAgentLastNameInput?.value,
                    "BuyerAgentPhone": this.buyersAgentNumberInput?.value,
                    "BuyerAgentEmail": this.buyersAgentEmailInput?.value,
                    "BuyerAgentReceiveTextMessages": this.buyersAgentRecieveUpdate?.checked,
                    "BuyerNameOfAgency": this.buyersAgentAgencyNameInput?.value,
                }
                HmsRoleInfo.HmsRoleDetail = {...sellerInfo, ...HmsRoleInfo.HmsRoleDetail}
             }

        }
        return HmsRoleInfo;
    }
 
 
   public editForm(selectValue: any){
 
    const hms_booking_data =  JSON.parse(sessionStorage.getItem('hms_booking') as string);
    let checkedInspectionPresent = this.getCheckedInspectionPresent();
 
        if(selectValue.toLowerCase() ==='homeowner'){
            this.homeOwnerInspectionOver18.checked = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.willOwnerOrAdultAttend;
            this.ownerNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.OwnerFirstName || '';
            this.ownerLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.OwnerLastName || '';
            this.ownerNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.OwnerPhone || '';
            this.ownerEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.OwnerEmail || '';
        }
        if(selectValue.toLowerCase() ==='agent'){
            checkedInspectionPresent = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.PresentForInspection;
            this.agentPropertyAccess.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentPropertyAcsess || '';
            this.agentNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentFirstName || '';
            this.agentLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentLastName || '';
            this.agentNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentPhone || '';
            this.agentEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentEmail || '';
            this.agentAgencyNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentNameOfAgency ||'';
            this.agentClientNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentClientFirstName || '';
            this.agentClientLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentClientLastName || '';
            this.agentClientNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentClientPhone || '';
            this.agentClientEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentClientEmail || '';
            this.agentClientRecieveUpdate.checked = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentClientReceiveTextMessages;
            this.agentClientAgencyNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentClientNameOfAgency || '';
            this.agentsAgentNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentsAgentFirstName || '';
            this.agentsAgentLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentsAgentLastName || '';
            this.agentsAgentNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentsAgentPhone || '';
            this.agentsAgentEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentsAgentEmail || '';
            this.agentsAgencyNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentsAgentNameOfAgency || '';
            this.agentsRecieveUpdate.checked = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.AgentsReceiveTextMessages;
        }
 
        if(selectValue.toLowerCase() ==='buyer'){
              checkedInspectionPresent = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.PresentForInspection;
              this.buyerPropertyAccess.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerPropertyAcess || '';
              this.buyerNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerFirstName || '';
              this.buyerLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerLastName || '';
              this.buyerNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerPhone || '';
              this.buyerEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerEmail || '';
              this.buyerAgentNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerAgentFirstName || '';
              this.buyerAgentLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerAgentLastName || '';
              this.buyerAgentNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerAgentPhone || '';
              this.buyerAgentEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerAgentEmail || '';
              this.buyerAgentRecieveUpdate.checked = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerAgentReceiveTextMessages;
              this.buyerAgentAgencyNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyerNameOfAgency || '';
              this.buyerSellerAgentNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentFirstName || '';
              this.buyerSellerAgentLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentLastName || '';
              this.buyerSellerAgentNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentPhone || '';
              this.buyerSellerAgentEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentEmail || '';
              this.buyerSellerAgencyNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerNameOfAgency || '';
              this.buyersAgentRecieveUpdate.checked = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentReceiveTextMessages;
          }
 
          if(selectValue.toLowerCase() ==='seller'){
              checkedInspectionPresent = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.PresentForInspection;
              this.sellerPropertyAccess.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerPropertyAcess || '';
              this.sellerNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerFirstName || '';
              this.sellerLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerLastName || '';
              this.sellerNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerPhone || '';
              this.sellerEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerEmail || '';
              this.sellerAgentNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentFirstName || '';
              this.sellerAgentLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentLastName || '';
              this.sellerAgentNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentPhone || '';
              this.sellerAgentEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentEmail || '';
              this.sellerAgentRecieveUpdate.checked = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerAgentReceiveTextMessages;
              this.sellerAgentAgencyNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.SellerNameOfAgency || '';
              this.buyersAgentNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyersAgentFirstName || '';
              this.buyersAgentLastNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyersAgentLastName || '';
              this.buyersAgentNumberInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyersAgentPhone || '';
              this.buyersAgentEmailInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyersAgentEmail || '';
              this.buyersAgentAgencyNameInput.value = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyersNameOfAgency || '';
              this.buyersAgentRecieveUpdate.checked = hms_booking_data?.HmsRoleInfo?.HmsRoleDetail?.BuyersReceiveTextMessages;
          }
 
   }
 
 
    public validateForm(): boolean {
        let inputs: any[] = [];
        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));


        const errors: Record<string, string> = {};
  
        inputs = [
            { input: this.selectInspector, id: this.selectInspector?.id,  regex: REGEX.matchAny, errorMessage: 'Please select the option' },
        ]

        if (this.selectedValue?.toLowerCase() === 'homeowner'){
            const homeOwnerYes = document.getElementById('yes-18') as HTMLInputElement;
            const homeOwnerNo = document.getElementById('no-18') as HTMLInputElement;
            if(!this.homeOwnerInspectionOver18?.checked && !homeOwnerNo?.checked){
                const errorMsg = document.getElementById('yes-18-error-msg') as HTMLElement;
                errors['yes-18-error-msg'] = errorMsg?.innerText || 'This is mandatory Field';
                errorMsg.classList.remove('hidden');
            }

            [homeOwnerNo, homeOwnerYes].forEach(element => element.addEventListener('click', () => this.hideErrorMessage('yes-18-error-msg')));

            inputs = [
                { input: this.ownerNameInput, id: this.ownerNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                { input: this.ownerLastNameInput, id: this.ownerLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                { input: this.ownerNumberInput, id: this.ownerNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                { input: this.ownerEmailInput, id: this.ownerEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },    
            ]
         }
 
         if(this.selectedValue?.toLowerCase() === 'agent'){
            const agentTheBuyer =  document.getElementById('agent-the-buyer') as HTMLInputElement;
            const agentBuyerAgent = document.getElementById('agent-buyer-agent') as HTMLInputElement;
            const agentTheSeller = document.getElementById('agent-the-seller') as HTMLInputElement;
            const agentSellerAgent = document.getElementById('agent-seller-agent') as HTMLInputElement;
            if(!agentTheBuyer?.checked && !agentBuyerAgent?.checked && !agentTheSeller?.checked && !agentSellerAgent?.checked){
                const errorMsg = document.getElementById('agent-inspector_attend') as HTMLElement;
                errorMsg.classList.remove('hidden');
            }
         
            [agentTheBuyer, agentBuyerAgent, agentTheSeller, agentSellerAgent].forEach(element => element.addEventListener('click', () => this.hideErrorMessage('agent-inspector_attend')));

            if(!this.agentBuyerYes?.checked && !this.agentSellerYes?.checked){
                const errorMsg = document.getElementById('agent-buyer-yes-error-msg') as HTMLElement;
                errorMsg.classList.remove('hidden');
            }

            [this.agentBuyerYes, this.agentSellerYes].forEach(element => element.addEventListener('click', () => this.hideErrorMessage('agent-buyer-yes-error-msg')));

            inputs= [  
                { input: this.agentPropertyAccess, id: this.agentPropertyAccess?.id, regex: REGEX.sendName, errorMessage: 'Invalid name format.' },
                { input: this.agentNameInput, id: this.agentNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                { input: this.agentLastNameInput, id: this.agentLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                { input: this.agentNumberInput, id: this.agentNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                { input: this.agentEmailInput, id: this.agentEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
                { input: this.agentClientNameInput, id: this.agentClientNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                { input: this.agentClientLastNameInput, id: this.agentClientLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                { input: this.agentClientNumberInput, id: this.agentClientNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                { input: this.agentClientEmailInput, id: this.agentClientEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
            ]
            if(this.agentInformationYes.checked){
                const agentInfo = [
                    { input: this.agentsAgentNameInput, id: this.agentsAgentNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                    { input: this.agentsAgentLastNameInput, id: this.agentsAgentLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                    { input: this.agentsAgentNumberInput, id: this.agentsAgentNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                    { input: this.agentsAgentEmailInput, id: this.agentsAgentEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
            
                ]
                inputs = [...agentInfo, ...inputs] 
            }
         }
         if(this.selectedValue?.toLowerCase() === 'buyer'){
            const buyerTheBuyer =  document.getElementById('buyer-the-buyer') as HTMLInputElement;
            const buyerBuyerAgent = document.getElementById('buyer-buyer-agent') as HTMLInputElement;
            const buyerTheSeller = document.getElementById('buyer-the-seller') as HTMLInputElement;
            const buyerSellerAgent = document.getElementById('buyer-seller-agent') as HTMLInputElement;
            if(!buyerTheBuyer?.checked && !buyerBuyerAgent?.checked && !buyerTheSeller?.checked && !buyerSellerAgent?.checked){
                const errorMsg = document.getElementById('buyer-inspector_attend') as HTMLElement;
                errorMsg.classList.remove('hidden');
            }

            [buyerTheBuyer, buyerBuyerAgent, buyerTheSeller, buyerSellerAgent].forEach(element => element.addEventListener('click', () => this.hideErrorMessage('buyer-inspector_attend')));

                inputs = [
                { input: this.buyerPropertyAccess, id: this.buyerPropertyAccess?.id, regex: REGEX.sendName, errorMessage: 'Invalid name format.' },
                { input: this.buyerNameInput, id: this.buyerNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                { input: this.buyerLastNameInput, id: this.buyerLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                { input: this.buyerNumberInput, id: this.buyerNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                { input: this.buyerEmailInput, id: this.buyerEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
             ]
            if(this.buyerAgentYes?.checked ){
              const buyerArray = [
                { input: this.buyerAgentNameInput, id: this.buyerAgentNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid name format.' },
                { input: this.buyerAgentLastNameInput, id: this.buyerAgentLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid name format.' },
                { input: this.buyerAgentNumberInput, id: this.buyerAgentNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                { input: this.buyerAgentEmailInput, id: this.buyerAgentEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
              ]
              inputs = [...buyerArray, ...inputs]   
            }
            if( this.buyerInformationYes?.checked){
               const buyerInfo = [
                { input: this.buyerSellerAgentNameInput, id: this.buyerSellerAgentNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                { input: this.buyerSellerAgentLastNameInput, id: this.buyerSellerAgentLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                { input: this.buyerSellerAgentNumberInput, id: this.buyerSellerAgentNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                { input: this.buyerSellerAgentEmailInput, id: this.buyerSellerAgentEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
               ]
               inputs = [...buyerInfo, ...inputs] 
            }
         }
 
         if(this.selectedValue?.toLowerCase() === 'seller'){
            const sellerTheBuyer =  document.getElementById('seller-the-buyer') as HTMLInputElement;
            const sellerBuyerAgent = document.getElementById('seller-buyer-agent') as HTMLInputElement;
            const sellerTheSeller = document.getElementById('seller-the-seller') as HTMLInputElement;
            const sellerSellerAgent = document.getElementById('seller-seller-agent') as HTMLInputElement;
            if(!sellerTheBuyer?.checked && !sellerBuyerAgent?.checked && !sellerTheSeller?.checked && !sellerSellerAgent?.checked){
                const errorMsg = document.getElementById('seller-inspector_attend') as HTMLElement;
                errorMsg.classList.remove('hidden');
            }

            [sellerTheBuyer, sellerBuyerAgent, sellerTheSeller, sellerSellerAgent].forEach(element => element.addEventListener('click', () => this.hideErrorMessage('seller-inspector_attend')));
        
            inputs = [
                { input: this.sellerPropertyAccess, id: this.sellerPropertyAccess?.id, regex: REGEX.sendName, errorMessage: 'Invalid name format.' },
                { input: this.sellerNameInput, id: this.sellerNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                { input: this.sellerLastNameInput, id: this.sellerLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                { input: this.sellerNumberInput, id: this.sellerNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                { input: this.sellerEmailInput, id: this.sellerEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
             ];
            if(this.sellerAgentYes.checked){
                const sellerArray = [
                    { input: this.sellerAgentNameInput, id: this.sellerAgentNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                    { input: this.sellerAgentLastNameInput, id: this.sellerAgentLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                    { input: this.sellerAgentNumberInput, id: this.sellerAgentNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                    { input: this.sellerAgentEmailInput, id: this.sellerAgentEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
                ]
                inputs = [...sellerArray, ...inputs]  
            }
            if(this.sellerInformationYes.checked){
                const sellerInfo = [
                    { input: this.buyersAgentNameInput, id: this.buyersAgentNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid first name format.' },
                    { input: this.buyersAgentLastNameInput, id: this.buyersAgentLastNameInput?.id, regex: REGEX.sendName, errorMessage: 'Invalid last name format.' },
                    { input: this.buyersAgentNumberInput, id: this.buyersAgentNumberInput?.id, regex: null, errorMessage: 'Invalid phone format.', isPhone: true },
                    { input: this.buyersAgentEmailInput, id: this.buyersAgentEmailInput?.id, regex: REGEX.sendEmail, errorMessage: 'Invalid email format.' },
                
                ]
                inputs = [...sellerInfo, ...inputs] 
            }
         }
 
        inputs.forEach(({ input, id, regex, errorMessage }) => {
            let errorMessage2 = errorMessage;
            errorMessage = document.getElementById(`${id}-error-msg`)?.innerText ?? errorMessage;
 
            if (input.required && input.value === '') {
                errors[id] = errorMessage;
            } else if (input.value !== '' && regex && !regex.test(input.value)) {
                errors[id] = errorMessage2;
            } else if (input.value !== '' && input.isPhone && input.value.length < this.PHONE_NUMBER_LENGTH) {
                const fieldError = document.getElementById(`${id}-error-msg`);
                errors[id] = errorMessage2;
                if (fieldError) {
                    fieldError.innerHTML = errorMessage;
                    fieldError.classList.remove('hidden');
                }
            }
        });
 
        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }
 
        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-error-msg`);
            if (field && field.parentNode) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });
 
        return Object.keys(errors).length === 0;
    }

    handleGoBack (){
        const serviceStep = document.getElementById('schedule-step');
        currentActiveStep(serviceStep);
        hmsProgressBarUpdate('0%', 0);
        window.scrollTo(0,0);
    }
 
    private handleDOMContentLoaded(): void {
 
        const selectItemsDiv = document.querySelector('.select-items.slim-scroll');
 
        if (selectItemsDiv) {
            const buttons = selectItemsDiv.querySelectorAll('button[type="button"]');
 
            buttons.forEach(button => {
                button.addEventListener('click', (event:any) => {
                    buttons.forEach(btn => {
                        btn.classList.remove('same-as-selected');
                    });
                    if(this.selectInspector){
                        this.selectInspector.value = event?.target?.textContent;
                    }
                    button.classList.add('same-as-selected');
 
                    const selectOptionsContainer = button.parentNode as HTMLElement | null;
 
                    if (selectOptionsContainer) {
                        const previousSibling = selectOptionsContainer.previousElementSibling;
 
                        if (previousSibling instanceof HTMLElement) {
                            previousSibling.innerHTML = button.innerHTML;
                            this.selectedScheduling =  button.innerHTML;
                            previousSibling.click();
                        } else {
                            console.error("Previous sibling element is not an HTMLElement.");
                        }
                    } else {
                        console.error("Select options container is null or undefined.");
                    }
                });
            });
        } else {
            console.error("Parent div element not found.");
        }
    }
 
    public handleMobileInput(e: Event, numberInput: any): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = numberInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
 
        if (val && val.length < this.PHONE_NUMBER_LENGTH) {
            this.handleError(numberInput, REGEX.mobileNumberRegex, numberInput?.id, errorMessages.invalidPhoneNumber)
        } else {
            this.hideError(numberInput?.id);
        }
 
        if (x) {
            const formattedValue = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
            numberInput!.value = formattedValue;
        }
    }
 
    hideError(id: any) {
        const fieldError: any = document.getElementById(`${id}-error-msg`);
        fieldError.classList?.add('hidden');
    }
 
    handleError(elementInput: HTMLInputElement, regex: RegExp, errorMsgId: string, errorMessage: string): void {
        const fieldError = document.getElementById(`${errorMsgId}-error-msg`);
 
        if ((elementInput.value !== '' && !regex.test(elementInput.value)) && (fieldError && elementInput.required)) {
            fieldError.classList.remove('hidden');
            fieldError.innerHTML = errorMessage ?? 'Invalid format.';
        } else {
            this.hideError(errorMsgId);
        }
    }
 
 
}