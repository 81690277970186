// import { MegaMenuHandler } from "../../global";
import { StickyHeader } from "../../global";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { getFooterLink } from "../util/share";
import { SetLocalHeader } from "./SetLocalHeader";
import {setServices} from "./serviceLinks";

export default class MenuGeneratorDynamic {
  constructor() {
    // Retrieve the necessary data once in the constructor
    const dbaName: string | null = localStorage.getItem('dbaName');
    const webId: string | null = localStorage.getItem('weblocationId');
    this.getFooterMenu(dbaName);
    if (webId && dbaName && !window.location.href.includes(dbaName)) {
      this.getMenu();
    }
    const localWeblocId = (document.getElementById('local_weblocationId') as HTMLInputElement)?.value;
    const franchiseWebLocationId = sessionStorage.getItem("franchiseWebLocationId");
    if (franchiseWebLocationId || localWeblocId) {
      this.hideOurTeamsMenu();
      this.fetchPhotoGalleryData();
    }
  }

   async hideOurTeamsMenu() {
    try {
      sessionStorage.removeItem("ourTeamObject");
      const localWebID = sessionStorage.getItem("franchiseWebLocationId");
      const webID = document.getElementById('weblocationId') as HTMLInputElement;
      const webLocationId = webID?.value ? webID?.value : localWebID;
      const apiUrl = apiConfig.ourTeam + webLocationId + `?apikey=${process.env.JS_API_KEY}`;

      const request = {
        method: 'GET',
        url: apiUrl
      };
     
      const result:any = await apiRequest(request);
      if(result){
      sessionStorage.setItem('ourTeamObject', JSON.stringify(result));
      if (result?.teamInfo == null && result.teamSize == 0) {
       this.removeOurTeamMenu();
      }
     }
    } catch (err) {
      console.log('Error in rendering our teams section.', err);
      this.removeOurTeamMenu();
    }
  }

  async fetchPhotoGalleryData() {
    let photos : any = localStorage.getItem('galleryPhotos');    
    photos = JSON.parse(photos);
    if(photos && photos.length < 6) {
      let photosElem: HTMLElement | undefined;
          document.querySelectorAll(".menu-block .mb-wrap .sub-menu a.subMenu-link")?.forEach((elem: any) => {
            if (elem?.innerText.toLowerCase() == "before & after photos" || elem?.innerText.toLowerCase() == "photo gallery" || elem?.innerText.toLowerCase() == "gallery") {
              photosElem = elem;
            }
          });
          if (photosElem) {
            const parentElement = photosElem.parentElement;
            if (parentElement) {
              parentElement.classList.add("hidden");
            }
          }

          document.querySelectorAll(".menu-block .mb-wrap .menu-nested")?.forEach((elem: any) => {
            if (elem?.innerText.toLowerCase() == "before & after photos" || elem?.innerText.toLowerCase() == "photo gallery" || elem?.innerText.toLowerCase() == "gallery") {
              elem?.parentElement?.parentElement?.classList.add("hidden");
            }
          });
    }
    
  }

      getFooterMenu(dbaName: any) {
        if(dbaName) {
      
          let urlName = `${window.location.origin}/${dbaName}/config-files/_footer-config` // `https://dig-www-nei-rbw2-test.nblydev.com/${dbaName}/config-files/_footer-config`;
          const request = {
            method: 'GET',
            url : urlName
          }
          apiRequest(request)
            .then((response: any) => {
              this.insertDataIntoFooterMenu(response);
            })
            .catch((err) => {
              console.log(err);
            })
          
        }
      
      }
      

  insertDataIntoFooterMenu(footerHTML? : any) {
    const parser = new DOMParser();
    const newFooter = parser.parseFromString(footerHTML, 'text/html');
    const footerConfigHTML = document.querySelector('.fb-wrapper-link') as HTMLElement;
    const newFooterLinks = newFooter.querySelector('.fb-wrapper-link');
    if(newFooterLinks && footerConfigHTML) {
      footerConfigHTML.innerHTML = newFooterLinks.innerHTML
    }
    this.getLocalHeaderData();
  }

  getLocalHeaderData() {
       // service links 
       const headerLinks: any = document.querySelectorAll('.dynamic-service-menu')
       let data: any = []
       if (headerLinks.length > 0) {
       headerLinks.forEach((item: any) => {
           data.push({
               name: item?.querySelector('a span').textContent.trim(),
               link: item?.querySelector('a').href
   
           })
       })
       setServices(data);
   } else {
      let serviceData:any = getFooterLink();
      setServices(serviceData);
      }
  }

  async getMenu() {
    const dbaName = (document.getElementById('dbaName') as HTMLInputElement)?.value ?? localStorage.getItem('dbaName');
    const webId = (document.getElementById('weblocationId') as HTMLInputElement)?.value ?? localStorage.getItem('weblocationId');
    const locationDoingBusinessAs = sessionStorage.getItem('doingBusinessAs');
    const url = window.location.origin // === "localhost" ? `https://dig-www-nei-mre2-test.nblydev.com` : window.location.origin
    const dynamicUrl = `${url}/localmenu/${webId}/${dbaName}`;
    const request = {
      method: 'GET',
      url: dynamicUrl,
    };
    try {
    const response:any = await apiRequest(request);
    this.insertMenuIntoDOM(response);
    const stickyHeader = new StickyHeader();
    const setLocalHeader = new SetLocalHeader();
    stickyHeader.dynamicMenuV2();
    if(dbaName && locationDoingBusinessAs){
      const newHTML = `
          <svg class="icon"> <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use></svg>
              <div>
                  <span class="local-address">${locationDoingBusinessAs}</span> Change location
              </div>
          `;
          setLocalHeader.setHamburgerSubMenuInnerDba(newHTML)
  }
    
    } catch (error) {
      console.error("Error fetching menu:", error);
    }
  }


  insertMenuIntoDOM(menuHTML?: string) {
    if (menuHTML) {
      const mainNav = document.querySelector('.main-nav');
      const topNav = document.querySelector('.header-wrapper .brand-cta-list');
      const parser = new DOMParser();
      const newNav = parser.parseFromString(menuHTML, 'text/html');
      const newMainNav = newNav.getElementById("bottom-menu") as HTMLElement;
      const newTopNav = newNav.getElementById("top-menu") as HTMLElement;
      if (mainNav && newMainNav) {
        mainNav.innerHTML = newMainNav.innerHTML; // Set innerHTML to an empty string if menuHTML is undefined
        // new MegaMenuHandler()
      }
      if (topNav && newTopNav) {
        topNav.innerHTML = newTopNav.innerHTML;
      }
    }
  }

  private removeOurTeamMenu() {

    try {
      const lowerCaseTargetText = ["our team", "meet the team"];
      let ourTeamElement: HTMLElement | undefined;
    
      document.querySelectorAll(".menu-block .mb-wrap .sub-menu a.subMenu-link")?.forEach((elem: any) => {
        const lowerCaseContent = elem?.innerHTML.toLowerCase();
        if (lowerCaseTargetText.includes(lowerCaseContent)) {
          ourTeamElement = elem;
        }
      });
    
      const menuNestedElements = document.querySelectorAll(".menu-block .mb-wrap .menu-nested");
      menuNestedElements?.forEach((elem: any) => {
        const lowerCaseContent = elem?.innerHTML.toLowerCase();
        if (lowerCaseTargetText.includes(lowerCaseContent)) {
          elem?.parentElement?.parentElement?.classList.add("hidden");
        }
      });
    
      if (ourTeamElement) {
        const parentElement = ourTeamElement.parentElement;
        parentElement?.classList.add("hidden");
      }

    } catch (err) {
      console.log('Error in removeOurTeamMenu function.', err);
    }
  }
  
}

const menuGenerator = new MenuGeneratorDynamic();
