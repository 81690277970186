import axios from "axios";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import apiRequest from "../api/apiRequest";
import { updateHeaderContent } from "../util/share";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { Footer } from "../footer/footer";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import { HMSStep4 } from "./step4";
export let hms_booking: any = {}
export let isLeadDataHms: boolean = false;

export class ScheduleService {
    private headerBtnZipcode: HTMLInputElement | any;
    private localSearchInput: HTMLInputElement | any;
    private cancelBtn: HTMLInputElement | any;
    private onlineScheduling: any;
    private isLead: boolean = false
    constructor() {

        const formNext = document.querySelectorAll('#form-section .contact-us-section');

        if(formNext.length > 0) {

            const hmsBookingData = JSON.parse(sessionStorage.getItem('hms_booking') as any);
            if (hmsBookingData?.zipcode) {
                hms_booking = hmsBookingData;
                this.nextStep2(null);
                // new Step2();
            } else {
                this.init();
            }
        }
    }

    public init() {

        this.onlineScheduling = false;
        const isApp = window.location.pathname.includes('schedule-appointment');
        const local_dba: any = (document.getElementById('dbaName') as HTMLInputElement)?.value;
        const local_weblocatioId: any = (document.getElementById('weblocationId') as HTMLInputElement)?.value;
        if (local_dba && local_weblocatioId) {
            sessionStorage.setItem("isLocal", "true");
            sessionStorage.setItem("franchiseWebLocationId", local_weblocatioId)
        }
        this.CloseErrorModal();
        this.initBackBtn();
        if (sessionStorage?.isLocal == "true" && isApp) {
            this.isLead = true;
            this.getDetails(sessionStorage.franchiseWebLocationId);
        } else {
            this.localSearchInput = document.getElementById('location-search-input') as HTMLInputElement;
            this.headerBtnZipcode = document.querySelector('.zip-modal form .btn-container .primary-btn');
            this.cancelBtn = document.querySelector('#zip-modal .white-btn') as HTMLInputElement;
            if (this.headerBtnZipcode) {
                this.headerBtnZipcode.addEventListener("click", this.handleButtonSubmit.bind(this));
            }

            if (this.localSearchInput) {
                this.localSearchInput?.addEventListener('input', this.zipCodeError.bind(this))
                document.getElementById("step1-title")!.style.display = "block";
                document.getElementById("step1-subtitle")!.style.display = "none";
            };
            if (this.cancelBtn) {
                this.cancelBtn.addEventListener("click", this.handleCancelBtnClick.bind(this));
            }
        }
    }
    public handleButtonSubmit(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.validateForm()) {
            startLoader()
            const zipCode: any = this.localSearchInput.value;
            hms_booking.zipcode = zipCode
            this.franchiseLookupAddressApi(zipCode);
        }
    }
    public handleCancelBtnClick() {
        window.history.back();

    }
    public zipCodeError(this: any) {
        if (!(document.getElementById('step1-subtitle') as HTMLInputElement).classList.contains("hidden")) {
            document.getElementById("step1-title")!.style.display = "block";
            document.getElementById("step1-subtitle")!.style.display = "none";
            document.getElementById("step1-subtitle")!.classList.add('hidden');
        }
        if (this.localSearchInput.value != '' && !REGEX.sendZip.test(this.localSearchInput.value.trim())) {
            const getErrMsg = (document.getElementById(`location-search-input-modal-error-msg`) as HTMLElement).getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`location-search-input-modal-error-msg`);


            if (fieldError) {
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid zip code format.'
            }
            else {
                const field: any = this.localSearchInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid zip code format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else {

            this.hideError('location-search-input-modal')
        }

    }
    hideError(id: any) {
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field')



    }
    public validateForm(): boolean {
        const zipCodeInput = document.getElementById(`location-search-input`) as HTMLInputElement;
        const zipCode = zipCodeInput.value;
        const formFields = { zipCode };
        const errors: any = {};


        if (zipCodeInput.required && formFields.zipCode === '') {
            errors['location-search-input'] = '';
        } else if (zipCodeInput.required && !REGEX.sendZip.test(formFields.zipCode.trim())) {
            errors['location-search-input'] = 'Invalid zip code format.';
        }


        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));


        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-modal-error-msg`);
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.textContent = errorMessage
                field.classList.add("invalid-field");
                if (fieldError && errorMessage) {
                    fieldError.innerHTML = errorMessage
                }
                if (fieldError && fieldError.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });

        return Object.keys(errors).length === 0;
    }
    private franchiseLookupAddressApi(zipCode: any) {
        const request = {
            url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode))
        };
        axios.get(request.url, {
            headers: {
                'Cache-Control': 'max-age=0',
            },
        })
            .then((result: any) => {
                result = result.data;
                if (result.length > 0) {
                    const { franchiseWebLocationId, doingBusinessAs, licenseNumber, franchiseId } = result[0];
                    sessionStorage.setItem('franchiseWebLocationId', franchiseWebLocationId);
                    sessionStorage.setItem('franchiseId', result[0].franchiseId);
                    sessionStorage.setItem('doingBusinessAs', doingBusinessAs);
                    localStorage.setItem('weblocationId', franchiseWebLocationId);
                    hms_booking.franchiseWebLocationId = franchiseWebLocationId;
                    hms_booking.doingBusinessAs = doingBusinessAs;
                    hms_booking.licenseNumber = licenseNumber;
                    hms_booking.franchiseId = franchiseId;
                    sessionStorage.setItem("hms_booking", JSON.stringify(hms_booking));
                    (async () => {
                        await this.getDetails(franchiseWebLocationId);
                        const locationUrl = result[0].locationWebsiteUrl ?? '';
                        const splitUrlArr = locationUrl.toLowerCase().split("/");
                        const pathname = splitUrlArr[splitUrlArr.length - 1];
                        if (pathname) {
                            hms_booking.pathname = pathname;
                            localStorage.setItem('dbaName', pathname);
                            sessionStorage.setItem("hms_hms_booking", JSON.stringify(hms_booking));
                        }

                        this.updateInputValue("local_weblocationId", franchiseWebLocationId);
                        this.updateInputValue("weblocationId", franchiseWebLocationId);
                        this.updateInputValue("full_dbaName", pathname);
                        this.updateInputValue("dbaName", pathname);

                        const address = document.querySelector('.header-wrapper .address')
                        const desc = document.querySelector('.header-wrapper .description')

                        if (doingBusinessAs) {
                            updateHeaderContent(doingBusinessAs);
                        }

                        if (address && desc) {
                            address.innerHTML = doingBusinessAs
                            desc.innerHTML = 'Locally Owned and Operated'
                        }
                        const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement)?.value;
                        sessionStorage.setItem("manualEnteredAddress", addressEntered + ", " + zipCode);
                        sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
                        sessionStorage.setItem("RL_DBAName", doingBusinessAs);


                        /********* Triggering GTM event ********/

                        try {

                            const leadObj = {
                                event: 'custEv_LocalDataLayer',
                                locationID: franchiseWebLocationId,
                                locationDBA: doingBusinessAs,
                                locationZipcode: zipCode,
                                licenseNumber: licenseNumber
                            }
                            gtmCustomEventLeadFlow(leadObj);

                        } catch (error) {
                            console.log("An error occurred in gtmCustomEventLeadFlow: ", error);
                        }



                        try {

                            new Step2();
                            // new FormHandlerStep2();
                            // new DateSlider();
                            const ft_footer = new Footer();
                        } catch (error) {

                        }

                    })();
                    setTimeout(() => {
                        stopLoader();
                    }, 3000)
                }
                else {
                    stopLoader()
                    document.getElementById("step1-title")!.style.display = "none";
                    document.getElementById("step1-subtitle")!.style.display = "block";
                    document.getElementById("step1-subtitle")!.classList.remove('hidden');
                }
            })
            .catch((error) => {
                stopLoader();
                if (error?.response != undefined) {
                    const duplicate = error?.response?.data;
                    if (duplicate?.Message === 'Full address is needed to narrow down results') {
                        const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                        const address: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
                        const completeAddress = `${address}, ${zipCode}`;
                        this.franchiseLookupAddressApi(completeAddress);
                    } else {
                        stopLoader();
                        this.OpenErrorModal();
                    }
                }
                else {
                    stopLoader();
                    this.OpenErrorModal();
                }
            });
    }

    public nextStep2(resp: any) {
        const zipModalDiv = document.querySelector('.zip-modal') as HTMLInputElement;
        if (zipModalDiv) {
            zipModalDiv.style.display = "none";
        }
        const formSectionDiv = document.getElementById('form-section') as HTMLElement;
        if (formSectionDiv) {
            formSectionDiv.style.display = "block";
        }

        const dbaDetails = document.querySelector('.brand-license .serving') as HTMLInputElement;
        if (dbaDetails) {
            dbaDetails.innerText = `${hms_booking?.doingBusinessAs ? hms_booking?.doingBusinessAs : resp?.locationDoingBusinessAs} `;
            const licenseNumber: any = document.querySelector('.brand-license .license') as HTMLElement;
            licenseNumber.innerText = hms_booking?.licenseNumber ? hms_booking?.licenseNumber : resp?.locationDoingBusinessAs;
        }
        const formNext = document.querySelectorAll('#form-section .contact-us-section')
        formNext?.forEach((item) => {
            item.classList.remove('form-step-active')
        })
        const progress = document.querySelectorAll('.progressbar .progress-step')
        const progressBar: any = document.getElementById('progress')
        const form1 = formNext[0]
        //const form2 = formNext[1]
        const progress1 = progress[0]
        //const progress2 = progress[1]
        progressBar.style.width = "0%"
        form1.classList.add('form-step-active');
        //form2.classList.add('form-step-active')
        progress1.classList.add('progress-step-active')
        progress1.classList.add('progress-step-confirmed')
        //progress2.classList.add('progress-step-active')
        isLeadDataHms = false
    }
    private getDetails(franchiseWeblocationId: any) {
        const request = {
            method: 'GET',
            url: `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${franchiseWeblocationId}`,
        };

        return apiRequest(request)
            .then((resp: any) => {
                let callTrackNumber: any = resp?.mainPhoneNumber;
                this.onlineScheduling = resp?.onlineScheduling;

                resp?.callTrackings.forEach((type: any) => {
                    if (type.callTrackingTypeDescription == "WebsiteTracking") {
                        callTrackNumber = type.callTrackingNumber;
                        hms_booking.localPhoneNumber = callTrackNumber;
                        sessionStorage.setItem('localPhoneNumber', callTrackNumber);
                        sessionStorage.setItem("hms_booking", JSON.stringify(hms_booking));
                    }
                });
                if (this.isLead) {
                    const { franchiseWebLocationId, locationDoingBusinessAs, franchiseWebLocationMarketingId } = resp;
                    sessionStorage.setItem('franchiseWebLocationId', franchiseWebLocationId);
                    sessionStorage.setItem('franchiseId', '');
                    sessionStorage.setItem('doingBusinessAs', locationDoingBusinessAs);
                    localStorage.setItem('weblocationId', franchiseWebLocationId);
                    hms_booking.franchiseWebLocationId = franchiseWebLocationId;
                    hms_booking.doingBusinessAs = locationDoingBusinessAs;
                    hms_booking.licenseNumber = franchiseWebLocationMarketingId;
                    hms_booking.franchiseId = '';
                    sessionStorage.setItem("hms_booking", JSON.stringify(hms_booking));
                }
                const phoneNO: any = document.querySelector('.header-wrapper .form-call-cta')

                if (phoneNO) {
                    phoneNO.href = `tel:${callTrackNumber}`
                    const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                    const phoneFormat: any = String(callTrackNumber)?.replace(/\D/g, '').match(reg);
                    phoneNO.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
                }

                const mobiele: any = document.querySelector('.header-cta-mobile .primary-btn')

                if (mobiele) {
                    mobiele.href = `tel:${callTrackNumber}`

                }

                if (!resp?.onlineScheduling) {
                    const progressContainer = document.querySelector('.progressbar')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const lastChild: any = progress[2]
                    if (lastChild)
                        progressContainer?.removeChild(lastChild)
                    const progressBar: any = document.getElementById('progress')
                    progressBar.style.width = "100%"
                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const btnNext = document.querySelectorAll('.contact-us-section .btn-next')[1]
                    const form1 = formNext[0]
                    const form2 = formNext[1]
                    const progress1 = progress[0]
                    const progress2 = progress[1]
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    if (btnNext)
                        btnNext.innerHTML = 'Submit'
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')
                    isLeadDataHms = true
                }
                else {
                    this.nextStep2(resp);

                }

            })
    }
    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;

            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

    CloseErrorModal() {
        const closeBtn = document.querySelector('.error-local-modal.error-modal-HMS .error-btn-close');
        const modalOpen = document.getElementById('error-modal-HMS') as HTMLElement;
        closeBtn?.addEventListener('click', () => {
            document.getElementById("modal-hms-system-error")!.style.display = "none";
            modalOpen?.classList.add('hidden');
        })
    }
    OpenErrorModal() {
        const errorModalHms = document.querySelector(".error-local-modal.error-modal-HMS");
        if (errorModalHms) {
            errorModalHms?.classList.remove('hidden');
            document.getElementById("modal-hms-system-error")!.style.display = "block";
        }
    }
    initBackBtn() {
        const btnBack = document.querySelector("#schedule-step .cancel-verification") as HTMLElement;
        btnBack?.addEventListener('click', () => {
            const formNext = document.querySelectorAll('#form-section .contact-us-section');
            formNext?.forEach((item) => {
                item.classList.remove('form-step-active');
            })
            if (!this.isLead) {
                const formSectionDiv = document.getElementById('form-section') as HTMLInputElement;
                formSectionDiv.style.display = "none";
                const zipModalDiv = document.querySelector('.zip-modal') as HTMLInputElement;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                zipModalDiv.style.display = "";
            } else {
                this.handleCancelBtnClick();
            }
        })
    }

}
document.addEventListener("DOMContentLoaded", () => {
    const conceptId: any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    if (conceptId == 25) {
        new ScheduleService();
        new Step2();
        new Step3();
        new HMSStep4();
    }
});
