import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { getFranchiseWebLocationIdDetails, insertAfterUrl, storeBookingApiPayload } from "../util/share";
import { handleInputError } from "../util/commonValidation";
import { getBrandJSON } from "../util/locationBasedBrandJson";


export class FormHandlerAskQuestion {
    private form: HTMLFormElement | null = null;
    private mobileNumberInput: HTMLInputElement | null = null;

    private nameInput: HTMLInputElement;
    private phoneNumberInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private zipCodeInput: HTMLInputElement;
    private commentTextInput: HTMLInputElement;
    private businessNameTextInput: HTMLInputElement;
    private errorMessage: { businessErrorName: string };

    private validateNotes: boolean = false;
    private validateBusiness: boolean = false;



    constructor() {
        this.init();
        this.clearFields();        

        this.nameInput = document.getElementById('send-name') as HTMLInputElement;
        this.phoneNumberInput = document.getElementById('send-phone') as HTMLInputElement;
        this.emailInput = document.getElementById('send-email') as HTMLInputElement;
        this.zipCodeInput = document.getElementById('send-zip') as HTMLInputElement;
        this.commentTextInput = document.getElementById('send-note') as HTMLInputElement;
        this.businessNameTextInput = document.getElementById('business-name') as HTMLInputElement;

        
        this.nameInput?.addEventListener('input', this.errorOnName.bind(this))
        this.emailInput?.addEventListener('input', this.emailError.bind(this))
        this.zipCodeInput?.addEventListener('input', this.zipCodeError.bind(this))
       this.errorMessage = {
            businessErrorName:   (document.getElementById('business-name-error-msg') as HTMLElement)?.textContent ?? 'Please enter the business name'
        }
        this.checkAndBindErrorFields();

    }

    checkAndBindErrorFields(){
        if(this.commentTextInput){
            if (this.commentTextInput.hasAttribute("required")) {
                this.commentTextInput?.addEventListener('input', this.errorOnComment.bind(this));
                this.validateNotes = true;
            }
        }
        if(this.businessNameTextInput){
            if (this.businessNameTextInput.hasAttribute("required")) {
                this.businessNameTextInput?.addEventListener('input', this.errorOnInputFields.bind(this));
                this.validateBusiness = true;
            }
        }

    }

    errorOnName(){
        if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {
            const getErrMsg =   (document.getElementById(`send-name-error-msg`) as HTMLElement).getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`send-name-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
            fieldError.innerHTML =  getErrMsg || 'Invalid name format.'  
               
            }
            else{
                const field: any = this.nameInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else{
            this.hideError('send-name')
        }

    }
    emailError(){
        if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {
            const getErrMsg =   (document.getElementById(`send-email-error-msg`) as HTMLElement).getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`send-email-error-msg`);
           

            if(fieldError){
                fieldError.classList.remove('hidden')
            fieldError.innerHTML = getErrMsg || 'Invalid email address, please try again.'
               
            }
            else{
                const field: any = this.emailInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid email address, please try again.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
    
           
        }
        else{
          
            this.hideError('send-email')
        }

    }

    private handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.phoneNumberInput?.value;
        const phoneMatch = val?.replace(/\D/g, '').match(reg);
        let formattedVal = '';

        if (phoneMatch) {
          const areaCode = phoneMatch[1];
          const mainNumber = phoneMatch[2];
          const optionalDashWithPhoneNumbers = phoneMatch[3] ? '-' + phoneMatch[3] : '';
        
          formattedVal = `(${areaCode}) ${mainNumber}${optionalDashWithPhoneNumbers}`;
        }
        if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14 && formattedVal.length < 14 ) {
            const getErrMsg =   (document.getElementById(`send-phone-error-msg`) as HTMLElement).getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`send-phone-error-msg`);
            

            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid phone format.'
               
            }
            else{
                const field: any = this.phoneNumberInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid phone format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }

           
        }
        else{
         
            this.hideError.call(this, 'send-phone')
        }
 
        if (phoneMatch) {
            let formattedPhoneNumber;
            
            if (!phoneMatch[2]) {
                formattedPhoneNumber = phoneMatch[1];
            } else {
                formattedPhoneNumber = `(${phoneMatch[1]}) ${phoneMatch[2]}${phoneMatch[3] ? '-' + phoneMatch[3] : ''}`;
            }
        
            this.phoneNumberInput!.value = formattedPhoneNumber;
        }
       
    }

    zipCodeError(){
        if (this.zipCodeInput.value != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {
            const getErrMsg =   (document.getElementById(`send-zip-error-msg`) as HTMLElement).getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`send-zip-error-msg`);
         

            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid zip code format.'
            }
            else{
                const field: any = this.zipCodeInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid zip code format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
          
           
        }
        else{
          
            this.hideError('send-zip')
        }

    }
    errorOnComment(){
        if (this.commentTextInput.value == '') {
            const getErrMsg =   (document.getElementById(`send-note-error-msg`) as HTMLElement).getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`send-note-error-msg`);
            if(fieldError){
                this.commentTextInput?.classList.add('invalid-field')
                fieldError.classList.remove('hidden')
                fieldError.innerHTML =  getErrMsg || 'Enter a valid inputs'  
               
            }
            else{
                const field: any = this.nameInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Enter a valid inputs.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else{
            this.hideError('send-note')
        }
    }

    errorOnInputFields(){
        handleInputError(this.businessNameTextInput, 'business-name-error-msg', REGEX.matchAny,  this.errorMessage.businessErrorName);  
    }
    
    hideError(id:any){
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field')

      

    }

    clearFields = () => {
        const inputIds = [
            'send-state',
        ];
        //'send-note' field is removed from inputIds array as part of bug 242636
        inputIds.forEach((id) => {
            const input = document.getElementById(id) as HTMLInputElement;
            if (input) {
                input.addEventListener('input', () => {
                    input.classList.remove('invalid-field');
                    const errmsg = document.getElementById(`${id}-error-msg`);
                    if (errmsg) errmsg.remove();
                });
            }
        });
    };

    private init() {
        this.form = document.querySelector('#ask-question-form button') as HTMLFormElement;
        this.mobileNumberInput = document.getElementById('send-phone') as HTMLInputElement;
        const radioElement = document.getElementById('inline-radio-6') as HTMLInputElement;

        if (this.mobileNumberInput) {
            this.mobileNumberInput.addEventListener('input', this.handleMobileInput.bind(this));
        }

        if (this.form) {
            this.form.addEventListener('click', this.handleSubmit.bind(this));
            if (radioElement) radioElement.checked = true;
        }
    }

    private async handleSubmit(event: Event): Promise<void> {
        event.preventDefault();
        const formFields = this.getFormFields();
        var host = window.location.hostname.toLowerCase();
        let IsTest;
        if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
        {
          IsTest = true;
        }else {
          IsTest = false;
        }
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const url = window.location.origin; 
        let vendorId;
        let vendorName;
        try{
            const brandJson = getBrandJSON();
            if(brandJson === 'Error validating Location'){
                console.error("Error Determining BrandJSON path for given location")
            }
            else{
                const apiResponse = await fetch(`${url}/${brandJson}`);
                const apiData = await apiResponse.json();
        
                const matchingVendor = apiData.find((item: any) => item.id === conceptId);
                if (matchingVendor) {
                    vendorId = matchingVendor.vendorId;
                    vendorName = matchingVendor.vendorName;
                }
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        if (this.validateForm()) {

            const labelElement = document.querySelector('label[for="business-name"].label-basic') as HTMLInputElement;
            const labelText = labelElement?.textContent?.trim();
            const businessNameWithoutAsterisk = labelText?.replace('*', '');
            const businessLableName = businessNameWithoutAsterisk ? ' ,'+businessNameWithoutAsterisk+':' :'';
            const businessName = formFields.businessName ? ` ${businessLableName} ${formFields.businessName}` :'';

            const requestBody :any = {
                Comments: formFields.note + `${businessName}`,
                ConceptId: conceptId,
                Email: formFields.email,
                FirstName: formFields.firstName.split(' ')[0],
                IsLocalized: false,
                IsNewCustomer: formFields.isSignup,
                IsTest: IsTest,
                LastName: formFields.firstName.split(' ')[1],
                Phone: formFields.phoneNumber,
                SignUpForUpdates: this.checkForLatestUpdateRadio(),
                ZipCode: formFields.zipCode,
                VendorId: vendorId,
                VendorName: vendorName,
            };

            // Handle FranchiseWebLocation ID
            // If available, set isLocalized to true as per our requirement.
            const franchiseId = await getFranchiseWebLocationIdDetails(formFields.zipCode);
            if (franchiseId) {
                requestBody.franchiseWebLocationId = franchiseId;
                requestBody.IsLocalized = true;
            }

            const request = {
                method: 'POST',
                url: apiConfig.CONTACT_US_SEND,
                data: requestBody
            };

            const messageDiv = document.querySelector('.contact-info .container .contact-block') as HTMLFormElement;
            const formDiv = document.querySelector('.contact-info .container .contact-block .width-60') as HTMLFormElement;

            const responseDiv = document.getElementById("confirmation");
            const responseHtml = responseDiv?.innerHTML;
            const html = `<div class="width-60">
            <div class="contact-form-wrap contact-thanku hidden" id="confirmation">
                    ${responseHtml}
                </div>
            </div>`;
            startLoader();            
            apiRequest(request)
                .then((response: any) => {
                    stopLoader();
                    storeBookingApiPayload(requestBody,response);
                    const forms = document.getElementById('ask-question-form');
                    if (forms) {
                        forms.parentElement?.classList.add('hidden');
                    }

                    const cont = document.getElementById('confirmation');
                    if (cont) cont.classList.remove('hidden');
                    insertAfterUrl();
                    if (document.getElementById('whitepaperdoc')) {
                        const url:any = document.getElementById('whitepaperdoc');
                    
                        // Create a download link.
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url?.value;
                        a.download = 'downloaded.pdf'; // You can set the desired filename here.
                        a.target="_blank"
                    
                        document.body.appendChild(a);
                    

                        a.click();
                    
                       
                    }
                })
                .catch((err) => {
                    stopLoader();
                    const forms = document.getElementById('ask-question-form');
                    if (forms) {
                        forms.parentElement?.classList.add('hidden');
                    }

                    const cont = document.getElementById('failure');
                    if (cont) cont.classList.remove('hidden');
                });
        } else {
            // stopLoader()
        }
    }

    private getFormFields(): any {
        const fields = {
            firstName: (document.getElementById('send-name') as HTMLInputElement).value,
            zipCode: (document.getElementById('send-zip') as HTMLInputElement).value,
            phoneNumber: (document.getElementById('send-phone') as HTMLInputElement).value,
            email: (document.getElementById('send-email') as HTMLInputElement).value,
            customerType: this.getSelectedRatio(document.getElementsByName('inline-radio-cust')),
            contactMethod: this.getSelectedRatio(document.getElementsByName('inline-radio-method')),
            note: (document.getElementById('send-note') as HTMLTextAreaElement).value,
            isSignup: (document.querySelector('input[name="inline-radio-tips"]') as HTMLInputElement)?.checked,
            businessName: (document.getElementById('business-name') as HTMLInputElement)?.value,

        };
        return fields;
    }
    private checkForLatestUpdateRadio() :boolean | null {
        if ((document.getElementById('inline-radio-6')as HTMLInputElement)?.checked) {
            return true;
        }
        return false;
    }

    private getSelectedRatio(node: any): string | null {
        const radioButtons = node;
        for (const radioButton of radioButtons) {
            if ((radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return null;
    }


    private validateForm(): boolean {
        const formFields = this.getFormFields();
        // document.querySelectorAll('.error-msg').forEach((e) => e.remove());
        document.querySelectorAll('.invalid-field').forEach((e) => e.classList.remove('invalid-field'));
        const errors: any = {};

        if (formFields.firstName === '') {
            errors['send-name'] = '';
        } else if (!REGEX.sendName.test(formFields.firstName)) {
            errors['send-name'] = 'Invalid name format.';
        }

        if (formFields.zipCode === '') {
            errors['send-zip'] = '';
        } else if (!REGEX.sendZip.test(formFields.zipCode.trim()) || formFields.zipCode === '00000') {
            errors['send-zip'] = 'Invalid zip code format.';
        }

        if (formFields.phoneNumber === '') {
            errors['send-phone'] = '';
        } else if (formFields.phoneNumber.length < 14) {
            errors['send-phone'] = 'Invalid phone format.';
        }
        if(this.validateNotes && formFields.note === ''){
            errors['send-note'] = '';
        }

        if (formFields.email === '') {
            errors['send-email'] = '';
        } else if (!REGEX.sendEmail.test(formFields.email)) {
            errors['send-email'] = 'Invalid email format.';
        }

        if (!REGEX.sendCity.test(formFields.city)) {
            errors['send-city'] = 'Invalid city format.';
        }

        if (this.validateBusiness && formFields.businessName === '') {
            errors['business-name'] = '';
        } else if (!REGEX.matchAny.test(formFields.businessName)) {
            errors['business-name'] = this.errorMessage?.businessErrorName;
        }

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                // field.parentNode.appendChild(errorElement);
                if (fieldError && fieldError.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
            }
            if (index === 0 && field) {
                field.focus();
            }
        });

        return Object.keys(errors).length === 0;
    }
}

const formHandler = new FormHandlerAskQuestion();
