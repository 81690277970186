import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";

export class HeroComponent {
    private data: any;
    private pageNumber: number;
    private pageSize: number;
    private countryId: any;
    constructor() {
        this.pageNumber = 1; // Initial page number
        this.pageSize = 10; // Number of items per page
        if (sessionStorage.getItem('countryCode') != null) {
            this.countryId = sessionStorage.getItem('countryCode')?.toLowerCase() == "us" ? "1" : "2";
        } else {
            this.countryId = (document.getElementById("countryCode") as HTMLInputElement)?.value.toLowerCase() == "us" ? "1" : "2";
        }
        if (document.querySelector(".hero-review"))
            this.getData();
    }

    private popularServiceBanner() {
        const heroBanner = document.querySelector('.hero-banner.form-on') as HTMLElement;
        const popularServicesBanner = document.querySelector('.popular-services-banner') as HTMLElement;
        if (heroBanner && popularServicesBanner?.previousElementSibling) {
            popularServicesBanner.classList.add('banner-under-form');
        }
    }

    public generateRatingStars(rating: number): string {
        const fullStar = '<span class="star full">★</span>';
        const emptyStar = '<span class="star empty">☆</span>';
        const numberOfFullStars = Math.floor(rating);
        const hasHalfStar = rating % 1 !== 0;
        const numberOfEmptyStars = hasHalfStar ? 5 - numberOfFullStars - 1 : 5 - numberOfFullStars;
        return fullStar.repeat(numberOfFullStars) + (hasHalfStar ? '<span class="star half">★</span>' : '') + emptyStar.repeat(numberOfEmptyStars);
    }

    private hideReviewSection(){
        //setting visibilty hidden so that the default space is not removed and so that the banner size remains contant irrespective of reviews.
        const reviewSection = document.querySelector('.hero-review') as HTMLElement;
        try{
            reviewSection.style.visibility = "hidden";
            //for Logging
            const localwebId: any = document.getElementById('local_weblocationId');
            const getWbLocationId: any = localwebId?.value || sessionStorage.getItem('franchiseWebLocationId');
            if(getWbLocationId === null)
                console.warn("No reviews present for National Site")
            else
                console.warn(`No reviews present for ${getWbLocationId} or Invalid Location`);
            }
        catch{
            console.warn("'hero-review' class is missing in the DOM");
        }
    }

    private showReviewSection(){
       
        const reviewSection = document.querySelector('.hero-review') as HTMLElement;
        if(reviewSection)
            reviewSection.style.visibility = "visible";
        
    }
    
    private getData() {
        const conceptId: any = document.getElementById('conceptId')
        let localwebId: any = document.getElementById('local_weblocationId');
        const weblocationID: any = document.getElementById('weblocationId');
        if(localwebId===null&&weblocationID!==null)
            localwebId = weblocationID;
        const getWbLocationId: any = localwebId?.value || sessionStorage.getItem('franchiseWebLocationId');
        const request = {
            method: 'GET',
            url: apiConfig.TESTIMONIAL_REVIEWS + `?apikey=${process.env.JS_API_KEY}&ConceptId=${conceptId?.value}&CountryId=${this.countryId}&MinimumRating=4&PageNumber=1&PageSize=10&FranchiseWebLocationId=${getWbLocationId ?? ''}`
        };
        const reviewTotal = document.querySelector('.customer-data .hero-review-heading')
        const rating = document.querySelector('.rating-wrap .hero-review-heading')
        const ratingStar = document.querySelector('.rating-wrap .hero-rating .rating')
        

        apiRequest(request)
            .then((result: any) => {
                if(result.length===0 || result[0]?.overallRating === 0 || result[0]?.grandTotal === 0){
                    this.hideReviewSection();
                    
                }
                else if (reviewTotal && rating && ratingStar) {
                    reviewTotal.textContent = Number(result[0].grandTotal).toLocaleString()
                    rating.textContent = `${result[0]?.overallRating == 0 ? result[0]?.reviewStarRating : result[0]?.overallRating}/5`
                    ratingStar.innerHTML = this.generateRatingStars(result[0].reviewStarRating)
                    this.showReviewSection();
                }
                else{
                    this.hideReviewSection();
                }
            }).catch(() => {
                this.hideReviewSection();
             });
    }




}
const testimonial = new HeroComponent();
