import apiRequest from "../api/apiRequest";

export function getMessagesData() {
    const url = window.location.origin;
    const request = {
        method: 'GET',
        url: `${url}/config-files/_site-config.json`,
    };

    return apiRequest(request)
        .then(res => {
            return res
        })
        .catch((err) => {
            return {}
        });

}

