export const REGEX = {
    mobileNumberRegex:/(\d{0,3})(\d{0,3})(\d{0,4})/,
    sendName: /^[A-Za-z][A-Za-z ]*$/, // First Name
    sendLname: /^[A-Za-z][A-Za-z ]*$/, // Last Name
    sendZip: /^(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d)$/,
    sendPhone: /^(\+?[1-9]\d{1,14}|0)$/, // Phone Number
    sendEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // Email Address
    sendAddress1:/^[^!@#$%^&*()].*$/, // Address Line 1
    sendAddress2:/^[^!@#$%^&*()].*$/, // Address Line 2 (optional)
    sendCity: /^[A-Za-z][A-Za-z ]*$/, // City
    sendCaPostalCode:/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,//handle the space in zipcode for tech availibility api,
    matchAny: /^.*$/,
    sendNumber: /^[0-9]+$/,
    sendYear: /^\d{4}$/,
    allow3Digit: /^(?:[5-9]\d{2}|[1-9]\d{3,4})$/,
    allow1to9:  /^[1-9]$/,
    allow1to99: /^(?:[1-9]|[1-9][0-9]?)$/
}

export function validateLocalZipPostalCode(postal:any){
    var urlLink = window.location.href.split('/');
    var isWebSite = urlLink[3] === "ca" ? "ca" : "us";
    if (isWebSite == 'ca'){
        return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal);
    }
    if(isWebSite == 'us'){
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postal);
    }
   
}