import { getDynamicMenuResponse } from '../util/getDynamicMenu';
export class ServiceList {
   // Replace 'any[]' with the actual data structure from your API
    // public dynamicMenuURLResponse: any = {};
    constructor() {

      const serviceContainer = document.getElementById('accordion-service')
      const tabsUl  = document.querySelector('.residential-accordion .req-call-tabs')

      if(serviceContainer || tabsUl)
      this.getMenue()
    }

    async getMenue() {


        const serviceContainer = document.getElementById('accordion-service')
        const tabsUl  = document.querySelector('.residential-accordion .req-call-tabs')
        
        let response = await getDynamicMenuResponse();
        const data = response.serviceMenu;
        const options = data["country"][0].brand[0].option;
        const brandMenu = options;
        const list =  brandMenu.map((item:any)=> this.generateServiceList(item))  
        const tabs =  brandMenu.map((item:any, index:number)=> this.generateTabs(item, index)) 

         if(tabsUl){
            tabsUl.innerHTML = tabs?.join('') || '';
         }

         if(serviceContainer)
            serviceContainer.innerHTML = list[0]
      }


 
      private generateTabs (data:any, index: any){
        return(
            `<li class="req-call-tablist">
            <input type="radio" class="req-call-radio hidden tabs-service"   id="accor-${data.name.toLowerCase()}" data-nbly="accor-${data.name.toLowerCase()}" name="request-call" value="${index}" ${index==0 ? 'checked=' : ''} >
            <label for="accor-${data.name.toLowerCase()}" class="req-call-btn res-req-btn">
                <img src="/brand/_assets/images/icons/${data.name.toLowerCase()}-icon-white.svg" class="primary-icon" alt="${data.name}" width="16" height="16">
                <img src="/brand/_assets/images/icons/${data.name.toLowerCase()}-icon-gray.svg" class="gray-icon" alt="${data.name}" width="16" height="16"> ${data.name}</label>
        </li>`
        )
      }
  
    private generateAccordionItem(service: any, index:number): string {
      return `
        <li class="card-list">
          <button type="button" class="list-accordion-btn" data-accordion-target="#service${index}" aria-expanded="true" aria-controls="service${index}">
            <div class="icon-block">
              <div class="icon-wrap">
                <svg class="icon">
                  <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                </svg>
              </div>
              <div class="icon-block-text">
                <h3 class="card-title">${service.name}</h3>
              </div>
            </div>
            <div class="accordion-close accordion-arrow">
              <img src="/brand/_assets/images/icons/plus.svg" alt="Plus" width="16" height="16">
            </div>
            <div class="accordion-open accordion-arrow">
              <img src="/brand/_assets/images/icons/minus.svg" alt="Minus" width="16" height="16">
            </div>
          </button>
          <div class="card-info hidden" id="service${index}">
            <ul class="accordion-list">
              ${service.menu.map((subservice: any) => `<li>${subservice.name}</li>`).join('')}
            </ul>
          </div>
        </li>
      `;
    }
  
    public generateServiceList(data:any): string {
      return `
          ${data?.category.map((service:any, index:any) => this.generateAccordionItem(service, index)).join('')}
      `;
    }
  }

  
  
  // Example usage:
//   const apiData = [
//     {
//       id: 'accor-electrical-service',
//       name: 'Electrical Installation and Service',
//       subservices: [
//         '240 Volt Outlet Installation and Replacement',
//         'Circuit Breaker Installation and Replacement',
//         // Add more subservices here
//       ],
//     },
//     // Add more services here
//   ];
  
  const serviceList = new ServiceList();
 
 // This will log the generated HTML to the console
  