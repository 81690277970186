
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { startLoader, stopLoader } from "../util/loader";
import { FormHandlerStep2 } from "./step2"
import { AppointmentForm } from "./step3"
import { Footer } from "../../opus2/footer/footer";
import { DateSlider } from "./scheduler"
import { locationSearchMapConfig } from "../location-search-map/config";
import axios from "axios";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { getBrandDetailsFromBrandJson, updateHeaderContent } from "../util/share";

export let allAddress: any = []

export let eventListner: any = null
export let fullData: any = null
export let isLeadData: any = false
export let address1Details:any = null;
// sessionStorage.setItem("suggestion","false");
let suggestedAddress: any = ''
let flagShowPopup = false;
export let changeZip = false;
sessionStorage.removeItem("currentZip");

export function setchangeZip(value:any) {
    changeZip = value;
}
export function beforeUnloadHandler(e: any) {
    if(!e.target.activeElement.classList.contains("fb-tel") && !e.target.activeElement.classList.contains("form-call-cta")){
    // Define the message to display in the confirmation prompt
    var confirmationMessage = "Are you sure you want to leave this page?";

    // You can conditionally display the confirmation message based on the specific page
    // For example, let's say you only want to display it on a page with a specific URL path:
    var currentURL = window.location.pathname;
    if (currentURL === "/schedule-appointment") {
        // Display the confirmation message
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    }
    }
}

export class FormHandlerLead {
    private form: any;
    private nameInput: HTMLInputElement;
    private lastNameInput: HTMLInputElement;
    private phoneNumberInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private serviceAddressInput: HTMLInputElement;
    private serviceAddress2Input: HTMLInputElement;
    private zipCodeInput: HTMLInputElement;
    private showButton: Boolean;
    private onlineScheduling: any;

    constructor() {
        this.onlineScheduling = false;
        this.form = document.querySelectorAll('.contact-us-section .btn-next')
        this.showButton = false
        this.nameInput = document.getElementById('user-full-name') as HTMLInputElement;
        this.phoneNumberInput = document.getElementById('user-phone-number') as HTMLInputElement;
        this.emailInput = document.getElementById('user-email') as HTMLInputElement;
        this.serviceAddressInput = document.getElementById('user-service-address') as HTMLInputElement;
        this.serviceAddress2Input = document.getElementById('user-address-2') as HTMLInputElement;
        this.zipCodeInput = document.getElementById('zip-code') as HTMLInputElement;
        this.lastNameInput = document.getElementById('user-last-name') as HTMLInputElement;

        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
     
        const saveBtn: any = document.querySelector('.property-address-modal a.btn')
        saveBtn?.addEventListener('click', () => {
            flagShowPopup = false
            const btn: any = document.getElementById('suggested-address-radio')
            const entAddRadio:any = document.getElementById('entered-address-radio');
            if(entAddRadio?.checked) {
                sessionStorage.setItem("suggestion","false");
                this.getAutoSuggested();
            }
            console.log("saveBtn", btn?.checked)
            if (btn?.checked && suggestedAddress?.address?.label) {
                this.serviceAddressInput.value = suggestedAddress?.address?.label
                this.hadleaddressReate(this.serviceAddressInput.value);
                sessionStorage.setItem("suggestion","true");
            }
            if(this.form.length > 0) {
                this.form[0].click();
            }
        })

        this.nameInput?.addEventListener('input', this.errorOnName.bind(this))
        this.emailInput?.addEventListener('input', this.emailError.bind(this))
        this.serviceAddressInput?.addEventListener('keyup', this.addreddErrorRemove2.bind(this))
        this.zipCodeInput?.addEventListener('input', this.zipCodeError.bind(this))
        this.lastNameInput?.addEventListener('input', this.errorOnLastName.bind(this));
        this.serviceAddress2Input?.addEventListener('keyup', this.address2Error.bind(this));

        if (this.form && this.form[0]) {
            // this.form[0].disabled = true
            // this.form[0].style.opacity = '0.3'
            this.form[0].addEventListener('click', this.handleSubmit.bind(this))
            const cancleBtn = document.querySelectorAll('.contact-us-section .cancel-verification')
            if (cancleBtn) {
                cancleBtn[0].addEventListener('click', () => {
                    sessionStorage.setItem("suggestion","false");
                    window.history.back();
                })
            }
        }

        if (this.phoneNumberInput) {
            this.phoneNumberInput.addEventListener('input', this.handleMobileInput.bind(this));
        }

        if (this.serviceAddressInput)
            this.serviceAddressInput.addEventListener('input', this.setAutoSuggested.bind(this));

        // window.addEventListener("beforeunload", beforeUnloadHandler);

        if (sessionStorage.getItem('doingBusinessAs')) {
            this.setHeaderCheck()
        }

        const zzip = sessionStorage.getItem('sAddressParam')

        if (zzip && this.zipCodeInput) {
            this.zipCodeInput!.value = zzip
        }
    }


    setHeaderCheck() {
        const address: any = document.querySelector('.header-wrapper .address')
        const desc = document.querySelector('.header-wrapper .description')

        if (address && desc) {
            address.innerHTML = sessionStorage.getItem('doingBusinessAs')
            desc.innerHTML = 'Locally Owned and Operated'
        }
    }

    errorOnName() {
        if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {

            const fieldError: any = document.getElementById(`user-full-name-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid name format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-full-name')
        }

    }

    errorOnLastName() {
        if (this.lastNameInput?.value !== '' && !REGEX.sendName.test(this.lastNameInput?.value)) {
            const fieldError: any = document.getElementById(`user-last-name-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid last name format.'
            this.showButton = false
        }
        else {
            this.showButton = true
            this.hideError('user-last-name')
        }

    }

    emailError() {
        if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {

            const fieldError: any = document.getElementById(`user-email-error-msg`);
            //fieldError.classList.remove('hidden')
            //fieldError.innerHTML = 'Invalid email address, please try again.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-email')
        }

    }

    zipCodeError() {
        if (this.zipCodeInput.value.trim() != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {

            const fieldError: any = document.getElementById(`zip-code-error-msg`);
            // fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid zip code format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('zip-code');
            if(this.zipCodeInput.value.trim() != sessionStorage.getItem('currentZip')){
                changeZip = true;
            }else{
                changeZip = false;
            }
        }

        // this.getAutoSuggested()

    }

    addreddErrorRemove2() {

        if (this.serviceAddressInput.value == suggestedAddress?.address?.label) {
            flagShowPopup = false
        }
        else {
            if (suggestedAddress?.address?.label)
                flagShowPopup = true
        }
        if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {

            const fieldError: any = document.getElementById(`user-service-address-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid address format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-service-address')
            this.hideError('zip-code')
        }

    }


    addreddErrorRemove() {

        if (this.serviceAddressInput.value == suggestedAddress?.address?.label) {
            flagShowPopup = false
        }
       
        if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {

            const fieldError: any = document.getElementById(`user-service-address-error-msg`);
            fieldError.classList.remove('hidden')
            fieldError.innerHTML = 'Invalid address format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError('user-service-address')
            this.hideError('zip-code')
        }

    }


    hideError(id: any) {
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError.classList.add('hidden')
        field.classList.remove('invalid-field')

        setTimeout(() => { this.showSubmitButton.call(this) }, 300)

    }


    private handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.phoneNumberInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14) {

            const fieldError: any = document.getElementById(`user-phone-number-error-msg`);
            //fieldError.classList.remove('hidden')
            //fieldError.innerHTML = 'Invalid phone format.'
            this.showButton = false
            setTimeout(() => { this.showSubmitButton.call(this) }, 300)

        }
        else {
            this.showButton = true
            this.hideError.call(this, 'user-phone-number')
        }

        if (x) {
            this.phoneNumberInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }

    }

    address2Error() {

        if (this.serviceAddress2Input?.value != '' && !REGEX.sendAddress2.test(this.serviceAddress2Input?.value)) {

            const fieldError: any = document.getElementById(`user-address-2-error-msg`);
            if(fieldError){
                fieldError.classList?.remove('hidden')
                fieldError.innerHTML = 'Invalid address2 format.'
            }
        }
        else {
            this.hideError('user-address-2')
        }
    }

    showSubmitButton() {

        // if(this.showButton && this.serviceAddressInput.value && REGEX.sendAddress1.test(this.serviceAddressInput.value) &&  this.nameInput.value && REGEX.sendName.test(this.nameInput.value) && this.phoneNumberInput.value && this.emailInput.value &&  REGEX.sendEmail.test(this.emailInput.value) && this.zipCodeInput.value && this.serviceAddressInput.value && REGEX.sendZip.test(this.zipCodeInput.value)){
        //     this.form[0].disabled = false
        //     this.form[0].style.opacity = '1'
        // }
        // else
        // {
        //     this.form[0].disabled = true
        //     this.form[0].style.opacity = '0.3'

        // }
    }

    private handleSubmit(event: Event) {
        event.preventDefault();
        
        if (this.validateForm()) {
            const currentSafeZip = document.getElementById('zip-code') as HTMLInputElement
            sessionStorage.setItem('sAddressParam',currentSafeZip.value);
            sessionStorage.setItem('currentZip',currentSafeZip.value);
            this.zipCodeInput.value = currentSafeZip.value

            console.log({ flagShowPopup , a: this.serviceAddressInput.value, b: suggestedAddress?.address?.label})
            if (this.serviceAddressInput.value.trim() == suggestedAddress?.address?.label)
                flagShowPopup = false

            if (flagShowPopup && suggestedAddress?.address?.label) {
                const callPopupModal = document.getElementById('address-modal')

                const addressData = suggestedAddress?.address?.label

                callPopupModal?.click()
                const enteredaddres = document.querySelector('[for=entered-address-radio]') as HTMLElement;
                if(enteredaddres) 
                    enteredaddres.innerHTML = this.serviceAddressInput.value;
                const suggestedaddress = document.querySelector('[for=suggested-address-radio]') as HTMLElement;
                if(suggestedaddress)
                    suggestedaddress.innerHTML = addressData;
    
            } else {
                startLoader()
                const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                this.franchiseLookupAddressApi(zipCode);
            }
        }

    }

    private franchiseLookupAddressApi(zipCode:any){
        const request = {
            url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode))
        };
        axios.get(request.url, {
            headers: {
              'Cache-Control': 'max-age=0',
            },
          })
             .then( async (result: any) => {
                result = result.data;
                const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
                
                    if (result.length > 0) {
                        const franchiseWebLocationId = result[0].franchiseWebLocationId;
                        const doingBusinessAs = result[0].doingBusinessAs;
                        const licenseNumber =  result[0].licenseNumber;


                        (async()=>{
                        await this.getDetails(franchiseWebLocationId);
                    


                        sessionStorage.setItem('franchiseWebLocationId', franchiseWebLocationId);
                        sessionStorage.setItem('franchiseId', result[0].franchiseId);
                        sessionStorage.setItem('doingBusinessAs', doingBusinessAs);
                        localStorage.setItem('weblocationId', franchiseWebLocationId);

                        const locationUrl =  result[0].locationWebsiteUrl?.replace(/\/$/, '') ?? '';
                        const splitUrlArr = locationUrl.toLowerCase().split("/");
                        const pathname = splitUrlArr[splitUrlArr.length - 1];
                        if(pathname){
                            localStorage.setItem('dbaName', pathname);
                        }
                        
                        this.updateInputValue("local_weblocationId", franchiseWebLocationId);
                        this.updateInputValue("weblocationId", franchiseWebLocationId);
                        this.updateInputValue("full_dbaName", pathname);
                        this.updateInputValue("dbaName", pathname);

                        const address = document.querySelector('.header-wrapper .address')
                        const desc = document.querySelector('.header-wrapper .description')
                        
                        if(pathname && doingBusinessAs){
                            updateHeaderContent(doingBusinessAs);                   
                        }

                        if (address && desc) {
                            address.innerHTML = doingBusinessAs
                            desc.innerHTML = 'Locally Owned and Operated'
                        }
                        const addressEntered: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
                        sessionStorage.setItem("manualEnteredAddress", addressEntered+", "+zipCode);
                        sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
                        sessionStorage.setItem("RL_DBAName",doingBusinessAs);


                        /********* Triggering GTM event ********/

                        try {
                            
                            const leadObj = {
                                event:'custEv_LocalDataLayer',
                                locationID: franchiseWebLocationId,
                                locationDBA:doingBusinessAs,
                                locationZipcode:zipCode,
                                licenseNumber:licenseNumber
                            }
                            gtmCustomEventLeadFlow(leadObj);

                        } catch (error) {
                            console.log("An error occurred in gtmCustomEventLeadFlow: ", error);
                        }
                        


                        try {
                            
                            new AppointmentForm(this.onlineScheduling);
                            new FormHandlerStep2(this.onlineScheduling);
                            new DateSlider();
                            const ft_footer = new Footer();
                        } catch (error) {

                        }

                    })();
                        setTimeout(() => {
                            stopLoader();
                        }, 3000)
                    }
                    else {

                    stopLoader()
                    if(!brandData.lead_contactapi){
                        const btnClick = document.getElementById('error-modal-id')
                        btnClick?.click()
                        const zipMessage = document.getElementById('modal-zipcode-error')
                        if (zipMessage){
                            document.getElementById('modal-appoinment-slot')?.classList.add('hidden')
                            zipMessage.innerHTML = `We're sorry. We don't currently provide service to ${zipCode}`
                            zipMessage?.classList.remove('hidden');
                        }
                    }else{
                        this.contactUsApiCall()
                    }
                }
            })
        .catch(async(error) => {
                stopLoader();
                const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
                
                    if(error?.response != undefined){
                    const duplicate = error?.response?.data;
                    if (duplicate?.Message === 'Full address is needed to narrow down results'){
                        const zipCode: any = (document.getElementById('zip-code') as HTMLInputElement).value;
                        const address: any = (document.getElementById('user-service-address') as HTMLInputElement).value;
                        const completeAddress = `${address}, ${zipCode}`;
                        this.franchiseLookupAddressApi(completeAddress);
                    }
                    }
                else {
                    stopLoader()
                    if(!brandData.lead_contactapi){
                        const btnClick = document.getElementById('error-modal-id')
                        btnClick?.click()
                        const zipMessage = document.getElementById('modal-zipcode-error')
                        if (zipMessage)
                            zipMessage.innerHTML = `We're sorry. Something went wrong. Please try again later.`
                    }else{
                        this.contactUsApiCall();
                    }
                }
            
        });
    }

   async contactUsApiCall(){

        const step3Instance = new AppointmentForm(false);
        const formData = step3Instance.getValues();
        const city = sessionStorage.getItem('manualCity');
        const state = sessionStorage.getItem('manualState');
        const country = sessionStorage.getItem('countryName');
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const zipCodeText = (document.querySelector('label[for="zip-code"]')as HTMLElement)?.innerText;
        const lastName = formData.lastName ?? '';

        let vendorId;
        let vendorName;
        
        try {

            const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

            if(brandData.lead_contactapi){
                
                if(brandData){
                    vendorId = brandData.vendorId;
                    vendorName = brandData.vendorName;
                }
                
                const requestBody = {
                    "FirstName": formData.name.split(' ')[0] ? formData.name.split(' ')[0] : '',
                    "LastName": formData.name?.split(' ')[1] ? formData.name?.split(' ')[1] : ' ',
                    "ZipCode": formData.zip,
                    "Phone":formData.phone,
                    "Email": formData.email,
                    "city": city, 
                    "state": state,
                    "country": country,
                    "address": formData.address,
                    "address2": formData.address2,
                    "Comments": zipCodeText,
                    "SignUpForUpdates": false,
                    "IsLocalized": false,
                    "IsNewCustomer": null, 
                    "IsTest": true,
                    "ConceptId": conceptId?.value,
                    "VendorId":vendorId,
                    "VendorName": vendorName
                };
            
                if(lastName){
                    requestBody.LastName = lastName;
                    requestBody.FirstName = formData.name ?? '';
                  }

                const request = {
                    method: 'POST',
                    url: apiConfig.CONTACT_US_SEND,
                    data: requestBody
                };

                await apiRequest(request);

                const contactUsObj = {       
                    "FirstName": requestBody.FirstName,
                    "LastName": requestBody.LastName,
                    "ZipCode": requestBody.ZipCode,
                    "Phone":requestBody.Phone,
                    "Email": requestBody.Email,
                    "isContact":true
                };
            
                sessionStorage.setItem('booking', JSON.stringify(contactUsObj));
                setTimeout(() =>{
                    window.history.replaceState({}, '', '/confirmation');
                    window.location.href = '/confirmation'
                },1000);

            }
        }
        catch (error) {
            console.error(`Error in Step1.ts file in the contactUsApiCall function. Error message: ${error}`);
            
        }        
    }

    private getDetails(franchiseWeblocationId: any) {
        const request = {
            method: 'GET',
            url: `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${franchiseWeblocationId}`,
        };

       return apiRequest(request)
            .then((resp: any) => {
                let callTrackNumber:  any = resp?.mainPhoneNumber;
                 this.onlineScheduling = resp?.onlineScheduling;

                resp?.callTrackings.forEach((type:any)=>{
                    if(type.callTrackingTypeDescription == "WebsiteTracking"){
                        callTrackNumber = type.callTrackingNumber;
                        sessionStorage.setItem('localPhoneNumber', callTrackNumber);
                    }
                });
                
                const phoneNO: any = document.querySelector('.header-wrapper .form-call-cta')

                if (phoneNO) {
                    phoneNO.href = `tel:${callTrackNumber}`
                    const reg = /(\d{0,3})(\d{0,3})(\d{0,4})/;
                    const phoneFormat: any = String(callTrackNumber)?.replace(/\D/g, '').match(reg);
                    phoneNO.innerHTML = `(${phoneFormat[1]}) ${phoneFormat[2]}-${phoneFormat[3]}`;
                }

                const mobiele: any = document.querySelector('.header-cta-mobile .primary-btn')

                if (mobiele) {
                    mobiele.href = `tel:${callTrackNumber}`

                }

                if (!resp?.onlineScheduling) {
                    const progressContainer = document.querySelector('.progressbar')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const lastChild: any = progress[2]
                    if (lastChild)
                        progressContainer?.removeChild(lastChild)
                    const progressBar: any = document.getElementById('progress')
                    progressBar.style.width = "100%"
                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const btnNext = document.querySelectorAll('.contact-us-section .btn-next')[1]
                    const form1 = formNext[0]
                    const form2 = formNext[1]
                    const progress1 = progress[0]
                    const progress2 = progress[1]
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    if (btnNext)
                        btnNext.innerHTML = 'Submit'
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')
                    isLeadData = true
                }
                else {
                    const formNext = document.querySelectorAll('#form-section .contact-us-section')
                    const progress = document.querySelectorAll('.progressbar .progress-step')
                    const progressBar: any = document.getElementById('progress')
                    const form1 = formNext[0]
                    const form2 = formNext[1]
                    const progress1 = progress[0]
                    const progress2 = progress[1]
                    progressBar.style.width = "50%"
                    form1.classList.remove('form-step-active')
                    form2.classList.add('form-step-active')
                    progress1.classList.remove('progress-step-active')
                    progress1.classList.add('progress-step-confirmed')
                    progress2.classList.add('progress-step-active')
                    isLeadData = false
                }

            })
    }

    private validateForm(): boolean {
        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: any = {};


        if (this.nameInput.required && this.nameInput.value === '') {
            errors['user-full-name'] = '';
        } else if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {
            errors['user-full-name'] = 'Invalid name format.';
        }

        if (this.lastNameInput?.required && this.lastNameInput?.value === '') {
            errors['user-last-name'] = '';
        } else if (this.lastNameInput?.value !== '' && !REGEX.sendName.test(this.lastNameInput?.value)) {
            errors['user-last-name'] = 'Invalid last name format.';
        }

        if (this.phoneNumberInput.required && this.phoneNumberInput.value === '') {
            errors['user-phone-number'] = '';
        }
        else if (this.phoneNumberInput.value != '' && this.phoneNumberInput?.value.length < 14) {

            const fieldError: any = document.getElementById(`user-phone-number-error-msg`);
            errors['user-phone-number'] = 'Invalid phone format.'


        }

        if (this.zipCodeInput?.required && this.zipCodeInput?.value === '') {
            errors['zip-code'] = '';
        } else if (this.zipCodeInput.value != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {
            errors['zip-code'] = 'Invalid zip code format.';
        }

        if (this.serviceAddressInput.required && this.serviceAddressInput.value === '') {
            errors['user-service-address'] = '';
        }
        else if (this.serviceAddressInput.value != '' && !REGEX.sendAddress1.test(this.serviceAddressInput.value)) {
            errors['user-service-address'] = 'Invalid address format.'

        }

        if (this.serviceAddress2Input?.required && this.serviceAddress2Input?.value === '') {
            errors['user-address-2'] = '';
        }
        else if (this.serviceAddress2Input?.value != '' && !REGEX.sendAddress2?.test(this.serviceAddress2Input?.value)) {
            errors['user-address-2'] = 'Invalid address2 format.'
        }

        if (this.emailInput.required && this.emailInput.value === '') {
            errors['user-email'] = '';
        } else if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {
            errors['user-email'] = 'Invalid email address, please try again.';
        }


        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.textContent = errorMessage
                field.classList.add("invalid-field");
                if (fieldError && errorMessage) {
                    fieldError.innerHTML = errorMessage
                }
                if (fieldError && fieldError.classList.contains('hidden'))
                    fieldError.classList.remove('hidden')
                // field.parentNode.appendChild(errorElement);
            }
            if (index == 0 && field) {
                field.focus()
            }
        });

        return Object.keys(errors).length === 0;
    }

    private setAutoSuggested = async (e: any) => {

        e.preventDefault()
        let isFlag = false
        const input: any =  document.querySelectorAll('.input-label-wrap #zip-code')[0]
        sessionStorage.setItem("suggestion","false");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        if (input && input.id == 'zip-code') {
            isFlag = true
        }
        
        const request = {
            method: 'GET',
            url: `https://discover.search.hereapi.com/v1/discover?q=${(isFlag && this.zipCodeInput.value) ? `${(document.getElementById('user-service-address') as HTMLInputElement).value}, ${this.zipCodeInput.value}` : (document.getElementById('user-service-address') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
        }
        apiRequest(request)
            .then((result: any) => {
                allAddress = result?.items
                suggestedAddress = result?.items[0]
                this.setDatat(result?.items.map((item: any) => item.title))
            })
            .catch((err) => {
            });
    }


    private getAutoSuggested = () => {
        // e.preventDefault()
        let isFlag = false
        const input = document.querySelectorAll('.input-label-wrap #zip-code')[0];
        if (input && input.id == 'zip-code') {
            isFlag = true
        }

        const request = {
            method: 'GET',
            url: `https://discover.search.hereapi.com/v1/discover?q=${(isFlag && this.zipCodeInput.value) ? `${this.zipCodeInput.value}` : (document.getElementById('user-service-address') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
        }
        apiRequest(request)
            .then((result: any) => {
                // allAddress = result?.items
                // suggestedAddress = result?.items[0]
                let manualCityVal = result?.items[0]?.address?.city;
                let manualStateVal = result?.items[0]?.address?.state;
                sessionStorage.setItem("manualCity", manualCityVal);
                sessionStorage.setItem("manualState", manualStateVal);
            })
            .catch((err) => {
            });

    }

    setZipCode = () => {
        let isFlag = true
        flagShowPopup = false
        const input: any =  document.querySelectorAll('.input-label-wrap #zip-code')[0];
        if (input && input.id == 'zip-code') {
            isFlag = false
        }

        const address = this.serviceAddressInput.value
        const item = allAddress.filter((item: any) => item.title == address)[0]
        const val = String(item?.address?.postalCode).split('-')[0]
        if (val && isFlag)
            this.zipCodeInput.value = val

        setTimeout(() => {
            this.addreddErrorRemove.call(this)
        }, 300)
    }


    private setDatat = (suggestions: any) => {
        try {

            // Get the input element
            const inputElement: any = document.getElementById("user-service-address") as HTMLInputElement;

            // Add input event listener for auto-suggestions
            const inputValue = inputElement.value.toLowerCase();
            const suggestionContainer: any = document.createElement("ul") as HTMLElement
            suggestionContainer.className = "suggestion-container";



            // Clear previous suggestions
            while (inputElement.nextSibling && inputElement.nextSibling.className === "suggestion-container") {
                inputElement.parentNode.removeChild(inputElement.nextSibling);
            }

            if (inputValue) {
                // const matchingSuggestions = suggestions.filter((suggestion: any) => {
                //     return suggestion.toLowerCase().includes(inputValue)
                // }
                // );
                suggestions.forEach((matchingSuggestion: any) => {
                    let clickedFlag : boolean = false;
                    const suggestionItem = document.createElement("li");
                    suggestionItem.className = "suggestion-item";
                    suggestionItem.textContent = matchingSuggestion;

                    suggestionItem.addEventListener("click", () => {
                        clickedFlag = true;
                        flagShowPopup = false
                        sessionStorage.setItem("suggestion","true");
                        inputElement.value = matchingSuggestion;
                        suggestionContainer.parentNode.removeChild(suggestionContainer);
                        this.setZipCode()
                        // concatinating address field with houseNumber + streetName to be passed in booking api //230654
                        this.hadleaddressReate(matchingSuggestion);
                    });
                    suggestedAddress.address.label = suggestions[0] ?? ''
                    if(!clickedFlag){
                        flagShowPopup = true;
                    }
                    
                    suggestionContainer.appendChild(suggestionItem);
                });

                if (suggestions.length > 0) {
                    inputElement.parentNode.insertBefore(suggestionContainer, inputElement.nextSibling);
                }
            }

        } catch (error) {
            console.log("error", error)
        }

    }
    hadleaddressReate(matchingSuggestion:any) {
        address1Details = '';
        const matchedAddress = allAddress?.find((address:any)=>address?.title.trim() === matchingSuggestion.trim());
        sessionStorage.setItem("stateCode",matchedAddress?.address?.stateCode);
        sessionStorage.setItem("countryName",matchedAddress?.address?.countryName)
        if(matchedAddress){
            if(matchedAddress?.address?.houseNumber && matchedAddress?.address?.street){
                address1Details = matchedAddress?.address?.houseNumber +" "+ matchedAddress?.address?.street;
                }else {
                 let hasSteert:any =   Object.keys(matchedAddress?.address).includes('street');
                 let hasHousenum:any = Object.keys(matchedAddress?.address).includes('houseNumber');
                 if(hasSteert === true && hasHousenum === false) {
                    address1Details = matchedAddress?.address?.street;
                 }
                if(hasSteert === false && hasHousenum === true) {
                    address1Details = matchedAddress?.address?.houseNumber;
                }
            }
        }
    }

    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;
    
            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

}
// Address dropdown dismmised 
document?.body?.addEventListener('click', function(event) {
    const suggestionContainer = document.querySelector('.suggestion-container') as HTMLElement;
    if(suggestionContainer?.parentNode){
        suggestionContainer.parentNode.removeChild(suggestionContainer);
    }
})

// Initialize the form handler when the DOM is ready

new FormHandlerLead();
