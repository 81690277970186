import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { startLoader, stopLoader } from "../util/loader";
import { InspectionSummary } from "./step6";
import { hmsProgressBarUpdate, returnToHomePage } from "../util/share";

export class ServiceInspection {
    inspectionPackages:any;
    inspectionServices:any;
    tableElements:any;

    //This array is declared to hold all the IDs of services offered in a chosen package.
    taken_arr:any;

    clickTracker:any;

    totalPrice:any;

    hmsBookingObject:any;

    editData:any;

    isPageInEditMode:boolean = false;

    constructor(){
        sessionStorage.setItem("hms_s5", "true");
        window.scrollTo(0,0);
        this.clickTracker = [];
        this.taken_arr = [];
        this.totalPrice = 0.0;
        this.inspectionPackages = {};
        this.inspectionServices = {};

        this.init();
        this.loadStep5();
        hmsProgressBarUpdate('75%', 3);
        this.showFooterData();
        
    }

    errorModalTrigger(){
        const target = document.getElementById('error-modal-HMS-trigger') as HTMLElement;
        target?.click();

        const errorModalHms = document.getElementById("modal-hms-system-error");
        if (errorModalHms) {
            errorModalHms.style.display = "block";
        }

        const backToHomeButton = document.querySelector("#error-modal-HMS .error-btn-close")as HTMLButtonElement;
        if(backToHomeButton)
            backToHomeButton.addEventListener("click", (()=>{
                returnToHomePage();
        }))
    }

    showFooterData(){
        const footerData = document.querySelector(".table-total") as HTMLElement;
        if(footerData){
            footerData.innerHTML = `<p>Subtotal </p>
            <strong>$0.00</strong>`;
        }
    }

    loadStep5(){
        const serviceSection = document.getElementById("service-step") as HTMLElement;
        const previousSections = document.querySelectorAll(".section-main>.contact-us-section");
        if(serviceSection && previousSections){
            previousSections.forEach((elm)=>{
                elm.classList.remove("form-step-active");
            })
            serviceSection.classList.add("form-step-active");
        }
    }

    //fetch all the services
    getServices(payload:any) {
        const request = {
            method: 'POST',
            url: `${apiConfig.GET_SERVICE_BUNDLE}?apikey=${process.env.JS_API_KEY}`,
            data: payload
        };

       return apiRequest(request)
        .then((resp: any) => {
            this.inspectionPackages = (resp.inspectionPackages)?resp.inspectionPackages.inspectionPackageServices:null;
            this.inspectionServices = (resp.inspectionServices)?resp.inspectionServices.inspectioServiceServices:null;
            this.tableElements = document.querySelector(".inspection-table")as HTMLTableElement;
            if(this.tableElements){
                this.tableElements.style.width="100%";
                const allTableValues = this.tableElements.querySelector("tbody");
                if(allTableValues)
                    allTableValues.innerHTML="";
                this.checkIfEditMode();
                this.setInspectionPackages();
                this.setInspectionServices();
                this.updateTotalCost();
            }
            stopLoader();
        })
        .catch((err) => {
            stopLoader();
            console.error("Error fetching API data : ", err);
            this.errorModalTrigger();
        });
    }

    handleClick(event:any){
        
        const clickedTarget = event.target;
        if(clickedTarget.nodeName === 'BUTTON'){
           
            const buttonClassName = clickedTarget.className;
            if(buttonClassName.includes("accrd-btn")){
                const targetId = clickedTarget.getAttribute('data-accordion-target');
                this.accordianSection(targetId);
            }
            else{
                const buttonType = buttonClassName.split(" ")[1];
                const buttonProp = {
                    "type": buttonType.split("__")[0],
                    "action": JSON.parse(buttonType.split("__")[1]),
                    "index": (clickedTarget.id).split("-")[1]
                }
                if(buttonProp.type === "package"){
                    this.clickEventForPackages(this.inspectionPackages[buttonProp.index], buttonProp.action, clickedTarget.id)
                }
                else{
                    this.clickEventForServices(this.inspectionServices[buttonProp.index], buttonProp.action, clickedTarget.id)
                }
                if(buttonProp.action)
                    this.toggleErrorDiv();
            }
            
        }
    }

    //Set date and time
    //returns time in the specified format
    setTimeAndDate(time:string){
        const options:any = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return new Date(time).toLocaleDateString("en-US", options);
    }

    //Populate the DOM with packages
    private setInspectionPackages():void{
        
        if(this.isPageInEditMode && (this.editData.HmsServiceBundles).length>0){
            this.editData.HmsServiceBundles.forEach((bundle:any)=>{
                this.clickTracker.push(bundle.hmsBundleServiceId);
                this.totalPrice+=bundle.hmsBundleServicePrice;
                this.checkforItemInServices();
                this.updateTotalCost();
            })
        }
        const allTableValues = this.tableElements.querySelector("tbody");
        allTableValues.addEventListener("click", this.handleClick.bind(this));
        this.inspectionPackages.forEach((elem:any, index: number)=>{
            let text = '';
            let packageContent = '';
            if(elem.inspectionRelatedServices.length>0){
                let listItems:string = ``;
                elem.inspectionRelatedServices.forEach((service:any)=>{
                    listItems+=(`<li>${service.inspectionRelatedServiceName}</li>`);
                });
                packageContent = `<div class="the-details hidden" id="accrd-pack-${index}"><div class="inspection-list"><strong>Included Services:</strong><ul>${listItems}</ul></div></div>`
            }
            else if(elem.inspectionServiceDescription){
                
                    packageContent = `<div class="the-details hidden" id="accrd-pack-${index}"><p class="inspection-sub-text">${elem.inspectionServiceDescription}</p></div>`
                
            }
            if(packageContent==="" || /<\/?[a-z][\s\S]*>/i.test(elem.inspectionServiceDescription))
                text = `<p class="inspection-text-main">${elem.inspectionServiceName} <button type="button" class="accrd-btn text-gray-500 dark:text-gray-400" data-accordion-target="#accrd-pack-${index}" aria-expanded="false" aria-controls="accrd-pack-${index}" style="display:none">(show included services)</button></p>`;
            else
                text = `<p class="inspection-text-main">${elem.inspectionServiceName} <button type="button" class="accrd-btn text-gray-500 dark:text-gray-400" data-accordion-target="#accrd-pack-${index}" aria-expanded="false" aria-controls="accrd-pack-${index}">(show included services)</button></p>`;


            const priceData = `<td><strong class="price">$${elem.inspectionServicePrice}</strong></td>`;
            const ctaButtonAddName = `ctaButtonPack-${index}`;
            const ctaRemoveButton = `<td><button type="button" id="${ctaButtonAddName}" class="table-danger-btn package__false"><span style="pointer-events:none">Remove</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-danger"></use>
            </svg>
            </button></td>`;
            const ctaAddButton = `<td><button type="button" id="${ctaButtonAddName}" class="table-primary-btn package__true"><span style="pointer-events:none">Add</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-white"></use>
            </svg>
            </button></td>`;

            let cta:any;
            if(this.clickTracker.includes(elem.inspectionServiceId)){
                cta = ctaRemoveButton;
            }
            else{
                cta = ctaAddButton;
            }
            allTableValues.insertAdjacentHTML( 'beforeend', `<tr><td><div class="row-wrap">${text}${packageContent}</div></td>${priceData}${cta}</tr>` );
        });
    }

    //Populate the DOM with services
    private setInspectionServices():void{
        if(this.isPageInEditMode && (this.editData.HmsAvailableServices).length>0){
            this.editData.HmsAvailableServices.forEach((bundle:any)=>{
                this.clickTracker.push(bundle.HmsServiceId);
                this.totalPrice+=bundle.HmsPrice;
                this.updateTotalCost();
            })
        }
        const allTableValues = this.tableElements.querySelector("tbody");
        allTableValues.insertAdjacentHTML( 'beforeend', `<tr><td colspan="3" class="table-header">Inspection Services</td></tr>` );

        this.inspectionServices.forEach((elem:any, index: number)=>{
            let text = '';
            let packageContent = '';
            if(elem.inspectionServiceDescription){
                packageContent = `<div class="the-details hidden" id="accrd-serv-${index}"><p class="inspection-sub-text">${elem.inspectionServiceDescription}</p></div>`
            }
            if(packageContent==="" || /<\/?[a-z][\s\S]*>/i.test(elem.inspectionServiceDescription))
                text = `<p class="inspection-text-main">${elem.inspectionServiceName} <button type="button" class="accrd-btn bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" data-accordion-target="#accrd-serv-${index}" aria-expanded="false" aria-controls="accrd-serv-${index}" style="display:none">(show included services)</button></p>`;
            else
                text = `<p class="inspection-text-main">${elem.inspectionServiceName} <button type="button" class="accrd-btn bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" data-accordion-target="#accrd-serv-${index}" aria-expanded="false" aria-controls="accrd-serv-${index}">(show included services)</button></p>`;
            
            const priceData = `<td><strong class="price">$${elem.inspectionServicePrice}</strong></td>`;

            const ctaButtonAddName = `ctaButtonServ-${index}`;
            
            const ctaRemoveButton = `<td><button type="button" id="${ctaButtonAddName}" class="table-danger-btn service__false"><span style="pointer-events:none">Remove</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-danger"></use>
            </svg>
            </button></td>`;

            const ctaAddButton = `<td><button type="button" id="${ctaButtonAddName}" class="table-primary-btn service__true"><span style="pointer-events:none">Add</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-white"></use>
            </svg>
            </button></td>`;

            let cta:any;
            if(elem.buttonEnabled === undefined){
                elem.buttonEnabled = true
            }
            if(!elem.buttonEnabled){
                cta = `<td><button type="button" id="${ctaButtonAddName}" class="table-disable-btn service__disabled" title="This service has been included in your Package"><span style="pointer-events:none">Included</span>
                <svg class="close-icon" style="pointer-events:none">
                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-danger"></use>
                </svg>
                </button></td>`
            }
            else if(this.clickTracker.includes(elem.inspectionServiceId)){
                cta = ctaRemoveButton;
            }
            else{
                cta = ctaAddButton;
            }
            allTableValues.insertAdjacentHTML( 'beforeend', `<tr><td><div class="row-wrap">${text}${packageContent}</div></td>${priceData}${cta}</tr>` );
        });
    }

    private checkforItemInServices() {
        return this.inspectionServices.map((obj:any) => {
            const match = Object.values(obj).some(val => this.taken_arr.includes(val));
            return {...obj, buttonEnabled: !match};
        });
    }

    //This checks for the activity being performed on the package(add/remove) and performs the appropriate push/pop to our filter array.
    clickEventForPackages(elem:any, beingAdded:boolean, colID:any){
        const colItem = document.getElementById(colID) as HTMLElement;
        const closestTD = colItem.closest('td') as HTMLElement;
        if(colItem && closestTD){
            closestTD.innerHTML ="";
        }
        if(beingAdded){
            this.clickTracker.push(elem.inspectionServiceId);
            this.totalPrice+=elem.inspectionServicePrice;
            closestTD.innerHTML = `<button type="button" id="${colID}" class="table-danger-btn package__false"><span style="pointer-events:none">Remove</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-danger"></use>
            </svg>
            </button>`;
        }else{
            this.totalPrice-=elem.inspectionServicePrice;
            let index = this.clickTracker.indexOf(elem.inspectionServiceId);
            if (index !== -1) {
                this.clickTracker.splice(index, 1);
            } 
            closestTD.innerHTML = `<button type="button" id="${colID}" class="table-primary-btn package__true"><span style="pointer-events:none">Add</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-white"></use>
            </svg>
            </button>`
        }
        if(elem.inspectionRelatedServices.length!==0){
            this.totalPrice-=elem.inspectionServicePrice;
            (beingAdded)?this.pushId(elem.inspectionRelatedServices):this.popId(elem.inspectionRelatedServices);
            closestTD.innerHTML = `<button type="button" id="${colID}" class="table-primary-btn package__true"><span style="pointer-events:none">Add</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-white"></use>
            </svg>
            </button>`
        }
        //rerender the values inside services section
        this.checkforItemInServices();
        this.updateTotalCost();
    }

    clickEventForServices(elem:any, beingAdded:boolean, colID:any){
        const colItem = document.getElementById(colID) as HTMLElement;
        const closestTD = colItem.closest('td') as HTMLElement;
        if(colItem && closestTD){
            closestTD.innerHTML ="";
        }
        if(beingAdded){
            this.totalPrice+=elem.inspectionServicePrice;
            this.clickTracker.push(elem.inspectionServiceId);
            closestTD.innerHTML = `<button type="button" id="${colID}" class="table-danger-btn service__false"><span style="pointer-events:none">Remove</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-danger"></use>
            </svg>
            </button>`;
        }
        else{
            this.totalPrice-=elem.inspectionServicePrice;
            let index = this.clickTracker.indexOf(elem.inspectionServiceId);
            if (index !== -1) {
                this.clickTracker.splice(index, 1);
            } 
            closestTD.innerHTML = `<button type="button" id="${colID}" class="table-primary-btn service__true"><span style="pointer-events:none">Add</span>
            <svg class="close-icon" style="pointer-events:none">
            <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#x-mark-white"></use>
            </svg>
            </button>`;
        }
        this.updateTotalCost();
    }

    private pushId(arr:any){
        arr.forEach((obj:any)=>{
            this.taken_arr.push(obj.inspectionRelatedServiceId)
        })
    }

    private popId(array:any) {
        this.taken_arr = this.taken_arr.filter((item:any) => !array.includes(item));
    }

    private updateTotalCost(){
        const totalCost = this.tableElements.querySelector(".table-total strong");
        if(totalCost)
            totalCost.textContent = (this.totalPrice===null || isNaN(this.totalPrice))? `$0.00` :`$${this.totalPrice}`;
    }

    private handleBackButton(){
        hmsProgressBarUpdate('50%', 2);
        const serviceSection = document.getElementById("property-step") as HTMLElement;
        const previousSections = document.querySelectorAll(".section-main>.contact-us-section");
        if(serviceSection && previousSections){
            previousSections.forEach((elm)=>{
                elm.classList.remove("form-step-active");
            })
            serviceSection.classList.add("form-step-active");
        }
        sessionStorage.removeItem("hms_s5");
        window.scrollTo(0,0);
        
    }

    private init() {
        startLoader();
        let HMSsessionData = sessionStorage.getItem("hms_booking");
        if(HMSsessionData){
            const bookingData = JSON.parse(HMSsessionData);
            const payload = `{
                "Zipcode": ${bookingData.zipcode},
                "WebLocationId": ${bookingData.franchiseWebLocationId},
                "AppointmentType": 2,
                "PropertyType": "Residential",
                "PropertyInfo": {
                    "YearBuilt": ${bookingData.HmsRoleInfo.HmsPropertyDetail.BuiltYear},
                    "SquareFeet": ${bookingData.HmsRoleInfo.HmsPropertyDetail.SquareFeet},
                    "FoundationType": "${bookingData.HmsRoleInfo.HmsPropertyDetail.TypeOfFoundation}",
                    "NumberOfBedrooms": "${bookingData.HmsRoleInfo.HmsPropertyDetail.NumberOfBedrooms}",
                    "NumberOfBathrooms": "${bookingData.HmsRoleInfo.HmsPropertyDetail.NumberOfBathrooms}",
                    "NumberOfKitchens": "${bookingData.HmsRoleInfo.HmsPropertyDetail.NumberOfKitchens}",
                    "HasPool": ${bookingData.HmsRoleInfo.HmsPropertyDetail.IsPoolAvailable},
                    "AreUtilitiesOn": ${bookingData.HmsRoleInfo.HmsPropertyDetail.UtilitiesOn}
                }
            }`;

            this.getServices(payload);
        }
        // this.progressBarUpdater();
        const errorMessage = document.querySelector("#service-step .service-alert") as HTMLElement;
        if(errorMessage){
            errorMessage.style.display = "none";
            const submitButton = document.querySelector("#service-step .btn-next");
            if(submitButton)
                submitButton.addEventListener('click', this.manageSubmit.bind(this));
        }

        const goBackButton = document.querySelector("#service-step .btn-cancel");
        if(goBackButton)
            goBackButton.addEventListener('click', this.handleBackButton);

        const sessionData = sessionStorage.getItem('hms_booking');
        if(sessionData){
            this.hmsBookingObject = JSON.parse(sessionData);
            const setTime = document.querySelector("#service-step .step-time") as HTMLElement
            if(setTime){
                setTime.innerHTML = `Selected Start Time: <span>${this.setTimeAndDate(this.hmsBookingObject.calendarPaylod.userSelectDate)}</span>`; 
            }
        }
    }

    checkIfEditMode(){
        const sessionData = sessionStorage.getItem("hms_booking");
        if(sessionData){
            const hms_booking = JSON.parse(sessionData);
            if(hms_booking?.HmsRoleInfo?.HmsSelectedServiceDetail){
                this.editData = hms_booking?.HmsRoleInfo?.HmsSelectedServiceDetail;
                this.isPageInEditMode = true;
            }
        }
    }

    progressBarUpdater(){
        const progressBarLine = document.querySelector(".progressbar #progress") as HTMLElement;
        const progresBarItems = document.querySelectorAll(".progressbar .progress-step");
        if(progresBarItems && progressBarLine){
            progressBarLine.style.width = "75%";
            progresBarItems.forEach((el, index)=>{
                if(index<3)
                    el.classList.add("prograssbar-length", "progress-step-confirmed");
                else if(index===3)
                    el.classList.add("prograssbar-length", "progress-step-active");
            })
            
        }
    }

    accordianSection(targetId:any){
        const accordionButtons = document.querySelectorAll('.accrd-btn');
        const target = document.querySelector(targetId);

        if(target){
            const parentWrapper = target.closest(".row-wrap") as HTMLElement;
            const clickedButton = parentWrapper.querySelector("button") as HTMLButtonElement;
            // Toggle the visibility of the accordion content for the clicked button only
            target.classList.toggle('hidden');

            // Toggle the aria-expanded attribute for the clicked button only
            const isExpanded = target.classList.contains('hidden') ? 'false' : 'true';
            clickedButton.setAttribute('aria-expanded', isExpanded);

            // Change the text of the button based on visibility for the clicked button only
            if (isExpanded === 'true') {
                clickedButton.textContent = '(hide included services)';
            } else {
                clickedButton.textContent = '(show included services)';
            }

            // Close other accordions when one is opened
            accordionButtons.forEach(otherButton => {
                if (otherButton !== clickedButton) {
                    const otherTargetId = otherButton.getAttribute('data-accordion-target');
                    let otherTarget:HTMLElement;
                    if(otherTargetId){
                        otherTarget = document.querySelector(otherTargetId) as HTMLElement;
                        if(otherTarget){
                            otherTarget.classList.add('hidden');
                            otherButton.setAttribute('aria-expanded', 'false');
                            otherButton.textContent = '(show included services)';
                        }
                    }
                        
                    
                }
            });
        }
        
    
    }

    toggleErrorDiv(){
        
        const errorMessage = document.querySelector("#service-step .service-alert") as HTMLElement;
        if(errorMessage)
            errorMessage.style.display =(this.clickTracker.length===0)?"flex":"none";
    }

    manageSubmit(event:any){
        event.preventDefault();
        const errorMessage = document.querySelector("#service-step .service-alert") as HTMLElement;
        if(errorMessage){
            if(this.clickTracker.length===0){
                errorMessage.style.display = "flex";
            }
            else{
                errorMessage.style.display = "none";
                this.packageAndServiceSummary();
                sessionStorage.removeItem("hms_s5");
            }
        }
    }

    packageAndServiceSummary(){

        
        let packages =  JSON.parse(JSON.stringify(this.inspectionPackages.filter((obj:any) => this.clickTracker.includes(obj.inspectionServiceId))));
        let services =  JSON.parse(JSON.stringify(this.inspectionServices.filter((obj:any) => this.clickTracker.includes(obj.inspectionServiceId))));

        packages.forEach((obj:any)=>{
            delete obj.$id;
            delete obj.buttonEnabled;

            obj.hmsBundleServiceId = obj.inspectionServiceId;
            delete obj.inspectionServiceId;

            obj.hmsBundleServiceName = obj.inspectionServiceName;
            delete obj.inspectionServiceName;
            
            obj.hmsBundleServiceDescription = obj.inspectionServiceDescription;
            delete obj.inspectionServiceDescription;

            obj.hmsBundleServiceProductNumber = obj.inspectionServiceProductNumber;
            delete obj.inspectionServiceProductNumber;

            obj.hmsBundleServiceCreatedBy = obj.inspectionServiceCreatedBy;
            delete obj.inspectionServiceCreatedBy;

            obj.hmsBundleServicePrice = obj.inspectionServicePrice;
            delete obj.inspectionServicePrice;

            obj.hmsBundleItemTax = obj.inspectionServiceItemTax;
            delete obj.inspectionServiceItemTax;
        });

        services.forEach((obj:any)=>{
            delete obj.$id;
            delete obj.buttonEnabled;

            obj.HmsServiceId = obj.inspectionServiceId;
            delete obj.inspectionServiceId;

            obj.HmsServiceName = obj.inspectionServiceName;
            delete obj.inspectionServiceName;
            
            obj.HmsServiceDescription = obj.inspectionServiceDescription;
            delete obj.inspectionServiceDescription;

            obj.HmsServiceProductNumber = obj.inspectionServiceProductNumber;
            delete obj.inspectionServiceProductNumber;

            obj.HmsServiceCreatedBy = obj.inspectionServiceCreatedBy;
            delete obj.inspectionServiceCreatedBy;

            obj.HmsPrice = obj.inspectionServicePrice;
            delete obj.inspectionServicePrice;

            obj.HmsItemTax = obj.inspectionServiceItemTax;
            delete obj.inspectionServiceItemTax;
        });

        const template = 
        {
    
            "HmsRoleInfo": {
                "HmsSelectedServiceDetail": {
                    "HmsServiceBundles": [...packages],
                    "HmsAvailableServices": [...services],
                    "HmsServiceSubTotal": this.totalPrice,
                    "HmsServiceTotal": 0
                },
                "HmsTaxDetail": {
                    "RegionalTaxes": [
                        {
                            "taxAmount": 0
                        }
                    ]
                }
            }
        }
        if(sessionStorage.getItem('hms_booking')){
            const HmsSelectedServiceDetail = template.HmsRoleInfo.HmsSelectedServiceDetail;
            this.hmsBookingObject.HmsRoleInfo.HmsSelectedServiceDetail = HmsSelectedServiceDetail;
            this.hmsBookingObject.HmsRoleInfo.HmsTaxDetail = template.HmsRoleInfo.HmsTaxDetail;
        }
        else{
            this.hmsBookingObject = template;
        }
        sessionStorage.setItem("hms_booking", JSON.stringify(this.hmsBookingObject));

        new InspectionSummary();
       
    }

}