import moment from "moment";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { startLoader, stopLoader } from "../util/loader";
import { getBrandDetailsFromBrandJson } from "../util/share";
import { blogPageLocationURL } from "../util/blogPageLocation";

export class BlogList {

    private data: any[];
    public itemsPerPage: number;
    public currentPage: number;
    public brandDetail:any;

    constructor() {
        this.itemsPerPage = 10;
        this.currentPage = 1;
        this.data = [];

        document.addEventListener('DOMContentLoaded', async() => {
       
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        this.brandDetail = brandData;
        const blogContainer = document?.querySelector('.blog-list');
            if (blogContainer) {

                //Data is fetched only if the fte is false
                if(!brandData?.enable_fte){
                    this.getData(null);
                }
            }

        });

        const searchForm = document.querySelector('.blog-search-form');
        searchForm?.addEventListener('submit', (e) => {
            e.preventDefault();
            this.updateURLParams();
        });

        const selectElement = document.getElementById("filter-blog") as HTMLInputElement;
        selectElement?.addEventListener("change", (event: any) => {
            event.preventDefault();
            this.updateURLParams();
        });
        const copyBlogLink = document.getElementById("a_share_link_blogs") as HTMLInputElement;
        copyBlogLink?.addEventListener("click", (event:any) => {
            event.preventDefault();
           let pageUrl = decodeURIComponent(window.location.href);
           let urlImg = pageUrl;
           this.copyToClipboard(urlImg);
       })
    }

    private copyToClipboard(url: string)
    {
        const listener = (e:any) =>
        {
            e.clipboardData.setData('text/plain', url);
            e.preventDefault();
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        alert(`${url}\n Copied to Clipboard`);
        document.removeEventListener('copy', listener);
    }
    private updateURLParams() {

        if(!this.brandDetail?.enable_fte) {   // If brandDetail is not defined or enable_fte is false

            const currentPage = '1';
            const searchTerm = (document.getElementById('search-blog') as HTMLInputElement)?.value || '';
            const selectedCategory = (document.getElementById("filter-blog") as HTMLInputElement)?.value || '';

            const updatedURL = new URL(window.location.href);
            updatedURL.searchParams.set('page', currentPage);
            updatedURL.searchParams.set('search', searchTerm);
            updatedURL.searchParams.set('categories', selectedCategory != '--Select--' ? selectedCategory : '');
            window.location.href = updatedURL.href;

        }else {
            this.updateBlogURLParams();
        }
    }

    private async updateBlogURLParams() {

        const searchTerm = (document.getElementById('search-blog') as HTMLInputElement)?.value?.trim() || '';
        const updatedURL = new URL(window.location.href);
        let searchStrWithHyphens =  searchTerm.replace(/\s+/g, "-");
        localStorage.setItem('blog_search', searchStrWithHyphens);
            if( this.brandDetail){
                if(this.brandDetail?.includeTrailingSlash){
                    searchStrWithHyphens = searchStrWithHyphens+'/'
                }
            }
        const dbaName = localStorage.getItem('dbaName');
        const brandDetails = localStorage.getItem("brandDetails");
        let localBlogUrlName;
        try{
            localBlogUrlName = (brandDetails)?JSON.parse(brandDetails)?.brand_local_blog_url_name :"blog";
        }
        catch{
            localBlogUrlName = "blog";
        }
        if(dbaName){
            const urlPath = blogPageLocationURL(dbaName);
            if(urlPath !== "error")
                updatedURL.pathname = `/${urlPath}/${localBlogUrlName}/search/` + searchStrWithHyphens;
            else
                updatedURL.pathname = `/${dbaName}/${localBlogUrlName}/search/` + searchStrWithHyphens;
        }else {
            updatedURL.pathname = `/${localBlogUrlName}/search/` + searchStrWithHyphens;
        }

        
        window.location.href = updatedURL.href;
    }

    private getData(e: any) {
        if (e) e.preventDefault();

        const urlParams = new URLSearchParams(window.location.search);
        const getPage = urlParams.get('page');
        const getSearch = urlParams.get('search');
        const getCategories = urlParams.get('categories');

        if (getPage) {
            this.currentPage = parseInt(getPage, 10);
        }

        const brandName = document.getElementById('brandName') as HTMLInputElement;
        const dbaName = document.getElementById('dbaName') as HTMLInputElement;


        const request = {
            method: 'GET',
            url: `https://searchg2.crownpeak.net/${process.env.Search_G2_URL}/select?q=custom_s_brand:${brandName?.value}&rows=1000&sort=custom_dt_created%20desc&echoParams=explicit&fl=*,score&defType=edismax&wt=json&fq=custom_s_local_dba:${dbaName?.value ? dbaName.value : 'none'}`
        };

        startLoader()

        apiRequest(request)
            .then((result: any) => {
                const { docs } = result?.response;
                const { currentPageItems, filterArray } = this.pagination(docs, this.currentPage, this.itemsPerPage, getSearch, getCategories);
                const lists = currentPageItems.map((item: any) => this.createBlogList(item)).join('');
                const blogContainer = document.querySelector('.blog-list');
                if (blogContainer) {
                    blogContainer.insertAdjacentHTML('afterbegin', lists);
                }

                this.data = filterArray;
                const paginationWrapperblog: any = document.getElementById("search-blog");
                if (document.querySelector(".pagination-wrapper") && paginationWrapperblog)
                    this.renderPageNumbers(getSearch, getCategories);

                const isQuery = (getCategories || getSearch);
                const len = filterArray?.length;
                const inputSearch = document.getElementById('search-blog') as HTMLInputElement;
                const inputSelect = document.getElementById('filter-blog') as HTMLInputElement;

                if (getSearch && inputSearch) {
                    inputSearch.value = getSearch;
                }
                if (inputSelect && getCategories) {
                    inputSelect.value = getCategories;
                }

                const title = isQuery
                    ? `<h2 class="blog-list-heading">Showing ${len} Results for ${getSearch ? `<span>"${getSearch}"</span>` : ''} ${getCategories && getSearch ? 'and' : ''} ${getCategories ? `<span>${getCategories}</span>` : ''}</h2>`
                    : '<h2 class="blog-list-heading">All Blog</h2>';
                blogContainer?.insertAdjacentHTML('afterbegin', title);

                stopLoader()
            })
            .catch((err) => {
                stopLoader()
                console.error(err);
            });
    }

    private pagination(array: any, page: any, itemsPerPage: any, searchKey: any, categoryKey: any) {
        let filterArray = array;

        if (searchKey) {
            filterArray = filterArray.filter((item: any) => String(item.custom_s_template_lable).toLocaleLowerCase().includes(String(searchKey).toLocaleLowerCase()) || String(item.custom_s_heading).toLocaleLowerCase().includes(String(searchKey).toLocaleLowerCase()) || String(item.custom_t_content_summary_Desc).toLocaleLowerCase().includes(String(searchKey).toLocaleLowerCase()));
        }

        if (categoryKey) {
            filterArray = filterArray.filter((item: any) => String(item.custom_s_category).toLocaleLowerCase() == String(categoryKey).toLocaleLowerCase());
        }

        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentPageItems = filterArray.slice(startIndex, endIndex);


        return { currentPageItems, filterArray };
    }
    
    private capitalizeEachWord(str: string): string {
        return str.replace(/\w\S*/g, function (txt: string) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
    private createBlogList(data: any): string {
        const { custom_dt_modified, custom_s_local_url, custom_s_heading, custom_s_template_lable, custom_t_content_summary_Desc, custom_s_article_summary_Image_url, custom_t_content_summary_Image_url_alt, custom_s_category, custom_dt_created } = data;
        const capitalizedCategory = this.capitalizeEachWord(custom_s_category);
        const imageContent = custom_s_article_summary_Image_url
            ? `<div class="media-wrapper">
                    <div class="media-container">
                        <a href="${custom_s_local_url}">
                            <picture>
                                <img src=${custom_s_article_summary_Image_url} alt="${custom_t_content_summary_Image_url_alt}" width="579" height="339">
                            </picture>
                        </a>
                    </div>
                </div>`
            : '';

        return `<article class="blog-card section-50-50">
                    <div class="image-content-warpper left-image-content">
                        <div class="text-wrapper">
                            <p class="blog-topic"><a href="${custom_s_local_url}">${capitalizedCategory}</a></p>
                            <div class="blog-heading"><a href="${custom_s_local_url}">${custom_s_heading}</a></div>
                            <span class="blog-date">${moment(custom_dt_created).format('MMM DD YYYY')}</span>
                            <p class="blog-text">${custom_t_content_summary_Desc}</p>
                            <a href="${custom_s_local_url}" class="blog-more-link" title="click to learn more">
                                Learn more
                                <svg>
                                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-primary "></use>
                                </svg>
                            </a>
                        </div>
                        ${imageContent}
                    </div>
                </article>`;
    }

    private renderPageNumbers(getSearch: string | null, getCategories: string | null): void {

        const paginationWrapper: any = document.querySelector(".pagination-wrapper");
        paginationWrapper.innerHTML = "";

        const totalPages = Math.ceil(this.data.length / this.itemsPerPage);
        const prevLink = `${window.location.pathname}?page=${this.currentPage > 1 ? this.currentPage - 1 : this.currentPage}&search=${getSearch || ''}&categories=${getCategories || ''}`;
        const nextLink = `${window.location.pathname}?page=${this.currentPage < totalPages ? this.currentPage + 1 : this.currentPage}&search=${getSearch || ''}&categories=${getCategories || ''}`;
        const lastLink = `${window.location.pathname}?page=${totalPages}&search=${getSearch || ''}&categories=${getCategories || ''}`;

        const previousHTML = `<li class="page-list">
                                <a href="${prevLink}" class="prev-btn">
                                    <span class="sr-only">Previous</span>
                                    <svg>
                                        <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-left-g500 "></use>
                                    </svg>
                                </a>
                            </li>`;

        const nextHTML = `<li class="page-list">
                            <a href="${nextLink}" class="next-btn">
                                <span class="sr-only">Next</span>
                                <svg>
                                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-g500 "></use>
                                </svg>
                            </a>
                        </li>`;
        
        const ellipsisHTML =  `<li class="page-list">
            <a href="#" class="page-number">...</a>
        </li>`;

        // if ((totalPages > 1) && (this.currentPage !== 1)) {
        //     paginationWrapper?.insertAdjacentHTML('afterbegin', previousHTML);
        // }
        if (totalPages <= 1) {
            paginationWrapper.classList.add("hidden");
        }
        
        if ((totalPages > 1)) {
            paginationWrapper?.insertAdjacentHTML('afterbegin', previousHTML);
        }

        const maxLoop = ((totalPages-this.currentPage) > 3 ? (this.currentPage+2) : (totalPages-1));
       for (let i = this.currentPage; i <= maxLoop; i++) {
            const pageItem = document.createElement("li");
            pageItem.className = "page-list";
            const pageLink = document.createElement("a");
            pageLink.href = `${window.location.pathname}?page=${i}&search=${getSearch || ''}&categories=${getCategories || ''}`;
            pageLink.className = "page-number";
            pageLink.textContent = i.toString();

            if (i === this.currentPage) {
                pageLink.classList.add("current-page");
                pageLink.setAttribute("aria-current", "page");
            }

            pageLink.addEventListener("click", () => {
                this.currentPage = i;
                this.updateURLParams();
            });

            pageItem.appendChild(pageLink);
            paginationWrapper.appendChild(pageItem);
        }

        if((this.currentPage == totalPages) && (totalPages > 3)) {
            let i = (totalPages - 2);
            let pageItem = document.createElement("li");
            pageItem.className = "page-list";
            let pageLink = document.createElement("a");
            pageLink.href = `${window.location.pathname}?page=${i}&search=${getSearch || ''}&categories=${getCategories || ''}`;
            pageLink.className = "page-number";
            pageLink.textContent = i.toString();
            pageItem.appendChild(pageLink);
            paginationWrapper.appendChild(pageItem);

            i = (totalPages - 1);
            pageItem = document.createElement("li");
            pageItem.className = "page-list";
            pageLink = document.createElement("a");
            pageLink.href = `${window.location.pathname}?page=${i}&search=${getSearch || ''}&categories=${getCategories || ''}`;
            pageLink.className = "page-number";
            pageLink.textContent = i.toString();
            pageItem.appendChild(pageLink);
            paginationWrapper.appendChild(pageItem);
        }

        if (totalPages > 3) {
            paginationWrapper?.insertAdjacentHTML('beforeend', ellipsisHTML);

            let className = "page-number";
            let attribute = "";

            if(this.currentPage == totalPages){
                className = "current-page page-number";
                attribute = `aria-current="page"`;
            }

            paginationWrapper?.insertAdjacentHTML('beforeend', `<li class="page-list"><a href="${lastLink}" ${attribute} class="${className}">${totalPages}</a></li>`);
        }

        // if ((totalPages > 1) && (this.currentPage !== totalPages)) {
        //     paginationWrapper?.insertAdjacentHTML('beforeend', nextHTML);
        // }

        if ((totalPages > 1)) {
            paginationWrapper?.insertAdjacentHTML('beforeend', nextHTML);
        }
    }
}

const blogList = new BlogList();
