import axios from "axios";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { StateHandler } from "../util/getStateFun";
import { startLoader, stopLoader } from "../util/loader";
import {getMessagesData} from "../util/siteConfig"
import { LocateAddressBumper } from "../header/locateAddressBumper";
import { insertAfterUrl } from "../util/share";
import { getBrandDetailsFromBrandJson, getCampaignName, storeBookingApiPayload } from "../util/share";

let messageData :any = {}
interface LeadSourceArray {
    find(arg0: (source: LeadSource) => boolean): LeadSource | undefined;
}  
interface LeadSource {
    syncGenericLeadSourceId: number;
    leadSourceName: string;
    isActive: boolean;
    referralTypeId: string;
    referralSourceId: any;
  }
export class ResidentialForm {
    private nameInput: HTMLInputElement;
    private lastNameInput: HTMLInputElement;
    private emailInput: HTMLInputElement;
    private phoneInput: HTMLInputElement;
    private addressInput: HTMLInputElement;
    private submitButton: any;
    private privateSuggestedArray = []


    constructor() {
        
        
        // Check for mobile form factor during page load
        //document.addEventListener('load', this.asideCardToMobile);

        document.addEventListener('DOMContentLoaded', () => {
            this.asideCardToMobile
        });

        window.addEventListener('resize', this.asideCardToMobile);
           const containerData = document.querySelector('.aside-card.tabs-mobile');
        this.asideCardToMobile();
        // if (window.innerWidth > 768) {
        //     containerData?.remove()
          
        // } else {
        
        // }

        const nameInputDesk = document.getElementById('req-name') as HTMLInputElement;
        const lastNameInputDesk = document.getElementById('req-lname') as HTMLInputElement;
        const nameInputMob = document.getElementById('req-name-mobile') as HTMLInputElement;
        const emailInputDesk = document.getElementById('req-email') as HTMLInputElement;
        const emailInputMob = document.getElementById('req-email-mobile') as HTMLInputElement;
        const phoneInputDesk = document.getElementById('req-phone') as HTMLInputElement;
        const phoneInputMob = document.getElementById('req-phone-mobile') as HTMLInputElement;
        const addressInputDesk = document.getElementById('req-address') as HTMLInputElement;
        const addressInputMob = document.getElementById('req-address-mobile') as HTMLInputElement;

        this.nameInput = nameInputDesk ? nameInputDesk : nameInputMob;
        this.lastNameInput = lastNameInputDesk;
        this.emailInput = emailInputDesk ? emailInputDesk: emailInputMob;
        this.phoneInput = phoneInputDesk ? phoneInputDesk : phoneInputMob;
        this.addressInput = addressInputDesk ? addressInputDesk : addressInputMob;

        // this.nameInput = document.getElementById('req-name') as HTMLInputElement;
        // this.emailInput = document.getElementById('req-email') as HTMLInputElement;
        // this.phoneInput = document.getElementById('req-phone') as HTMLInputElement;
        // this.addressInput = document.getElementById('req-address') as HTMLInputElement;
        this.submitButton = document.querySelectorAll('.residential-form .primary-btn');
        this.nameInput?.addEventListener('input', this.errorOnName.bind(this))
        this.emailInput?.addEventListener('input', this.emailError.bind(this))
        this.addressInput?.addEventListener('input', this.zipCodeError.bind(this))
        this.lastNameInput?.addEventListener('input', this.errorOnLastName.bind(this));


        this.submitButton?.forEach((btn: any) => {
            btn.addEventListener('click', this.setAutoSuggested.bind(this))
        })

        if (this.phoneInput) {
            this.phoneInput.addEventListener('input', this.handleMobileInput.bind(this));
        }
         
        const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value; 

        if(pageType && pageType.toLocaleLowerCase() !=="opus 2 location map"){
          getMessagesData()?.then(resp=>{
            messageData = resp
          });
        }


    }
    public scrollToThankYouSection() {
        if ( window.innerWidth <= 768) { // .hero-banner .form-container
            // document.querySelector(".hero-review")?.scrollIntoView();
            let targetScrollElement = document.querySelector('.hero-review') as HTMLElement;
            if (targetScrollElement) {
                let OffSetTop = targetScrollElement?.offsetTop;
                window.scrollTo({
                    top : OffSetTop,
                    behavior: 'smooth'
                })
            }
        }
    }
    
    public handleMobileInput(e: Event): void {
        e.preventDefault();
        const reg = REGEX.mobileNumberRegex;
        const val = this.phoneInput?.value;
        const x = val?.replace(/\D/g, '').match(reg);
        const formattedVal = x ? `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}` : '';
        if (this.phoneInput.value != '' && this.phoneInput?.value.length < 14 && formattedVal.length < 14) {
            const getErrMsg =   (document.getElementById(`req-phone-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`req-phone-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ||'Invalid phone format.'
               
            }
            else{
                const field: any = this.phoneInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid phone format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }

           
        }
        else{
         
            this.hideError.call(this, 'req-phone')
        }

        if (x) {
            this.phoneInput!.value = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? '-' + x[3] : ''}`;
        }
       
    }







    errorOnName(){
        if (this.nameInput.value !== '' && !REGEX.sendName.test(this.nameInput.value)) {
            const getErrMsg =   (document.getElementById(`req-name-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`req-name-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ||'Invalid name format.'  
               
            }
            else{
                const field: any = this.nameInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else{
            this.hideError('req-name')
        }

    }
    errorOnLastName(){
        if (this.lastNameInput.value !== '' && !REGEX.sendName.test(this.lastNameInput.value)) {
            const getErrMsg =   (document.getElementById(`req-lname-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`req-lname-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ?? 'Invalid last name format.'  
               
            }
            else{
                const field: any = this.lastNameInput;
                if (field?.parentNode) {
                    const errorMessage = getErrMsg ?? 'Invalid last name format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
        }
        else{
            this.hideError('req-lname')
        }

    }
    emailError(){
        if (this.emailInput.value != '' && !REGEX.sendEmail.test(this.emailInput.value)) {
            const getErrMsg =   (document.getElementById(`req-email-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`req-email-error-msg`);
           

            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg ||'Invalid email address, please try again.'
               
            }
            else{
                const field: any = this.emailInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid email address, please try again.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            }
    
           
        }
        else{
          
            this.hideError('req-email')
        }

    }

    zipCodeError(){
        if ((this.addressInput?.value != '' && !REGEX.sendZip.test(this.addressInput?.value.trim())) || this.addressInput?.value == '00000') {
            const getErrMsg =   (document.getElementById(`req-address-error-msg`) as HTMLElement)?.getAttribute('aria-describedby');
            const fieldError: any = document.getElementById(`req-address-error-msg`);
            if(fieldError){
                fieldError.classList.remove('hidden')
                fieldError.innerHTML = getErrMsg || 'Invalid zip code format.'
            }
            else{
                const field: any = this.addressInput;
                if (field && field.parentNode) {
                    const errorMessage = getErrMsg || 'Invalid zip code format.';
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${field.id}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    field.parentNode.appendChild(errorElement);
                }
            } 
        }
        else{
          
            this.hideError('req-address')
        }

    }

    hideError(id:any){
        const field: any = document.getElementById(id);
        const fieldError: any = document.getElementById(`${id}-error-msg`);

        fieldError?.classList.add('hidden')
        field?.classList.remove('invalid-field')

      

    }

   
    private getFormFields(): any {
        const residentialDesk = (document.querySelector('input[name="request-call"]') as HTMLInputElement)?.checked;
        const residentialMob = (document.querySelector('input[name="request-call"]') as HTMLInputElement)?.checked;
        const fields = {
            nameInput: this.nameInput?.value,
            lastNameInput: this.lastNameInput?.value,
            emailInput: this.emailInput?.value,
            phoneInput: this.phoneInput?.value,
            addressInput: this.addressInput?.value,
            isResidential: residentialDesk ? residentialDesk : residentialMob
        };
        return fields;
    }


    private validateInputs(): boolean {

        const formFields = this.getFormFields();
      //  document.querySelectorAll('.error-msg').forEach(e => e.remove());
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
        const errors: any = {};

        if (formFields.nameInput === '') {
            errors['req-name'] = '';
        } else if (!REGEX.sendName.test(formFields.nameInput)) {
            errors['req-name'] = 'Invalid  name format.';
        }
        if (formFields.lastNameInput === '') {
            errors['req-lname'] = '';
        } else if (!REGEX.sendName.test(formFields.lastNameInput)) {
            errors['req-lname'] = 'Invalid last name format.';
        }
        if (formFields.phoneInput === '') {
            errors['req-phone'] = '';
        }
        else if (formFields.phoneInput.length < 14) {
            errors['req-phone'] = 'Invalid phone format.';
        }


        if (formFields.addressInput === '') {
            errors['req-address'] = '';
 
        }
        else if (!REGEX.sendZip.test(formFields.addressInput?.trim()) || this.addressInput?.value == '00000') {
            errors['req-address'] = 'Invalid zip code format.';
        }

        if (formFields.emailInput === '') {
            errors['req-email'] = '';
        } else if (!REGEX.sendEmail.test(formFields.emailInput)) {
            errors['req-email'] = 'Invalid email address, please try again.';
        }

        Object.keys(errors).forEach((fieldId, index) => {
            const field: any = document.getElementById(fieldId);
            const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
            if (field && field.parentNode) {
                const errorMessage = errors[fieldId];
                const errorElement = document.createElement('span');
                errorElement.className = 'error-msg';
                errorElement.id = `${fieldId}-error-msg`;
                errorElement.textContent = errorMessage;
                field.classList.add("invalid-field");
                //field.parentNode.appendChild(errorElement);
                if (fieldError && fieldError.classList.contains('hidden'))
                fieldError.classList.remove('hidden')
            }
            if (index == 0 && field) {
                field.focus()
            }
        });
        return Object.keys(errors).length === 0;
    }
 
    private async handleSubmit(event: any, data: { franchiseId: any; franchiseWebLocationId: any; }, zipcodeInput:any): Promise<void> {
        event.preventDefault();
        const formFields = this.getFormFields();
        const campaignName = getCampaignName();
        const addressZip: any = zipcodeInput;
        var host = window.location.hostname.toLowerCase();
        let IsTest;
        if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
        {
          IsTest = true;
        }else {
          IsTest = false;
        }
        const formData: any = new FormData();
        const id: any = data.franchiseWebLocationId ?? undefined;
        const franchiseId: any = data.franchiseId ?? undefined;
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const defaultCustomerType = (document.getElementById('defaultCustomerType')as HTMLInputElement)?.value;
        const url = window.location.origin; 
        let vendorId : any;
        let vendorName: any;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode) as any;
        if(brandData){
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }

        const lastName = formFields.lastNameInput ?? '';
        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;

        const requestBody: any = {
            IsLocalized: true,
            FirstName: formFields.nameInput.split(' ')[0] ? formFields.nameInput.split(' ')[0] : '',
            LastName: formFields.nameInput.split(' ')[1] ? formFields.nameInput.split(' ')[1] : ' ',
            ZipCode: addressZip?.address?.postalCode,
            PostalCode: addressZip?.address?.postalCode,
            Email: formFields.emailInput,
            State: addressZip?.address?.state,
            City: addressZip?.address?.city,
            Phone: formFields.phoneInput,
            Address: "No address collected", //addressZip?.title,
            Address2: '',
            IsNewCustomer: "",
            Comments: '',
            Note: formFields.isResidential ? 'Residential' : 'Commercial',
            SignUpForUpdates: '',
            ConceptId: Number(conceptId),
            ConceptCode: conceptCode,
            LeadSource: "WEB",
            LeadOrigin: "WEB",
            PhoneType: true,
            IsLeadOnly: true,
            IsTest: IsTest,
            ScheduleID: "",
            CampaignName: campaignName,
            CallType: "WEB",
            ConceptCalledId: Number(conceptId),
            IsEstimate: false,
            EmailOptOut: emailOptOut,
            VendorId: vendorId,
            VendorName : vendorName,
            CurrentPage:url,
            CustomerType:formFields.isResidential ? 'Residential' : 'Commercial',   
            PreferredCommunicationType : 'Email,Phone',
            LeadSourceID:defaultLeadSourceId

        };
        if(defaultCustomerType){
            requestBody.CustomerType = defaultCustomerType.charAt(0).toUpperCase() + defaultCustomerType.slice(1);
            requestBody.Note =  defaultCustomerType.charAt(0).toUpperCase() + defaultCustomerType.slice(1);
        }
        if (id) {
            requestBody.FranchiseWebLocationId = id;
            requestBody.WebLocationId = id;
            requestBody.FranchiseId = franchiseId;
        }
        
        if(lastName){
            requestBody.LastName = lastName;
            requestBody.FirstName = formFields.nameInput ?? '';
          }


        try {
            if (conceptCode?.toLocaleLowerCase() === 'dvw' && franchiseId) {
                const dynamic_url = `${apiConfig.howToKnow}?apikey=${process.env.JS_API_KEY}&franchiseId=${franchiseId}`;
                const request = {
                    method: 'GET',
                    url: dynamic_url,
                };
        
                const leadSources: LeadSourceArray = await apiRequest(request);
                const othersLeadSource: LeadSource | undefined = leadSources.find(
                    (source: LeadSource) => source?.leadSourceName.toLocaleLowerCase() === "others"
                );
        
                if (othersLeadSource?.referralTypeId) {
                    requestBody.ReferralTypeId = othersLeadSource.referralTypeId;
                }
                if (othersLeadSource?.leadSourceName) {
                    requestBody.ReferralType = othersLeadSource.leadSourceName;
                }

            }
        } catch (error) {
            console.error('An error occurred in generic-lead-sources API in ResidentialForm.ts file:', error);
        }

        const request = {
            method: 'POST',
            url: apiConfig.BOOKING_API_URL,
            data: requestBody
        };

    //     const html = `<div class="tab-body">
    //     <h2 class="form-heading">${messageData?.lead_form_thanks_title}</h2>
    //     <p class="thank-text">${messageData?.lead_form_thanks_description}</p>
    // </div>`;

        const messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
        const formDiv = document.querySelector('.residential-form-container') as HTMLFormElement;
        const formDivHeader = document.querySelector('.form-heading-container') as HTMLFormElement;        
        const thankYouHeader = document.getElementById('thank-you-header') as HTMLFormElement;
        const thankYouDesc = document.getElementById('thank-you-description') as HTMLFormElement;
        const errorMsg = document.getElementById('error--header') as HTMLFormElement;
        const eroorDesc = document.getElementById('error-description') as HTMLFormElement;

        apiRequest(request)
            .then((response: any) => {
                stopLoader()
                storeBookingApiPayload(requestBody,response);
                formDiv.remove();
                formDivHeader.remove();
                thankYouHeader.classList.remove("hidden");
                thankYouDesc.classList.remove("hidden");
                this.scrollToThankYouSection();
                const closeModal = document.getElementById('close-local-modal');
                if(closeModal){
                    closeModal.click();
                }
                insertAfterUrl();
                // messageDiv?.insertAdjacentHTML('beforeend', html);

                this.createHiddenLocalWeblocalIdInput(id);
            })
            .catch((err) => {
                stopLoader();
            //     const html = `<div class="tab-body">
            //     <h2 class="form-heading">${messageData?.lead_form_error_title}</h2>
            //     <p class="thank-text">${messageData?.lead_form_error_description}</p>
            //    </div>`;
               errorMsg?.classList.remove("hidden");
               eroorDesc?.classList.remove("hidden");
                // const messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
                // const formDiv = document.querySelector('.tab-body') as HTMLFormElement;
                formDiv?.remove();
                formDivHeader?.remove();
                // messageDiv?.insertAdjacentHTML('beforeend', html);
            });

    }


    private setAutoSuggested = (e:any) => {
        e.preventDefault()
        if (this.validateInputs()) {
            const request = {
                method: 'GET',
                url: ` https://discover.search.hereapi.com/v1/discover?q=${(document.getElementById('req-address') as HTMLInputElement).value}&in=countryCode%3AUSA%2CCAN&at=0,0&apiKey=${process.env.JS_Heremap_API_KEY}`
            }
            apiRequest(request)
                .then((result: any) => {
                    if(result?.items.length > 0) {
                    this.privateSuggestedArray = result?.items

                    const zipcodeData = result?.items[0]
                    const updatedData = this.getAddress(zipcodeData);
                    this.getFranchiseWebLocationId(e, updatedData);

                    // const arr = result?.items.map((item: any) => item.title)
                    // this.setDatat(result?.items.map((item: any) => item.title))
                    } else {
                        this.handleErrorcase();
                    }
                })
                .catch((err) => {
                    this.handleErrorcase();
                });
        }
    }

    private getAddress(data: any){
        let newData = data;
        newData.title = "No address collected";
        return newData;
    }

    private handleErrorcase() {
        const errorMsg = document.getElementById('error--header') as HTMLFormElement;
        const eroorDesc = document.getElementById('error-description') as HTMLFormElement;
        errorMsg.classList.remove("hidden");
        eroorDesc.classList.remove("hidden");
        const html = `<div class="tab-body">
        <h2 class="form-heading">${messageData?.lead_form_noservice_title}</h2>
        <p class="thank-text">${messageData?.lead_form_noservice_description}</p>
        </div>`;
        const messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
        const formDiv = document.querySelector('.tab-body') as HTMLFormElement;
        formDiv?.remove()
        messageDiv?.insertAdjacentHTML('beforeend', html);
        this.scrollToThankYouSection();
        stopLoader();
    }
    // private isMobileFormFactor() : boolean {
    //     return window.innerWidth <= 768;
    //   }
   
      private asideCardToMobile() {
        const asideCard = document.querySelector('.aside-card.tabs') as HTMLElement;
        const asideCardMobile = document.querySelector('.aside-card.tabs-mobile') as HTMLElement;
        
        try {

            if( asideCard && asideCardMobile) {
                if (window.innerWidth <= 768) {
                    while (asideCard.firstChild) {
                        asideCardMobile.appendChild(asideCard.firstChild);
                      } 
                } else{
                    while (asideCardMobile.firstChild) {
                        asideCard.appendChild(asideCardMobile.firstChild);
                      } 
                }
            }

        } catch (error) {
            console.log("Error in Residential form: ",error);
        }
        
      }
  

    private setDatat = (suggestions: any) => {
        try {

            // Get the input element
            const inputElement: any = document.getElementById("req-address") as HTMLInputElement;

            // Add input event listener for auto-suggestions
            const inputValue = inputElement.value.toLowerCase();
            const suggestionContainer: any = document.createElement("ul") as HTMLElement
            suggestionContainer.className = "suggestion-container";

            // Clear previous suggestions
            while (inputElement.nextSibling && inputElement.nextSibling.className === "suggestion-container") {
                inputElement.parentNode.removeChild(inputElement.nextSibling);
            }

            if (inputValue) {
                const matchingSuggestions = suggestions.filter((suggestion: any) => {
                    return suggestion.toLowerCase().includes(inputValue)
                }
                );



                matchingSuggestions.forEach((matchingSuggestion: any) => {
                    const suggestionItem = document.createElement("li");
                    suggestionItem.className = "suggestion-item";
                    suggestionItem.textContent = matchingSuggestion;

                    suggestionItem.addEventListener("click", () => {
                        inputElement.value = matchingSuggestion;
                        suggestionContainer.parentNode.removeChild(suggestionContainer);
                    });

                    suggestionContainer.appendChild(suggestionItem);
                });

                if (matchingSuggestions.length > 0) {
                    inputElement.parentNode.insertBefore(suggestionContainer, inputElement.nextSibling);
                }
            }

        } catch (error) {



        }

    }




    private getFranchiseWebLocationId(event: Event, zipcodeInput:any) {
        event.preventDefault();
        const formFields = this.getFormFields();
        const zipCode: any = zipcodeInput;
        const errorMsg = document.getElementById('error--header') as HTMLFormElement;
        const eroorDesc = document.getElementById('error-description') as HTMLFormElement;
        if (this.validateInputs()) {
            startLoader()
            // const formFields = this.getFormFields();
            // const request = {
            //     method: 'GET',
            //     url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(zipCode?.address?.postalCode))
            // };
            const apiUrl = apiConfig.LocateLocationApiWithRoundRobinFalse.replace("sAddressParamValue", encodeURIComponent(zipCode?.address?.postalCode));
            axios.get(apiUrl, {
                headers: {
                  'Cache-Control': 'max-age=0',
                },
            })
            .then( async(response: any) => {
                const result = response.data;
                const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

             
                if (result.length > 0) {

                    const obj = {
                        'franchiseId': result[0].franchiseId,
                        'franchiseWebLocationId': result[0].franchiseWebLocationId

                    }

                    this.handleSubmit(event, obj, zipcodeInput);
                }
                else {

                    if(!brandData?.lead_contactapi){
                        errorMsg.classList.remove("hidden");
                        eroorDesc.classList.remove("hidden");
                        const formDivResidential = document.querySelector('.residential-form-container') as HTMLFormElement;
                        const formDivHeader = document.querySelector('.form-heading-container') as HTMLFormElement; 
                        formDivResidential?.remove();
                        formDivHeader?.remove();
                    const html = `<div class="tab-body">
                    <h2 class="form-heading">${messageData?.lead_form_noservice_title}</h2>
                    <p class="thank-text">${messageData?.lead_form_noservice_description}</p>
                </div>`;
                    const messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
                    const formDiv = document.querySelector('.tab-body') as HTMLFormElement;
                    formDiv?.remove()
                    messageDiv?.insertAdjacentHTML('beforeend', html);
                    this.scrollToThankYouSection();

                    }else{
                        this.contactUsApiCall(zipcodeInput);
                    }
                    stopLoader()

                }
            })
            .catch(async (error) => {
                    stopLoader();
                    const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
                    const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

                        const duplicate = error?.response?.data;
                        const step1 = document.querySelector('.step-1') as HTMLElement;
                        if (step1) {
                            step1.classList.add('hidden');
                        }
                        if (duplicate?.Message === 'Full address is needed to narrow down results'){
                            const stateHandler = new StateHandler();
                            stateHandler.getStateFun();
                            const findLocalBtn = document.querySelector(".find-local") as HTMLElement;
                            findLocalBtn?.click();
                            const step2 = document.querySelector('.step-2') as HTMLElement;
                            if (step2) {
                                step2.classList.remove('hidden');
                                step2.classList.add('block');
                                
                                const closeLocalModal = document.querySelector('.close-modal-btn');
                                closeLocalModal?.addEventListener('click', function handleClick(event) {
                                    step2.classList.add("hidden");
                                    step1.classList.remove("hidden");
                                });
                            }
                            const modalZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
                            
                            modalZipCode.value = formFields.addressInput;
                            sessionStorage.setItem('shortFormAddressBumper','true');
                            const locateAddressBumper = new LocateAddressBumper();
                        }else{
                          if(!brandData?.lead_contactapi){
                                const html = `<div class="tab-body">
                                <h2 class="form-heading">${messageData?.lead_form_error_title}</h2>
                                <p class="thank-text">${messageData?.lead_form_error_description}</p>
                                </div>`;
                                const messageDiv = document.querySelector('.tabs-wrapper') as HTMLFormElement;
                                const formDiv = document.querySelector('.tab-body') as HTMLFormElement;
                                formDiv.remove()
                                messageDiv?.insertAdjacentHTML('beforeend', html);
                            }else {   
                                this.contactUsApiCall(zipcodeInput);
                            }
                        }
                        
                });
        }
    }

    async contactUsApiCall(zipcodeInput:any){
        
        const formFields = this.getFormFields();
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const zipCodeText = (document.querySelector('label[for="req-address"]')as HTMLElement)?.innerText;
        const lastName = formFields.lastNameInput ?? '';
        const addressZip: any = zipcodeInput;
        const formDiv = document.querySelector('.residential-form-container') as HTMLFormElement;
        const formDivHeader = document.querySelector('.form-heading-container') as HTMLFormElement;        
        const thankYouHeader = document.getElementById('thank-you-header') as HTMLFormElement;
        const thankYouDesc = document.getElementById('thank-you-description') as HTMLFormElement;
        const errorMsg = document.getElementById('error--header') as HTMLFormElement;
        const eroorDesc = document.getElementById('error-description') as HTMLFormElement;

        let vendorId;
        let vendorName;
        
        try {

            const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);

            if(brandData.lead_contactapi){
                
                if(brandData){
                    vendorId = brandData.vendorId;
                    vendorName = brandData.vendorName;
                }
                
                const requestBody = {
                    "FirstName": formFields.nameInput.split(' ')[0] ? formFields.nameInput.split(' ')[0] : '',
                    "LastName": formFields.nameInput.split(' ')[1] ? formFields.nameInput.split(' ')[1] : ' ',
                    "ZipCode": addressZip?.address?.postalCode,
                    "Email": formFields.emailInput,
                    "State": addressZip?.address?.state,
                    "City": addressZip?.address?.city,
                    "Phone": formFields.phoneInput,
                    "Address": addressZip?.address?.label, 
                    "address2": '',
                    "country": addressZip?.address?.country,
                    "Comments": zipCodeText,
                    "SignUpForUpdates": false,
                    "IsLocalized": false,
                    "IsNewCustomer": null, 
                    "IsTest": true,
                    "ConceptId": conceptId?.value,
                    "VendorId":vendorId,
                    "VendorName": vendorName
                };
                if(lastName){
                    requestBody.LastName = lastName;
                    requestBody.FirstName = formFields.nameInput ?? '';
                  }
            
                const request = {
                    method: 'POST',
                    url: apiConfig.CONTACT_US_SEND,
                    data: requestBody
                };

                await apiRequest(request);
                formDiv?.remove();
                formDivHeader?.remove();
                if(thankYouHeader){
                    thankYouHeader.classList.remove("hidden");
                    thankYouDesc.classList.remove("hidden");
                }
                this.scrollToThankYouSection();
            }
        }
        catch (error) {
            console.error(`Error in Residential.ts file in the contactUsApiCall function. Error message: ${error}`);
            errorMsg?.classList.remove("hidden");
            eroorDesc?.classList.remove("hidden");
            formDiv.remove();
            formDivHeader.remove();
        }        
    }

    public createHiddenLocalWeblocalIdInput(id:any):void{

        const localWebLocationIdInput: HTMLInputElement = document.createElement('input');
        localWebLocationIdInput.type = 'hidden';
        localWebLocationIdInput.value = id;
        localWebLocationIdInput.id = 'local_weblocationId';
        document.body.appendChild(localWebLocationIdInput);

    }

}

const residentialForm = new ResidentialForm();
