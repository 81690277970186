export function getBrandJSON():string {
    const assetPathElement = document.getElementById("assetPath")as HTMLInputElement;
    if(assetPathElement){
        const assetPathValue = assetPathElement.value;
        if(assetPathValue.includes("/CA/"))
            return '_assets-global/configs/brands-ca.json';
        else if(assetPathValue.includes("/US/"))
            return '_assets-global/configs/brands.json';
    }
    return "Error validating Location"
}