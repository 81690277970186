import { REGEX } from "../constants/regex";
import { ValidateZipPostalCode } from "../util/ValidateZipPostalCode";
import { GetLocateLocation } from "./GetLocateLocation";
import { LocateAddressBumper } from './locateAddressBumper';

export class SearchLocalSite{
  private headerBtnZipcode: HTMLInputElement | any;
  private localSearchInput: HTMLInputElement | any;
  constructor() {
    this.headerBtnZipcode = document.querySelector('.find-local-modal form .form-cta .primary-btn');
    if (this.headerBtnZipcode) {
        this.headerBtnZipcode.addEventListener("click", this.handleButtonClick.bind(this));
    } 
    this.localSearchInput = document.getElementById('local-search-modal') as HTMLInputElement;
    let errorMessageSpan = document.createElement('span');
    errorMessageSpan.id = 'modal-error_message';
    errorMessageSpan.className = 'error-msg block';
    errorMessageSpan.style.display = 'none';

    if (this.localSearchInput) {
        this.localSearchInput.addEventListener('input', () => {
    
            const reg = REGEX.sendZip;
            const val:any = this.localSearchInput?.value;
            if (this.localSearchInput.value != '' && !REGEX.sendZip.test(this.localSearchInput.value.trim())) {

              const fieldError: any = document.getElementById(`local-search-modal-error-msg`);
               fieldError.classList.remove('hidden')
              fieldError.innerHTML = 'Invalid zip code format.'
            
      
          }
          else {
            const fieldError: any = document.getElementById(`local-search-modal-error-msg`);
            fieldError.classList.add('hidden')
          
        
          }

          const text1 = document.getElementById("step1-text1") as HTMLElement;
          const text2 = document.getElementById("step1-text2") as HTMLElement;      
           text1.style.display = "block";
            text2.style.display = "none";
        });

          const formCtaDiv = document.querySelector(".step-1 .form-cta");
        if (formCtaDiv && formCtaDiv.parentNode) {
          formCtaDiv.parentNode.insertBefore(errorMessageSpan, formCtaDiv);
        }
    }
    const enterheaderBtnZipcode =  document.getElementById('local-search-modal') as HTMLInputElement;
    enterheaderBtnZipcode?.addEventListener('keydown', function(this:any,event:KeyboardEvent){
      if(event.code === 'Enter'){
        event.stopPropagation();
        event.preventDefault();
        if(enterheaderBtnZipcode?.value){
        (document.querySelector('.find-local-modal form .form-cta .primary-btn') as HTMLInputElement).click();
        }else {
          const zipShowErrMsg = document.getElementById('local-search-modal-error-msg') as HTMLElement;
          zipShowErrMsg?.classList.remove('hidden');
        }
      }
    });

      window.onload = () => {

      const findLocalButtons = document.querySelectorAll('.close-modal-btn') as NodeListOf<HTMLButtonElement>;
      
      if(findLocalButtons && findLocalButtons.length > 0) {
        findLocalButtons.forEach(button => {
            button?.addEventListener('click', (event) => {
                event.preventDefault(); 
                const searchInput = document.getElementById('local-search-modal') as HTMLInputElement | null;
                if (searchInput) {
                    searchInput.value = '';
                }
                document.getElementById('step1-text2')!.style.display = 'none';
                document.getElementById('step1-text1')!.style.display = 'block';
            });
        });
    }
 
  };
  

  }




  public handleButtonClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    const buttonId = (event.target as HTMLElement)?.id;

    const localSearchInput = document.querySelector('#local-search-modal') as HTMLInputElement;
    const zipInput = (event.target as HTMLElement)?.previousElementSibling as HTMLInputElement;
    const localSearchInputVal = localSearchInput?.value;

    let zipValue = buttonId === "local-search-modal" ? localSearchInputVal : (zipInput?.value);
    const domainName = location.hostname.toLowerCase();
    const isWebSite = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") === -1 ? "us" : "ca";

    if (isWebSite === "ca") {
      zipValue = zipValue?.indexOf(" ") === -1 ? zipValue?.replace(/^(.{3})(.*)$/, "$1 $2") : zipValue;
    }

    if (typeof zipValue !== 'undefined') {
      localStorage.setItem('zipcode', zipValue);
      const inputZipCode1 = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
      if (inputZipCode1) {
        inputZipCode1.value = zipValue;
      }
    }

    const locateAddressBumper = new LocateAddressBumper();

    const getLocateLocation = new GetLocateLocation();
    if (buttonId === "local-search-modal") {
      const locationSearchChangeLocValue = (document.querySelector(".modal-search-input") as HTMLInputElement)?.value;
      const isValidZip = this.validateForm(zipValue)

      if (isValidZip) {
        getLocateLocation.locateLocation("desk");
      }
    } else {
      const zipcodeValue = (document.querySelector("#local-search-modal") as HTMLInputElement)?.value;
      const isValidZip = this.validateForm(zipValue)


      if (isValidZip) {
        getLocateLocation.locateLocation("desk");
      }
    }
  }


  validateForm(zipValue:any): boolean {
    const zipCodeInput = document.getElementById("local-search-modal") as HTMLInputElement;
    const zipCode = zipCodeInput.value;
    const formFields = { zipCode };
    const errors: any = {};

 
    if (zipCodeInput.required && formFields.zipCode === '') {
      errors['local-search-modal'] = '';
  } else if (zipCodeInput.required && !REGEX.sendZip.test(formFields.zipCode.trim())) {
      errors['local-search-modal'] = 'Invalid zip code format.';
  }

   
    document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));


    Object.keys(errors).forEach((fieldId, index) => {
      const field: any = document.getElementById(fieldId);
      const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
      if (field && field.parentNode) {
          const errorMessage = errors[fieldId];
          const errorElement = document.createElement('span');
          errorElement.className = 'error-msg';
          errorElement.textContent = errorMessage
          field.classList.add("invalid-field");
          if (fieldError && errorMessage) {
              fieldError.innerHTML = errorMessage
          }
          if (fieldError && fieldError.classList.contains('hidden'))
              fieldError.classList.remove('hidden')
      }
      if (index == 0 && field) {
          field.focus()
      }
  });

    return Object.keys(errors).length === 0;
  }

  
}

const searchLocalSite = new SearchLocalSite();