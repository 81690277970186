import { localSearch } from "../util/share";
export class SearchServiceInitial {
    private searchInput: HTMLInputElement;

    constructor() {
        const brandName  = document.getElementById('brandName') as HTMLInputElement;
        this.searchInput = document.getElementById('glb-search-service') as HTMLInputElement;
        const brandNameVal = brandName?.value;

        const urlParams = new URLSearchParams(window.location.search);
        const getSearch = urlParams.get('search');
        if(getSearch) 
        this.searchServiceFunctionality()
        const dbaName = document.getElementById('full_dbaName') as HTMLInputElement;
        
        if(dbaName?.value){
           localSearch();
        }


    }

    public searchServiceFunctionality(): void {
        const pathName = window.location.pathname;
        // const searchPage = pathName.includes("/search/");
        const filterArray: any[] = [];
        const urlParams = new URLSearchParams(window.location.search);
        const getSearch = urlParams.get('search');
       
        const searchTermVal = this.searchInput?.value ? this.searchInput?.value?.toLowerCase() : getSearch ? getSearch.toLowerCase() : ''
        const searchTerm = searchTermVal?.trim();        
        const multipleTerms: string[] = [];
        if (searchTerm) {
            multipleTerms.push(searchTerm);
            const nameArr = searchTerm.split(' ');
            nameArr.forEach(nm => {
                multipleTerms.push(nm);
            });
        }
        let serviceData = sessionStorage.getItem('filterService');
        const dbaName = document.getElementById('full_dbaName') as HTMLInputElement;

        if(dbaName?.value){
           serviceData = sessionStorage.getItem('localFilterService'); 
        }
        if (serviceData != null) {
            const filterService: any[] = JSON.parse(serviceData);
            filterService.map((m: any) => {
                m.category?.map((cat: any) => {
                    multipleTerms.forEach((term: any) => {
                        const link = term?.replace(/\s/g, '-');
                        if (cat.serviceType.toLowerCase() == 'otherservice') {
                            this.otherServiceAvailable(cat.category, filterArray, term, link);
                        }
                        this.filterCatgroyMenuService(cat, filterArray, term, link);
                    });
                });
            });

            filterArray.sort((a, b) => {
                const relevanceA = this.calculateRelevance(a, searchTerm);
                const relevanceB = this.calculateRelevance(b, searchTerm);
    
                // Sort in descending order (higher relevance first)
                return relevanceB - relevanceA;
            });
           
            const uniqueArray = filterArray.filter((value: any, index: any, self: any) =>
                index === self.findIndex((t: any) => (
                    t.link === value.link
                ))
            );

            this.searchServiceListGenerator(uniqueArray, searchTerm);
        }
    }

    private calculateRelevance(searchData: any, searchTerm: string): number {
        const nameRelevance = searchData.name?.toLowerCase().includes(searchTerm) ? 1 : 0;
        const descriptionRelevance = searchData.description?.toLowerCase().includes(searchTerm) ? 1 : 0;

        return nameRelevance + descriptionRelevance;
    }

    private checkingServiceLinkName(mn: any, filterArray: any, namecheck: any, link: any): void {
        mn?.service?.map((ser: any) => {
            if (ser.link.toLowerCase().includes(link)) {
                filterArray.push(ser);
            } else {
                if (ser.name.toLowerCase().includes(namecheck)) {
                    filterArray.push(ser);
                }
            }
        });
    }

    private otherServiceAvailable(category: any, filterArray: any, term: any, link: any): void {
        category.map((cat: any) => {
            this.filterCatgroyMenuService(cat, filterArray, term, link);
        });
    }

    private filterCatgroyMenuService(cat: any, filterArray: any, namecheck: any, link: any): void {
        if (cat.link?.includes(link)) {
            filterArray.push(cat);
            cat?.menu?.map((mn: any) => {
                if (mn.link.toLowerCase().includes(link)) {
                    filterArray.push(mn);
                    this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                } else {
                    if (mn.name?.toLowerCase().includes(namecheck)) {
                        filterArray.push(mn);
                        this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                    } else {
                        this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                    }
                }
            });
        } else {
            if (cat.name?.toLowerCase().includes(namecheck)) {
                filterArray.push(cat);
                cat?.menu?.map((mn: any) => {
                    if (mn.link?.toLowerCase().includes(link)) {
                        filterArray.push(mn);
                        this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                    } else {
                        if (mn.name?.toLowerCase().includes(namecheck)) {
                            filterArray.push(mn);
                            this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                        } else {
                            this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                        }
                    }
                });
            } else {
                cat?.menu?.map((mn: any) => {
                    if (mn.link?.toLowerCase().includes(link)) {
                        filterArray.push(mn);
                        this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                    } else {
                        if (mn.name?.toLowerCase().includes(namecheck)) {
                            filterArray.push(mn);
                            this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                        } else {
                            this.checkingServiceLinkName(mn, filterArray, namecheck, link);
                        }
                    }
                });
            }
        }
    }

    private searchServiceListGenerator(filterArray: any[], namecheck: string | null): void {
        let searchRowList: any = [];
        for (let i = 0; i < filterArray.length; i++){
            searchRowList.push(this.serviceSearchRowGenerator(filterArray[i]));
        }
        const serviceBlockHeading:any = document.querySelector('.replacement-block .service-block-heading') as HTMLElement;

        const resultInfo = `Showing ${filterArray?.length} Results for “${namecheck}”`;
        
        const serviceErrorMsg: any = document.querySelector('.service-block-text .wysiwyg') as unknown as HTMLElement;
        const serviceBlockText: HTMLElement | null = document.querySelector('.service-block-text');

        if (serviceErrorMsg?.innerText === "") {
            serviceErrorMsg.classList.add("hidden");
            if (serviceBlockText) {
                serviceBlockText.classList.add("hidden");
            }
        }
        if (serviceBlockHeading) {
            serviceBlockHeading.textContent = resultInfo;
        }
        const searchResultsContainer = document.querySelector('.service-wrapper .replacement-section') as HTMLUListElement;
        if (searchRowList.length > 0) {
            if(searchResultsContainer){
                searchResultsContainer.innerHTML = searchRowList.join('');
            }
            // searchResultsContainer?.insertAdjacentHTML('afterbegin', searchRowList);
        }
    }

    serviceSearchRowGenerator(searchData: any){
        return `
             <li class="replacement-card-list">
             ${searchData.thumbnail ? 
                `<div class="card-header">
                <picture>
                    <source media="(min-width:640px)" srcset="${searchData.thumbnail}" height="180">
                    <source media="(min-width:300px)" srcset="${searchData.thumbnail}" width="293" height="180">
                    <img src="${searchData.thumbnail}" alt="" width="293">
                </picture>
            </div>` : '' }
            <div class="card-body">
                <h2 class="replacement-heading">${searchData.name}</h2>
                ${searchData.description ? `<p class="replacement-text">${searchData.description}</p>` : ''}
            </div>
            <div class="card-footer">
                ${searchData.link != "" ? 
                    `<a href="${searchData.link}" class="primary-outline-btn btn"> Read More <svg>
                        <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#arrow-small-right-primary" />
                        </svg>
                    </a>`:''}
            </div>
        </li>
        `;
    }
}

new SearchServiceInitial();
